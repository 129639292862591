import { chain, get, every, sumBy, filter } from 'lodash';
import numeral from 'numeral';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Collapse } from '@material-ui/core';
import { ExpandLess } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import PickUpOrderSummary from './PickUpOrderSummary';
import AddonSku from '../../../lib/Constants/AddonSku';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column-reverse',
    flexShrink: 0
  },
  expandContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column'
  },
  expandIcon: {
    transform: props => (props.showOrderSummary ? 'rotate(180deg)' : ''),
    transition: 'transform 0.5s ease-out'
  },
  expandTab: {
    alignItems: 'center',
    backgroundColor: theme.palette.primary.light,
    borderBottom: `1px dashed ${theme.palette.info.main}`,
    borderRadius: '5px 5px 0px 0px',
    cursor: 'pointer',
    color: theme.palette.bg.main,
    display: 'flex',
    fontSize: '0.9rem',
    justifyContent: 'space-around',
    width: '200px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  runningTotal: {
    alignItems: 'center',
    display: 'flex',
    margin: '0px 10px'
  },
  runningTotalContainer: {
    alignItems: 'center',
    backgroundColor: theme.palette.bg.light,
    display: 'flex',
    flexDirection: 'row',
    height: '60px',
    justifyContent: 'space-between',
    padding: '5px 10px',
    zIndex: '1'
  },
  runningTotalLabel: {
    fontWeight: 'bold',
    margin: '0px 10px'
  },
  save: {
    right: '0px',
    [theme.breakpoints.down('md')]: {
      right: '50px'
    },
    [theme.breakpoints.down('sm')]: {
      right: '0px'
    },
    [theme.breakpoints.down('xs')]: {
      right: '50px'
    }
  }
}));

export default function PickUpActionBar(props) {
  const [showOrderSummary, setShowOrderSummary] = useState(false);
  const classes = useStyles({ ...props, showOrderSummary });

  function disableSubmit(pickUp) {
    return !every([
      filter(pickUp.rolls, 'filmRollId').length,
      pickUp.messengerHoursId,
      pickUp.pickUpAddressId,
      pickUp.customerId,
      pickUp.date,
      pickUp.messengerId
    ]);
  }

  const lines = props.pickUp.order.lines.filter(
    line =>
      line.quantity !== 0 &&
      line.productId &&
      line.productId !== get(props.pickUpFeeProduct, 'id')
  );
  const pickUpFee = props.chargePickUp
    ? get(props.pickUpFeeProduct, 'price')
    : 0;
  const total = numeral(sumBy(lines, 'amount') + pickUpFee).format('$0.00');

  return (
    <Card className={classes.container}>
      <div className={classes.runningTotalContainer}>
        <div className={classes.runningTotal}>
          <div className={classes.runningTotalLabel}>Pick Up Total: </div>
          <div className={classes.runningTotalAmount}>{total}</div>
        </div>

        <Button
          disabled={props.disabled || disableSubmit(props.pickUp)}
          size="small"
          color="primary"
          variant="contained"
          onClick={props.submit}
          className={classes.save}
        >
          Save
        </Button>
      </div>
      <div className={classes.expandContainer}>
        <div
          className={classes.expandTab}
          onClick={() => setShowOrderSummary(!showOrderSummary)}
        >
          <div>Order Summary</div>
          <ExpandLess className={classes.expandIcon} />
        </div>

        <Collapse in={showOrderSummary}>
          <PickUpOrderSummary
            pickUp={props.pickUp}
            pickUpFeeProduct={props.pickUpFeeProduct}
            {...props}
          />
        </Collapse>
      </div>
    </Card>
  );
}

PickUpActionBar.propTypes = {
  pickUp: PropTypes.shape({}).isRequired,
  pickUpFeeProduct: PropTypes.shape({}).isRequired,
  submit: PropTypes.func.isRequired
};
