import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  danger: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.destructive.main,
    '&:hover': {
      backgroundColor: theme.palette.destructive.dark
    }
  },
  success: {
    color: theme.palette.bg.main,
    backgroundColor: theme.palette.success.dark,
    '&:hover': {
      backgroundColor: theme.palette.success.darker
    }
  },
  warning: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.warning.main,
    '&:hover': {
      backgroundColor: theme.palette.warning.dark
    }
  }
}));
