import { get, map, size } from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider
} from '@material-ui/core';

import OrderSummary from '../OrderSummary/OrderSummary';
import SuccessButton from '../Button/SuccessButton';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  actions: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  content: {
    minHeight: '200px',
    width: '360px'
  },
  dialog: {
    zIndex: '99999 !important'
  },
  dialogPaper: {
    [theme.breakpoints.down('sm')]: {
      margin: '0px'
    }
  }
}));

export default function HDUnlockModal(props) {
  const classes = useStyles();
  const quantity = size(props.scans);
  const line = {
    customerId: props.user.id,
    orderId: null,
    productId: get(props.product, `id`),
    amount: get(props.product, `price`) * quantity,
    notes: '',
    stripeId: null,
    quantity
  };
  const lines = [line];
  const [saving, setSaving] = useState(false);

  function onConfirm() {
    const params = {
      scanIds: map(props.scans, 'id'),
      lines
    };

    setSaving(true);

    const unlockMethod = props.tiff
      ? props.unlockTiffScans
      : props.unlockHDScans;

    unlockMethod(props.user.id, params)
      .then(({ pickUps }) => props.onClose(pickUps))
      .finally(() => setSaving(false));
  }

  return (
    <Dialog
      classes={{ root: classes.dialog, paper: classes.dialogPaper }}
      onClose={() => props.onClose()}
      open={props.open}
    >
      <DialogTitle>HD Unlocks Order Confirmation</DialogTitle>
      <Divider />
      <DialogContent className={classes.content}>
        <OrderSummary
          user={props.user}
          products={props.products}
          isSmallScreen
          lines={lines}
        />
        <div className={classes.actions}>
          <Button
            disabled={saving}
            color="primary"
            variant="contained"
            size="small"
            onClick={() => props.onClose()}
          >
            Cancel
          </Button>
          <SuccessButton
            disabled={saving}
            color="primary"
            variant="contained"
            size="small"
            onClick={onConfirm}
          >
            Confirm and Unlock
          </SuccessButton>
        </div>
      </DialogContent>
    </Dialog>
  );
}

HDUnlockModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  product: PropTypes.shape({}),
  unlockHDScans: PropTypes.func.isRequired,
  unlockTiffScans: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired
};
