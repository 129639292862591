import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  card: {
    alignItems: 'center',
    backgroundColor: theme.palette.white.main,
    border: props =>
      props.selected ? `5px solid ${theme.palette.secondary.main}` : 'none',
    cursor: 'pointer',
    display: 'flex',
    fontSize: '0.8rem',
    justifyContent: 'space-between',
    margin: '0px 10px 10px 0px',
    padding: '10px',
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.main,
      cursor: 'pointer'
    }
  },
  cardDetail: {
    lineHeight: '1.15em'
  }
}));

export default function PickUpWindowCard(props) {
  const classes = useStyles(props);
  const { date, window, onSelect } = props;
  const { start, end } = window;

  function formatTime(minutes) {
    return date
      .clone()
      .add(minutes, 'minutes')
      .format('hh:mm A');
  }

  const time = `${formatTime(start)} - ${formatTime(end)}`;
  return (
    <Card classes={{ root: classes.card }} onClick={() => onSelect(window)}>
      <div className={classes.cardDetail}>{date.format('LL')}</div>
      <div className={classes.cardDetail}>{time}</div>
    </Card>
  );
}

PickUpWindowCard.propTypes = {
  date: PropTypes.shape({}).isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  window: PropTypes.shape({}).isRequired
};
