import { chain } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import UserPickUps from './Sections/UserPickUps';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import { LinearProgress } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import useWindowSize from '../../lib/utils/Hook/use-window-size';
import AdminContext from '../../contexts/AdminContext';

import { makeStyles } from '@material-ui/core/styles';

const styles = () => ({
  snackbar: {
    backgroundColor: '#d63031'
  }
});
const useStyles = makeStyles(styles);

const getUserId = (location, isAdmin) => {
  return chain(location)
    .get('pathname')
    .split('/')
    .at(isAdmin ? 3 : 2)
    .value();
};

export default function UserPage(props) {
  const classes = useStyles();
  const context = useContext(AdminContext);
  const { location } = props;
  const [userId] = useState(getUserId(location, context.isAdmin));
  const [error, setError] = useState(null);

  useEffect(() => {
    props.getUser(userId);
    props.getProducts();
  }, [userId]);

  useEffect(() => {
    setError(props.error);
  }, [props.error]);

  const screenSize = useWindowSize();

  return (
    <div>
      {(props.loading || props.loadingPickUps) && <LinearProgress />}
      {props.user && (
        <UserPickUps
          pickUps={props.pickUps}
          {...props}
          screenSize={screenSize}
          isSmallScreen={screenSize.isSmallScreen}
        />
      )}

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        autoHideDuration={6000}
        open={!!error}
        onClose={() => setError(null)}
      >
        <SnackbarContent
          classes={{
            root: classes.snackbar
          }}
          message={error}
          action={
            <React.Fragment>
              <IconButton
                aria-label="close"
                color="inherit"
                className={classes.close}
                onClick={() => setError(null)}
              >
                <Close />
              </IconButton>
            </React.Fragment>
          }
        />
      </Snackbar>
    </div>
  );
}

UserPage.propTypes = {
  availabilities: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool.isRequired,
  user: PropTypes.shape({}),
  getPickUps: PropTypes.func.isRequired,
  getProducts: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  removeAddress: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  pickUps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
