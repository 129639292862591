import cx from 'classnames';
import { keyBy, sumBy, size, get } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import Divider from '@material-ui/core/Divider';

import OrderLine from '../../../components/OrderLine/OrderLine';
import OrderTotal from '../../../components/OrderTotal/OrderTotal';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  checkCircle: {
    fill: theme.palette.success.dark,
    marginLeft: '10px'
  },
  container: {
    background: theme.palette.white.main,
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    padding: '0px 15px'
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between'
  },
  headerText: {
    alignItems: 'center',
    display: 'flex'
  },
  summary: {
    backgroundColor: 'transparent',
    padding: '20px 10px'
  },
  summaryHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 'bold'
  },
  summaryHeader1: {
    flex: '1'
  },
  summaryHeader2Mobile: {
    [theme.breakpoints.down('sm')]: {
      flex: '2'
    }
  },
  summaryHeader5: {
    flex: '5'
  },
  tableTitle: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

export default function PickUpOrderSummary(props) {
  const classes = useStyles(props);
  const productsById = keyBy(props.products, 'id');

  // Work around for showing included service
  const includedServiceProduct = {
    included_film_rolls: {
      name: 'Included Film Roll',
      price: 0.0
    }
  };

  const includedServiceLine = {
    productId: 'included_film_rolls',
    amount: 0.0,
    notes: 'Included Film Roll',
    quantity: size(props.rollCredits)
  };

  const lines = props.pickUp.order.lines.filter(
    line =>
      line.quantity !== 0 &&
      line.productId &&
      line.productId !== get(props.pickUpFeeProduct, 'id')
  );

  return (
    <div className={classes.container}>
      <h4 className={classes.header}>
        <span className={classes.headerText}>Pick Up Order Summary</span>
      </h4>

      <div className={classes.summary}>
        <div className={classes.summaryHeader}>
          <div className={classes.summaryHeader5}>Item</div>
          <div
            className={cx(classes.summaryHeader1, classes.summaryHeader2Mobile)}
          >
            {props.isSmallScreen ? '$' : 'Price'}
          </div>
          <div className={classes.summaryHeader1}>
            {props.isSmallScreen ? 'Qty' : 'Quantity'}
          </div>
          <div
            className={cx(classes.summaryHeader1, classes.summaryHeader2Mobile)}
          >
            Total
          </div>
        </div>

        {size(props.rollCredits) > 0 && (
          <OrderLine
            products={includedServiceProduct}
            line={includedServiceLine}
          />
        )}

        {lines.map((line, i) => (
          <OrderLine key={line.id || i} products={productsById} line={line} />
        ))}
      </div>

      <Divider />

      <OrderTotal
        showPickUpFee
        total={sumBy(lines, 'amount')}
        pickUpFee={props.chargePickUp ? 9.99 : 0}
      />
    </div>
  );
}

PickUpOrderSummary.propTypes = {
  chargePickUp: PropTypes.bool.isRequired,
  rollCredits: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isSmallScreen: PropTypes.bool.isRequired,
  pickUp: PropTypes.shape({}).isRequired,
  pickUpFeeProduct: PropTypes.shape({}).isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  user: PropTypes.shape({}).isRequired
};
