import React from 'react';
import { connect } from 'react-redux';
import AdminPage from '../../views/AdminPage/AdminPage';

const AdminContainer = props => {
  return <AdminPage {...props} />;
};

/**
 * The admin state to be provided to the admin component
 *
 * @param {object} state global state
 */
const mapStateToProps = state => ({});

/**
 * Actions to be dispatched
 *
 * @param {function} dispatch function to dispatch admin adminActions
 */
const mapDispatchToProps = dispatch => ({});

/**
 * Connects admin state to the App component
 *
 * @param {object} mapStateToProps admin data object to become props for the App component
 * @param {object} mapDispatchToProps admin adminActions to be dispatched to reducer
 */
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminContainer);
