import { map } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import { Paper } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import contentAreasStyles from '../../assets/jss/material-kit-pro-react/views/componentsSections/contentAreas';
import PickUpsListPickUp from './PickUpsListPickUp';

const useStyles = makeStyles(theme => ({
  ...contentAreasStyles,
  paper: {
    backgroundColor: 'transparent',
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  pickUps: {
    paddingBottom: '55px',
    overflow: 'auto'
  }
}));

export default function PickUpsList(props) {

  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <div className={classes.pickUps}>
        {map(props.pickUps, pu => {
          return <PickUpsListPickUp pickUp={pu} key={pu.id} {...props} />;
        })}
      </div>
    </Paper>
  );
}

PickUpsList.propTypes = {
  pickUps: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
