import axios from 'axios';
import { chain, size, isEmpty, some } from 'lodash';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import querystring from 'querystring';

import { makeStyles } from '@material-ui/core/styles';
// core components
import GridContainer from '../../components/Grid/GridContainer.js';
import GridItem from '../../components/Grid/GridItem.js';
import Button from '../../components/CustomButtons/Button.js';

// Sections for this page
import Onboarding from '../../containers/Onboarding/Onboarding';
import LandingHeader from '../../components/LandingHeader/LandingHeader';
import AppFooter from '../../components/AppFooter/AppFooter';
import Typography from '@material-ui/core/Typography';
import LandingBG from '../../assets/img/landing_bg.jpg';
import TextField from '@material-ui/core/TextField';
import validateEmail from '../../lib/utils/Form/validate-email';

const useStyles = makeStyles(theme => ({
  hero: {
    alignItems: 'center',
    display: 'flex',
    flex: '1',
    margin: 'auto',
    maxWidth: '540px',
    minHeight: '400px',
    padding: '0px'
  },
  root: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    paddingTop: '50px'
  }
}));

export default function PrivacyPolicyPage(props) {
  const [login, setLogin] = useState(false);
  const [openModel, setOpenModal] = useState(false);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <LandingHeader
        onLogin={() => {
          setLogin(true);
          setOpenModal(true);
        }}
      />
      <GridContainer className={classes.hero}>
        <div>
          <Typography variant="h2">
            <u>Privacy Policy</u>
          </Typography>

          <br />
          <br />

          <Typography variant="body1">
            We aim to streamline your film photography with a simple
            subscription so you can focus on shooting. Every photographer is
            different and your answers will help us provide you with the service
            you need to make your film photography a truly hassle-free
            experience.
          </Typography>

          <br />
          <br />

          <Typography variant="body1">
            In addition to your opinion, we will also collect some personal
            information about you such as email address to be able to contact
            you and zip code to determine whether we are able to service your
            area.
          </Typography>

          <br />
          <br />

          <Typography variant="body1">
            We security store this data and we respect your trust and protect
            your privacy and therefore will not share or sell this data with any
            third parties.
          </Typography>

          <br />
          <br />

          <Typography variant="body1">
            By filling out this form you agree that we will process your data in
            line with our <a target="_blank" href="https://storage.cloud.google.com/darklab_public/privacy-policy.pdf">privacy policy</a>.
          </Typography>

          <br />
          <br />

          <Typography variant="body1">
            If you have any questions or change your mind, contact our Data
            Protection Officer{' '}
            <a href="mailto:josh@darklabsubscription.com">
              josh@darklabsubscription.com
            </a>
          </Typography>

          <br />
          <br />
        </div>
      </GridContainer>

      <Onboarding
        {...props}
        showLogin={login}
        open={openModel}
        onClose={() => {
          setLogin(false);
          setOpenModal(false);
        }}
      />

      <AppFooter />
    </div>
  );
}
