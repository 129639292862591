/**
 * Onboarding saga functions
 */
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { handleError } from '../../lib/utils/saga-utils';
import { actions } from './slice';
import PickUpAPI from '../../lib/Api/PickUp';
import UserAPI from '../../lib/Api/User';
import ProductAPI from '../../lib/Api/Product';

export function* addPickUp({ payload: { pickUp, resolve, reject } }) {
  try {
    const response = yield call(UserAPI.addPickUp, pickUp.customerId, pickUp);
    yield put(actions.addPickUpSuccess({ pickUp: response.pickUp }));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.addPickUpError, err);
    yield call(reject, err);
  }
}

export function* getPickUp({ payload: { id, resolve, reject } }) {
  try {
    const response = yield call(PickUpAPI.get, id);
    yield put(actions.getPickUpSuccess(response));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.getPickUpError, err);
    yield call(reject, err);
  }
}

export function* getPickUps({ payload: { params } }) {
  try {
    const response = yield call(PickUpAPI.getAll, params);
    const { pickUps } = response;
    yield put(actions.getPickUpsSuccess({ pickUps }));
  } catch (err) {
    yield handleError(actions.getPickUpsError, err);
  }
}

export function* updatePickUp({ payload: { pickUp, resolve, reject } }) {
  try {
    const response = yield call(
      UserAPI.updatePickUp,
      pickUp.pickUp.customerId,
      pickUp.pickUp.id,
      pickUp
    );
    yield put(actions.updatePickUpSuccess({ pickUp: response.pickUp }));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.updatePickUpError, err);
    yield call(reject, err);
  }
}

export function* getAvailabilities({ payload: { userId, params, resolve } }) {
  try {
    const response = yield call(UserAPI.getAvailabilities, userId, params);
    const { availabilities } = response;
    yield put(actions.getAvailabilitiesSuccess({ availabilities }));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.getAvailabilitiesError, err);
  }
}

export function* addFilmRoll({ payload }) {
  const { film, resolve, reject } = payload;

  try {
    const response = yield call(UserAPI.addFilmRoll, film);
    yield put(actions.addFilmRollSuccess({ pickUp: response.pickUp }));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.addFilmRollError, err);
    yield call(reject, err);
  }
}

export function* updateFilmRoll({ payload }) {
  const { film, resolve, reject } = payload;
  try {
    const response = yield call(UserAPI.updateFilmRoll, film);
    yield put(actions.updateFilmRollSuccess(response));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.updateFilmRollError, err);
    yield call(reject, err);
  }
}

export function* addFilmRollScan({ payload }) {
  const { film, scanFile, onProgress, resolve, reject } = payload;
  try {
    const response = yield call(
      UserAPI.addFilmRollScan,
      film,
      scanFile,
      onProgress
    );
    yield put(actions.addFilmRollScanSuccess(response));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.addFilmRollScanError, err);
    yield call(reject, err);
  }
}

export function* removeFilmRollScan({ payload }) {
  const { film, scanId, resolve, reject } = payload;
  try {
    const response = yield call(UserAPI.removeFilmRollScan, film, scanId);
    yield put(actions.removeFilmRollScanSuccess(response));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.removeFilmRollScanError, err);
    yield call(reject, err);
  }
}

export function* getPickUpProducts() {
  try {
    const response = yield call(ProductAPI.getPickUpProducts);
    yield put(actions.getPickUpProductsSuccess(response));
  } catch (err) {
    yield handleError(actions.getPickUpProductsError, err);
  }
}

function* pickUpsSaga() {
  yield takeLatest(actions.addFilmRoll.type, addFilmRoll);
  yield takeEvery(actions.addFilmRollScan.type, addFilmRollScan);
  yield takeLatest(actions.addPickUp.type, addPickUp);
  yield takeLatest(actions.getPickUps.type, getPickUps);
  yield takeLatest(actions.getPickUp.type, getPickUp);
  yield takeLatest(actions.getPickUpProducts.type, getPickUpProducts);
  yield takeLatest(actions.getAvailabilities.type, getAvailabilities);
  yield takeLatest(actions.removeFilmRollScan.type, removeFilmRollScan);
  yield takeLatest(actions.updateFilmRoll.type, updateFilmRoll);
  yield takeLatest(actions.updatePickUp.type, updatePickUp);
}

export default pickUpsSaga;
