import axios from 'axios';
import { parallelLimit } from 'async';
import { saveAs } from 'file-saver';
import JsZip from 'jszip';
import { chain, get, keyBy, map, omitBy, range, uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import PickUpsList from '../../../components/PickUpsList/PickUpsList';
import DigitalSku from '../../../lib/Constants/DigitalSku';
import HDUnlockModal from '../../../components/PickUpsList/HDUnlockModal';
import Pagination from '../../../components/Pagination/Pagination';
import PickUpListActionBar from '../../../components/PickUpsList/PickUpListActionBar';

import { makeStyles } from '@material-ui/core/styles';
import Gallery from '../../../components/PickUpsList/Gallery';

const styles = theme => ({
  actions: {
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '4px 4px 0px 0px',
    bottom: '0',
    display: 'flex',
    justifyContent: 'flex-end',
    left: '0',
    padding: '10px',
    position: 'fixed',
    zIndex: '1200',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      padding: '0px',
      minHeight: '195px'
    }
  },
  link: {
    '&:visited': {
      color: theme.palette.bg.main
    }
  },
  pagination: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: '0px',
    visibility: 'hidden',
    [theme.breakpoints.down('sm')]: {
      flex: '0.5',
      justifyContent: 'flex-start',
      marginLeft: '10px',
      width: '100%'
    }
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    justifyContent: 'space-between',
    padding: '5px',
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vh - 80px)',
      marginTop: '75px'
    }
  },
  scrollable: {
    overflow: 'auto'
  },
  tableTitle: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px 0px'
  }
});
const useStyles = makeStyles(styles);

export default function UserPickUps(props) {
  const classes = useStyles();
  const [selected, setSelected] = useState({});
  const [hdProduct, setHDProduct] = useState(null);
  const [galleryImage, setGalleryImage] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const [allPickUps, setAllPickUps] = useState([]);
  const {
    getPickUps,
    products,
    pickUps,
    user: { id: userId }
  } = props;
  const [params, setParams] = useState(
    props.params || {
      limit: 5,
      offset: 0,
      sortBy: 'date',
      desc: true
    }
  );

  const productsBySku = keyBy(products, 'sku');
  const scansById = makeScansById(pickUps);

  useEffect(() => {
    getPickUps(params, userId).then(({ pickUps }) =>
      setAllPickUps(state => uniqBy([...state, ...pickUps], 'id'))
    );
  }, [params, getPickUps, userId]);

  function makeScansById(pickUps) {
    return chain(pickUps)
      .flatMap('rolls')
      .flatMap('scans')
      .compact()
      .keyBy('id')
      .value();
  }

  function unlockHDScan(scan, sku) {
    if (sku === DigitalSku.HD_SCAN && scan.hiRes) return;
    if (sku === DigitalSku.TIFF_SCAN && scan.tiff) return;
    const product = productsBySku[sku];
    setSelected({ [scan.id]: true });
    setHDProduct(product);
  }

  function updateGalleryImage(pickUps) {
    const scan = chain(pickUps)
      .flatMap('rolls')
      .flatMap('scans')
      .find(scan => scan.id === galleryImage.id)
      .value();
    if (!scan) return;
    setGalleryImage(null);
    setGalleryImage(scan);
  }

  function getLink(scan = {}) {
    if (scan.tiff) return scan.tiff.read;
    if (scan.hiRes) return scan.hiRes.read;
    return scan.midRes.read;
  }

  function download(selected) {
    const zip = new JsZip();
    const folderName = ['darklab_scans_download', new Date()].join('_');
    const limit = 5;

    setDownloading(true);

    const callbacks = map(selected, (_, id) => {
      return cb => {
        const scan = scansById[id];

        if (!scan) return cb();

        const read = getLink(scan);
        const config = { responseType: 'arraybuffer' };

        axios
          .get(read, config)
          .then(({ data }) => {
            zip.file(
              `${scan.name}.${scan.tiff ? 'tif' : 'jpg'}`,
              Buffer.from(data, 'binary').toString('base64'),
              { base64: true }
            );
            cb();
          })
          .catch(err => cb(err));
      };
    });

    parallelLimit(callbacks, limit)
      .then(() => {
        zip.generateAsync({ type: 'blob' }).then(content => {
          saveAs(content, folderName);
        });
      })
      .finally(() => setDownloading(false));
  }

  return (
    <div className={classes.root}>
      <HDUnlockModal
        open={!!hdProduct}
        onClose={pickUps => {
          setHDProduct(null);
          setSelected({});
          if (pickUps) {
            const filtered = omitBy(selected, v => !v);
            const scansById = makeScansById(pickUps);
            setAllPickUps(state => uniqBy([...state, ...pickUps]));
            download(filtered, scansById);
          }
          if (pickUps && galleryImage) updateGalleryImage(pickUps);
        }}
        scans={
          get(hdProduct, 'sku') === DigitalSku.TIFF_SCAN
            ? chain(scansById)
                .values()
                .reject('tiff')
                .filter(scan => selected[scan.id])
                .value()
            : chain(scansById)
                .values()
                .reject('hiRes')
                .filter(scan => selected[scan.id])
                .value()
        }
        product={hdProduct}
        tiff={get(hdProduct, 'sku') === DigitalSku.TIFF_SCAN}
        {...props}
      />

      {galleryImage && (
        <Gallery
          images={chain(pickUps)
            .flatMap('rolls')
            .find(roll =>
              get(roll, 'scans', []).find(
                scan => scan.id === get(galleryImage, 'id')
              )
            )
            .get('scans')
            .value()}
          image={galleryImage}
          onChangeImage={scan => setGalleryImage(scan)}
          onUnlock4k={scan => unlockHDScan(scan, DigitalSku.HD_SCAN)}
          onUnlockTiff={scan => unlockHDScan(scan, DigitalSku.TIFF_SCAN)}
          onClose={() => setGalleryImage(null)}
        />
      )}

      <div className={classes.scrollable}>
        <div className={classes.tableTitle}>
          <Typography variant="h2">Orders</Typography>
          <Link to={`/users/${userId}/pick-ups/new`} className={classes.link}>
            <Button size="medium" color="primary" variant="contained">
              Schedule Pick Up
            </Button>
          </Link>
        </div>

        <PickUpsList
          downloading={downloading}
          download={scans => download(scans, scansById)}
          setGalleryImage={setGalleryImage}
          onUnlock4k={scan => unlockHDScan(scan, DigitalSku.HD_SCAN)}
          onUnlockTiff={scan => unlockHDScan(scan, DigitalSku.TIFF_SCAN)}
          setSelected={setSelected}
          selected={selected}
          getLink={getLink}
          {...props}
        />
      </div>

      <div className={classes.actions}>
        <Pagination
          className={classes.pagination}
          pages={range(Math.ceil(props.pickUpsCount / params.limit)).map(n => {
            return {
              active: n === params.offset / params.limit,
              disabled: false,
              text: n + 1,
              onClick: () =>
                setParams(p => ({ ...p, offset: n * params.limit }))
            };
          })}
        />

        <PickUpListActionBar
          download={scans => download(scans, scansById)}
          downloading={downloading}
          selected={selected}
          setSelected={setSelected}
          allPickUps={allPickUps}
          isSmallScreen={props.isSmallScreen}
          onUnlock4k={() => setHDProduct(productsBySku[DigitalSku.HD_SCAN])}
          onUnlockTiff={() => setHDProduct(productsBySku[DigitalSku.TIFF_SCAN])}
        />
      </div>
    </div>
  );
}

UserPickUps.propTypes = {
  isSmallScreen: PropTypes.bool.isRequired,
  params: PropTypes.shape({
    offset: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    sortBy: PropTypes.string.isRequired,
    desc: PropTypes.bool.isRequired
  }),
  pickUpsCount: PropTypes.number.isRequired,
  pickUps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  user: PropTypes.shape({}).isRequired
};
