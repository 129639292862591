import { chain, get, keyBy, groupBy, map, trim, sortBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Divider from '@material-ui/core/Divider';

const formatMinutes = minutes => {
  return moment()
    .startOf('day')
    .add(minutes, 'minutes')
    .format('HH:mm');
};

const getMinutesFromMidnight = (hours, minutes) => {
  return moment()
    .startOf('day')
    .add(hours, 'hours')
    .add(minutes, 'minutes')
    .diff(moment().startOf('day'), 'minutes');
};

const formStyles = theme => ({
  formContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
});

const useFormStyles = makeStyles(formStyles);

function MessengerHoursForm(props) {
  const classes = useFormStyles();
  const [hours, setHours] = useState(props.hours);
  const [zips, setZips] = useState(map(props.hours.zips, 'zip').join(', '));

  function setTime(e) {
    const {
      target: { value: time, name }
    } = e;
    const [hrs, mins] = time.split(':');
    const minutes = getMinutesFromMidnight(hrs, mins);

    setHours({ ...hours, [name]: minutes });
  }

  function saveHours() {
    const currentZipsbyZip = keyBy(props.hours.zips, 'zip');
    const cleanedZips = chain(zips)
      .split(',')
      .map(trim)
      .filter(z => z.length === 5)
      .map(z => {
        return currentZipsbyZip[z]
          ? currentZipsbyZip[z]
          : { messengerId: props.messengerId, zip: z, hoursId: hours.id };
      })
      .uniqBy('zip')
      .value();

    const saveHours = hours.id ? props.updateHours : props.addHours;

    saveHours(props.messengerId, { ...hours, zips: cleanedZips }).then(
      ({ hours }) => setHours(hours)
    );

    setZips(map(cleanedZips, 'zip').join(', '));
  }

  return (
    <form>
      <Grid container className={classes.formContainer}>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            label="Start"
            type="time"
            name="start"
            error={hours.end && hours.start ? hours.end < hours.start : false}
            value={hours.start ? formatMinutes(hours.start) : '00:00'}
            margin="normal"
            InputLabelProps={{ shrink: true }}
            inputProps={{ step: 1800 }}
            onChange={setTime}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <TextField
            label="End Time"
            type="time"
            name="end"
            error={hours.end && hours.start ? hours.end < hours.start : false}
            value={hours.end ? formatMinutes(hours.end) : '00:00'}
            margin="normal"
            InputLabelProps={{ shrink: true }}
            inputProps={{ step: 1800 }}
            onChange={setTime}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <div>
            {hours.id && (
              <TextareaAutosize
                value={zips}
                rowsmin={3}
                placeholder="Zip Codes..."
                onChange={e => setZips(e.target.value)}
              />
            )}
          </div>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Button
            disabled={!hours.end || !hours.start}
            onClick={saveHours}
            color="secondary"
            variant="contained"
            size="small"
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

const useStyles = makeStyles(() => ({
  label: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '5px 0px'
  },
  weekdayContainer: {
    margin: '10px 0px'
  },
  weekday: {
    minWidth: '100px'
  }
}));

export default function MessengerHours(props) {
  const classes = useStyles();
  const [hours] = useState(props.hours);
  const [newHours, setNewHours] = useState([]);
  const weekdays = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
  ];

  const hoursByWeekdays = groupBy(newHours.concat(hours), 'weekday');

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12}>
        <h4>Messenger Hours:</h4>
        {map(weekdays, (w, i) => {
          return (
            <div className={classes.weekdayContainer} key={w}>
              <div className={classes.label}>
                <div className={classes.weekday}>{w}</div>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() =>
                    setNewHours(
                      [
                        {
                          start: null,
                          end: null,
                          messengerId: get(props, 'messenger.id'),
                          weekday: i,
                          zips: []
                        }
                      ].concat(newHours)
                    )
                  }
                >
                  New Hours
                </Button>
              </div>
              <Divider />
              {map(sortBy(hoursByWeekdays[i], 'start'), (h, i) => (
                <MessengerHoursForm
                  addHours={props.addHours}
                  updateHours={props.updateHours}
                  hours={h}
                  key={h.id || i}
                  messengerId={get(props, 'messenger.id')}
                />
              ))}
            </div>
          );
        })}
      </Grid>
    </Grid>
  );
}

MessengerHours.propTypes = {
  messenger: PropTypes.shape({}).isRequired,
  hours: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  addHours: PropTypes.func.isRequired,
  updateHours: PropTypes.func.isRequired
};
