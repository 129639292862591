/**
 * This file specifies the state of the application in response to App actions
 */
import { createSlice } from '@reduxjs/toolkit';

const TOKEN_STORAGE_KEY = 'darklab_token_storage';
const WAITLIST_STORAGE_KEY = 'darklab_waitlist_user';

const initialState = {
  authToken: localStorage.getItem(TOKEN_STORAGE_KEY),
  isLoggedIn: !!localStorage.getItem(TOKEN_STORAGE_KEY),
  currentUser: null,
  gettingCurrentUser: false,
  getCurrentUserError: null,
  signingUp: false,
  signupError: null,
  loggingIn: false,
  loginError: false,
  waitlistUser: localStorage.getItem(WAITLIST_STORAGE_KEY)
    ? JSON.parse(localStorage.getItem(WAITLIST_STORAGE_KEY))
    : {}
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    signup: state => ({ ...state, signingUp: true }),
    signupError: (state, action) => {
      localStorage.removeItem(TOKEN_STORAGE_KEY);

      return {
        ...state,
        currentUser: null,
        signupError: action.payload.error,
        signingUp: false
      };
    },
    signupSuccess: (state, action) => ({
      ...state,
      currentUser: action.payload.user,
      isLoggedIn: true,
      signupError: null,
      signingUp: false
    }),
    login: state => ({ ...state, loggingIn: true }),
    loginError: (state, action) => {
      localStorage.removeItem(TOKEN_STORAGE_KEY);

      return {
        ...state,
        currentUser: null,
        loginError: action.payload.error,
        loggingIn: false
      };
    },
    loginSuccess: (state, action) => ({
      ...state,
      currentUser: action.payload.user,
      isLoggedIn: true,
      loginError: null,
      loggingIn: false
    }),
    logout: state => {
      localStorage.removeItem(TOKEN_STORAGE_KEY);

      return {
        ...state,
        currentUser: null,
        isLoggedIn: false,
        loginError: null,
        loggingIn: false
      };
    },
    getCurrentUser: state => ({ ...state, gettingCurrentUser: true }),
    getCurrentUserError: (state, action) => ({
      ...state,
      gettingCurrentUser: false,
      getCurrentUserError: action.payload.error
    }),
    getCurrentUserSuccess: (state, action) => ({
      ...state,
      gettingCurrentUser: false,
      currentUser: action.payload.user
    }),
    addWaitlistUser: state => ({ ...state }),
    addWaitlistUserError: state => {
      localStorage.removeItem(WAITLIST_STORAGE_KEY);

      return { ...state, waitlistUser: {} };
    },
    addWaitlistUserSuccess: (state, action) => {
      const { user } = action.payload;
      localStorage.setItem(WAITLIST_STORAGE_KEY, JSON.stringify(user));

      return { ...state, waitlistUser: user };
    }
  }
});

export const { reducer, actions } = slice;

export default reducer;
