import { get } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import RouteMap from '../RouteMap/RouteMap';
import RouteStop from '../RouteStop/RouteStop';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  container: {
    margin: '10px 0px'
  },
  block: {
    alignItems: 'flex-start',
    display: 'flex',
    fontSize: '14px',
    fontWeight: 'bold',
    justifyContent: 'space-between',
    margin: '20px 0px'
  },
  mapContainer: {
    display: 'flex',
    flexDirection: 'row',
    maxHeight: '300px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  stops: {
    flex: 2,
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      flex: 1
    }
  }
}));

const formatTime = (date, minutes) => {
  return moment(date)
    .startOf('day')
    .add(minutes, 'minutes')
    .format('LLLL');
};

export default function MessengerRoute(props) {
  const classes = useStyles();

  const blockDate = get(props.route, '0.pickUp.date');
  const blockStart = formatTime(
    blockDate,
    get(props.route, '0.pickUpWindow.start')
  );
  const blockEnd = formatTime(
    blockDate,
    get(props.route, '0.pickUpWindow.end')
  );
  const [center, setCenter] = useState(props.route[0]);

  return (
    <div className={classes.container}>
      <div className={classes.block}>
        <div>
          {blockStart} - {blockEnd}
        </div>
      </div>
      <div className={classes.mapContainer}>
        <div className={classes.stops}>
          {props.route.map(stop => (
            <RouteStop
              onMouseEnter={() => setCenter(stop)}
              key={stop.pickUpAddress.id}
              address={stop.pickUpAddress}
              user={stop.customer}
              pickedUpAt={stop.pickUp.pickedUpAt}
            />
          ))}
        </div>
        <RouteMap
          center={center}
          route={props.route}
          containerElement={<div style={{ height: `300px`, flex: '6' }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </div>
    </div>
  );
}
