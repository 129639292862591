import { chain, map } from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CheckCircle from '@material-ui/icons/CheckCircle';

import AddressForm from '../../../containers/AddressForm/AddressForm';
import PickUpAddress from './PickUpAddress';

import { makeStyles } from '@material-ui/core/styles';
import modalStyle from '../../../assets/jss/material-kit-pro-react/modalStyle.js';
const useStyles = makeStyles(theme => ({
  ...modalStyle(theme),
  container: {
    flex: '1',
    margin: '5px'
  },
  checkCircle: {
    fill: theme.palette.success.dark,
    marginLeft: '10px'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  headerText: {
    alignItems: 'center',
    display: 'flex'
  },
  paper: {
    backgroundColor: 'transparent',
    padding: '10px 0px'
  }
}));

export default function PickUpAddressSelector(props) {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [addressForEdit, setAddressForEdit] = useState(null);
  const futurePickUpsByAddress = chain(props.user)
    .get('upcomingPickUps')
    .keyBy('pickUpAddressId')
    .value();

  return (
    <div className={classes.container}>
      <h4 className={classes.header}>
        <span className={classes.headerText}>
          1. Select an Address{' '}
          {props.address && <CheckCircle className={classes.checkCircle} />}
        </span>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={() => {
            setAddressForEdit({});
            setOpenModal(true);
          }}
        >
          Add Address
        </Button>
      </h4>
      <Divider />
      <Paper className={classes.paper}>
        <Grid container>
          {map(props.user.addresses, a => (
            <Grid item key={a.id} xs={12} sm={6} md={6}>
              <PickUpAddress
                onSelect={props.onSelect}
                address={a}
                selected={props.address.id === a.id}
                disabled={!!futurePickUpsByAddress[a.id]}
                onEdit={() => {
                  setAddressForEdit(a);
                  setOpenModal(true);
                }}
                removeAddress={props.removeAddress}
              />
            </Grid>
          ))}
        </Grid>
      </Paper>

      {addressForEdit && (
        <Dialog
          open={openModal}
          onClose={() => setOpenModal(false)}
          onExited={() => {
            setAddressForEdit(null);
            props.getUser(props.user.id);
          }}
        >
          <DialogTitle>Edit Address</DialogTitle>
          <DialogContent>
            <AddressForm
              user={props.user}
              address={addressForEdit}
              onUpdateUser={() => {
                setOpenModal(false);
                setAddressForEdit(null);
                props.getUser(props.user.id);
              }}
            />
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}

PickUpAddressSelector.propTypes = {
  address: PropTypes.shape({}).isRequired,
  getUser: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  removeAddress: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired
};
