/**
 * This file specifies the state of the application in response to App actions
 */
import { assign } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: null,
  messenger: null,
  messengers: [],
  routes: []
};

const slice = createSlice({
  name: 'messengers',
  initialState,
  reducers: {
    addHours: state => ({ ...state, loading: true }),
    addHoursError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error
    }),
    addHoursSuccess: (state, action) => {
      const { messenger } = action.payload;
      console.log(messenger, action.payload);
      const messengers = state.messengers.map(m => {
        if (m.id === messenger.id) return assign(m, messenger);
        return m;
      });

      return {
        ...state,
        loading: false,
        error: null,
        messenger,
        messengers
      };
    },
    updateHours: state => ({ ...state, loading: true }),
    updateHoursError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error
    }),
    updateHoursSuccess: (state, action) => {
      const { messenger } = action.payload;
      const messengers = state.messengers.map(m => {
        if (m.id === messenger.id) return assign(m, messenger);
        return m;
      });

      return {
        ...state,
        loading: false,
        error: null,
        messenger,
        messengers
      };
    },
    deleteHours: state => ({ ...state, loading: true }),
    deleteHoursError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error
    }),
    deleteHoursSuccess: (state, action) => {
      const { messenger } = action.payload;
      const messengers = state.messengers.map(m => {
        if (m.id === messenger.id) return assign(m, messenger);
        return m;
      });

      return {
        ...state,
        loading: false,
        error: null,
        messenger,
        messengers
      };
    },
    addMessenger: state => ({ ...state, loading: true }),
    addMessengerError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error
    }),
    addMessengerSuccess: (state, action) => ({
      ...state,
      loading: false,
      error: null,
      messenger: action.payload.messenger,
      messengers: state.messengers.unshift(action.payload.messenger)
    }),
    getMessenger: state => ({ ...state, loading: true }),
    getMessengerError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error
    }),
    getMessengerSuccess: (state, action) => ({
      ...state,
      loading: false,
      error: null,
      messenger: action.payload.messenger
    }),
    getMessengers: state => ({ ...state, loading: true }),
    getMessengersError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error
    }),
    getMessengersSuccess: (state, action) => ({
      ...state,
      loading: false,
      error: null,
      messengers: action.payload.messengers
    }),
    updateMessenger: state => ({ ...state, loading: true }),
    updateMessengerError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error
    }),
    updateMessengerSuccess: (state, action) => {
      const { messenger } = action.payload;
      const messengers = state.messengers.map(m => {
        if (m.id === messenger.id) return assign(m, messenger);
        return m;
      });

      return {
        ...state,
        loading: false,
        error: null,
        messenger,
        messengers
      };
    },
    getRoutes: state => ({ ...state, loading: true }),
    getRoutesError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error
    }),
    getRoutesSuccess: (state, action) => {
      const { routes } = action.payload;

      return {
        ...state,
        loading: false,
        error: null,
        routes
      };
    }
  }
});

export const { reducer, actions } = slice;

export default reducer;
