import Fuse from 'fuse.js';
import React from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';

export default function Dropdown(props) {
  return (
    <ReactSelect
      styles={{
        container: base => ({
          ...base,
          borderRadius: '2px',
          flex: 1,
          fontSize: '12px',
          marginRight: '10px'
        }),
        control: base => ({
          ...base,
          minHeight: '0px',
          padding: '0px'
        }),
        dropdownIndicator: base => ({
          ...base,
          padding: '0px 4px'
        }),
        input: base => ({
          ...base,
          padding: '0px'
        }),
        option: base => ({
          ...base,
          padding: '0px 4px'
        }),
        menuPortal: base => ({ ...base, zIndex: 9999 }),
        valueContainer: base => ({
          ...base,
          padding: '0px 8px'
        })
      }}
      options={props.options}
      filterOption={(opt, search) => {
        const fuse = new Fuse([opt], {
          keys: ['label'],
          threshold: 0.4,
          distance: 100
        });
        return search ? fuse.search(search).length > 0 : true;
      }}
      onChange={props.onChange}
      onFocus={props.onFocus}
      placeholder={props.placeholder}
      value={props.value}
    />
  );
}

Dropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  placeholder: PropTypes.string
};

Dropdown.defaultProps = {
  onFocus: () => {}
};
