/**
 * This file specifies the state of the application in response to App actions
 */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  error: null,
  products: [],
};

const slice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    getPickUpProducts: state => ({ ...state, loading: true }),
    getPickUpProductsError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error
    }),
    getPickUpProductsSuccess: (state, action) => {
      const { products } = action.payload;

      return {
        ...state,
        loading: false,
        error: null,
        products
      };
    }
  }
});

export const { reducer, actions } = slice;

export default reducer;
