/**
 * Onboarding saga functions
 */
import { call, put, takeLatest } from 'redux-saga/effects';
import { handleError } from '../../lib/utils/saga-utils';
import { actions } from './slice';
import UserAPI from '../../lib/Api/User';
import ProductAPI from '../../lib/Api/Product';

// export function* addUser({ payload: { user } }) {
//   try {
//     const response = yield call(UserAPI.add, user);
//     yield put(actions.addUserSuccess({ user: response.user }));
//   } catch (err) {
//     yield handleError(actions.addUserError, err);
//   }
// }

export function* getUser({ payload: { userId } }) {
  try {
    const response = yield call(UserAPI.get, userId);
    const { user } = response;
    yield put(actions.getUserSuccess({ user }));
  } catch (err) {
    yield handleError(actions.getUserError, err);
  }
}

export function* getUsers({ payload: { params } }) {
  try {
    const response = yield call(UserAPI.getAll, params);
    const { users } = response;
    yield put(actions.getUsersSuccess({ users }));
  } catch (err) {
    yield handleError(actions.getUsersError, err);
  }
}

export function* updateUser({ payload: { userId, update, resolve } }) {
  try {
    const response = yield call(UserAPI.update, userId, update);
    yield put(actions.updateUserSuccess({ user: response.user }));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.updateUserError, err);
  }
}

export function* updatePayment({ payload: { userId, token, resolve } }) {
  try {
    const response = yield call(UserAPI.updatePayment, userId, token);
    yield put(actions.updatePaymentSuccess(response));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.updatePaymentError, err);
  }
}

export function* addUserPickUp({ payload: { userId, pickUp, resolve } }) {
  try {
    const response = yield call(UserAPI.addPickUp, userId, pickUp);
    yield put(actions.addUserPickUpSuccess({ pickUp: response.pickUp }));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.addUserPickUpError, err);
  }
}

export function* getUserPickUp({ payload: { userId, pickUpId, resolve } }) {
  try {
    const response = yield call(UserAPI.getPickUp, userId, pickUpId);
    yield put(actions.getUserPickUpSuccess({ pickUp: response.pickUp }));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.getUserPickUpError, err);
  }
}

export function* getUserPickUps({ payload: { userId, params, resolve } }) {
  try {
    const response = yield call(UserAPI.getPickUps, userId, params);
    yield put(actions.getUserPickUpsSuccess(response));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.getUserPickUpsError, err);
  }
}

export function* updateUserPickUp({ payload: { userId, pickUp, resolve } }) {
  try {
    const response = yield call(UserAPI.updatePickUp, userId, pickUp);
    yield put(actions.updateUserPickUpSuccess({ pickUp: response.pickUp }));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.updateUserPickUpError, err);
  }
}

export function* unlockHDScans({ payload: { userId, params, resolve } }) {
  try {
    const response = yield call(UserAPI.unlockHDScans, userId, params);
    yield put(actions.unlockHDScansSuccess(response));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.unlockHDScansError, err);
  }
}

export function* unlockTiffScans({ payload: { userId, params, resolve } }) {
  try {
    const response = yield call(UserAPI.unlockTiffScans, userId, params);
    yield put(actions.unlockTiffScansSuccess(response));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.unlockTiffScansError, err);
  }
}

export function* downloadUserPickUpScans({
  payload: { userId, pickUpId, resolve }
}) {
  try {
    const response = yield call(UserAPI.downloadPickUpScans, userId, pickUpId);
    yield put(actions.downloadUserPickUpScansSuccess(response));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.downloadUserPickUpScansError, err);
  }
}

export function* removeAddress({ payload: { userId, addressId, resolve } }) {
  try {
    const response = yield call(UserAPI.removeAddress, userId, addressId);
    yield put(actions.removeAddressSuccess(response));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.removeAddressError, err);
  }
}

export function* getDigitalProducts({ payload: { resolve, reject } }) {
  try {
    const response = yield call(ProductAPI.getDigitalProducts);
    yield put(actions.getDigitalProductsSuccess(response));
  } catch (err) {
    yield handleError(actions.getDigitalProductsError, err);
  }
}

function* usersSaga() {
  // yield takeLatest(actions.addUsers.type, addUser);
  yield takeLatest(actions.getUsers.type, getUsers);
  yield takeLatest(actions.getUser.type, getUser);
  yield takeLatest(actions.updateUser.type, updateUser);
  yield takeLatest(actions.updatePayment.type, updatePayment);
  yield takeLatest(actions.addUserPickUp.type, addUserPickUp);
  yield takeLatest(actions.getUserPickUp.type, getUserPickUp);
  yield takeLatest(actions.getUserPickUps.type, getUserPickUps);
  yield takeLatest(actions.updateUserPickUp.type, updateUserPickUp);
  yield takeLatest(
    actions.downloadUserPickUpScans.type,
    downloadUserPickUpScans
  );
  yield takeLatest(actions.unlockHDScans.type, unlockHDScans);
  yield takeLatest(actions.unlockTiffScans.type, unlockTiffScans);
  yield takeLatest(actions.removeAddress.type, removeAddress);
  yield takeLatest(actions.getDigitalProducts.type, getDigitalProducts);
}

export default usersSaga;
