import { chain, get, omitBy, size } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

const useStyles = makeStyles(theme => ({
  action: {
    minWidth: '100px',
    [theme.breakpoints.down('sm')]: {
      height: '30px',
      padding: '0px 15px',
      width: '100%'
    },
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
      padding: '8px 22px',
      width: '100%'
    }
  },
  actions: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      flex: '1'
    }
  },
  root: {
    alignItems: 'center',
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'row',
    flex: '1',
    justifyContent: 'flex-end',
    paddingRight: '100px',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      width: '100%'
    },
    [theme.breakpoints.down('xs')]: {
      paddingRight: '0px'
    }
  }
}));

export default function PickUpListActionBar(props) {
  const classes = useStyles();
  const selected = omitBy(props.selected, (_, k) => k.split('_').length !== 1);
  const scansById = chain(props.allPickUps)
    .flatMap('rolls')
    .flatMap('scans')
    .keyBy('id')
    .value();

  function hdSelectedCount(prop) {
    return (
      chain(selected)
        .reject((_, id) => get(scansById, `${id}.${prop}`))
        // identity
        .filter()
        .size()
        .value()
    );
  }

  const eligible4kCount = hdSelectedCount('hiRes');
  const eligibleTiffCount = hdSelectedCount('tiff');
  const downloadable = omitBy(selected, v => !v);

  return (
    <div className={classes.root}>
      <ButtonGroup
        size="large"
        color="secondary"
        variant="contained"
        classes={{ root: classes.actions }}
        orientation={props.isSmallScreen ? 'vertical' : 'horizontal'}
      >
        <Button
          className={classes.action}
          disabled={props.downloading || !size(downloadable)}
          onClick={e => {
            e.stopPropagation();
            props.download(downloadable);
          }}
        >
          Download ({size(downloadable)})
        </Button>
        <Button
          disabled={!eligible4kCount}
          className={classes.action}
          onClick={e => {
            e.stopPropagation();
            props.onUnlock4k();
          }}
        >
          Unlock 4K ({eligible4kCount})
        </Button>
        <Button
          disabled={!eligibleTiffCount}
          className={classes.action}
          onClick={e => {
            e.stopPropagation();
            props.onUnlockTiff();
          }}
        >
          Unlock TIFF ({eligibleTiffCount})
        </Button>
        <Button
          className={classes.action}
          disabled={props.downloading}
          onClick={e => {
            e.stopPropagation();
            props.setSelected({});
          }}
        >
          Clear Selected
        </Button>
      </ButtonGroup>
    </div>
  );
}

PickUpListActionBar.propTypes = {
  download: PropTypes.func.isRequired,
  downloading: PropTypes.bool.isRequired,
  isSmallScreen: PropTypes.bool.isRequired,
  selected: PropTypes.shape({}).isRequired,
  onUnlock4k: PropTypes.func.isRequired,
  onUnlockTiff: PropTypes.func.isRequired,
  allPickUps: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
