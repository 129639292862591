import { chain, filter, findIndex, get, size, toLower } from 'lodash';
import AddonSku from '../Constants/AddonSku';

function resolveLineUpdate(lines, lineIndex, product, quantity, user) {
  if (lineIndex >= 0 && quantity) {
    const line = {
      ...lines[lineIndex],
      quantity: quantity,
      amount: quantity * product.price
    };
    return [...lines.slice(0, lineIndex), line, ...lines.slice(lineIndex + 1)];
  } else if (lineIndex >= 0 && !quantity) {
    return [...lines.slice(0, lineIndex), ...lines.slice(lineIndex + 1)];
  } else if (lineIndex < 0 && !quantity) {
    return lines;
  } else {
    const line = {
      customerId: user.id,
      orderId: null,
      productId: product.id,
      amount: quantity * product.price,
      notes: '',
      stripeId: null,
      quantity: quantity
    };
    return [...lines, line];
  }
}

function addE6Lines(lines, rolls, products, filmRolls, user) {
  const product = get(products, `${AddonSku.E_6}`, {});
  const lineIndex = findIndex(lines, l => l.productId === product.id);
  const quantity = size(
    filter(rolls, r => {
      const filmRoll = get(filmRolls, `${r.filmRollId}.filmRoll`);
      if (!filmRoll) return false;
      return toLower(filmRoll.native_process) === 'e-6';
    })
  );

  return resolveLineUpdate(lines, lineIndex, product, quantity, user);
}

function addAdditionalRollLines(lines, rolls, products, user) {
  const product = get(products, `${AddonSku.ADDITIONAL_ROLL}`, {});
  const lineIndex = findIndex(lines, l => l.productId === product.id);
  const rollsCount = size(rolls);
  const freeRolls = chain(user)
    .get('rollCredits')
    .size()
    .value();
  const quantity = rollsCount - freeRolls <= 0 ? 0 : rollsCount - freeRolls;

  return resolveLineUpdate(lines, lineIndex, product, quantity, user);
}

function addCrossProcessLines(lines, rolls, products, user) {
  const product = get(products, `${AddonSku.CROSS_PROCESS}`, {});
  const lineIndex = findIndex(lines, l => l.productId === product.id);
  const quantity = size(filter(rolls, r => !!r.crossProcessId));

  return resolveLineUpdate(lines, lineIndex, product, quantity, user);
}

function addPushPullLines(lines, rolls, products, user) {
  const product = get(products, `${AddonSku.PUSH_PULL_ROLL}`, {});
  const lineIndex = findIndex(lines, l => l.productId === product.id);
  const quantity = size(filter(rolls, r => !!r.pushPullId));

  return resolveLineUpdate(lines, lineIndex, product, quantity, user);
}

export function makeAddOnLines(lines, rolls, products, filmRolls, user) {
  const withAdditional = addAdditionalRollLines(lines, rolls, products, user);
  const withE6 = addE6Lines(withAdditional, rolls, products, filmRolls, user);
  const withPushPull = addPushPullLines(withE6, rolls, products, user);
  return addCrossProcessLines(withPushPull, rolls, products, user);
}
