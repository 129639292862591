import { createSelector } from 'reselect';

const onboardingSelector = state => state.onboarding;

export const creatingSubscriptionSelector = createSelector(
  onboardingSelector,
  onboarding => onboarding.creatingSubscription
);

export const createSubscriptionErrorSelector = createSelector(
  onboardingSelector,
  onboarding => onboarding.createSubscriptionError
);

export const subscriptionCreatedSelector = createSelector(
  onboardingSelector,
  onboarding => onboarding.subscriptionCreated
);
