import PropTypes from 'prop-types';
import React, { useState } from 'react';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  arrows: {
    alignItems: 'center',
    display: 'flex'
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

const isPastDate = (date, delta, limit) => {
  if (!limit) return false;

  const nextDate = moment.utc(date).add(delta, 'days');

  return delta < 0
    ? nextDate.isBefore(moment.utc(limit))
    : nextDate.isAfter(moment.utc(limit));
};

export default function DateRangePicker(props) {
  const classes = useStyles();
  const [from, setFrom] = useState(props.from || moment().startOf('isoWeek'));
  const [to, setTo] = useState(props.to || from.clone().endOf('isoWeek'));

  function updateRange(weeks) {
    const nextFrom = from
      .clone()
      .startOf('isoWeek')
      .add(weeks, 'week');
    const firstDate = moment().add(1, 'days');

    const f = nextFrom.isBefore(firstDate) ? firstDate : nextFrom;
    const t = to.clone().add(weeks, 'week');
    setFrom(f);
    setTo(t);
    props.onChange(f, t);
  }

  return (
    <div className={classes.container}>
      <div>
        {from.format('MM/DD/YY')} - {to.format('MM/DD/YY')}
      </div>

      <div className={classes.arrows}>
        <div>
          <IconButton
            disabled={isPastDate(from, -1, moment())}
            onClick={() => updateRange(-1)}
            aria-label="Last Week"
          >
            <KeyboardArrowLeft />
          </IconButton>
        </div>
        <div>
          <IconButton
            disabled={isPastDate(to, 1, props.limit)}
            onClick={() => updateRange(1)}
            aria-label="One Week Forward"
          >
            <KeyboardArrowRight />
          </IconButton>
        </div>
      </div>
    </div>
  );
}

DateRangePicker.propTypes = {
  onChange: PropTypes.func.isRequired
};
