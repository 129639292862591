import React from 'react';
import { connect } from 'react-redux';
import PickUpsPage from '../../views/PickUpsPage/PickUpsPage';
import {
  availabilitiesSelector,
  loadingPickUpsSelector,
  pickUpsErrorSelector,
  pickUpsSelector
} from './selectors';
import { actions } from './slice';
import { actions as userActions } from '../Users/slice';
import promisifyActions from '../../lib/utils/promisify-actions';

const PickUps = props => {
  return <PickUpsPage {...props} />;
};

/**
 * The admin state to be provided to the admin component
 *
 * @param {object} state global state
 */
const mapStateToProps = state => ({
  loading: loadingPickUpsSelector(state),
  error: pickUpsErrorSelector(state),
  pickUps: pickUpsSelector(state),
  availabilities: availabilitiesSelector(state)
});

/**
 * Actions to be dispatched
 *
 * @param {function} dispatch function to dispatch admin adminActions
 */
const mapDispatchToProps = dispatch => ({
  addPickUp: pickUp => dispatch(actions.addPickUp({ pickUp })),
  downloadPickUpScans: (userId, pickUpId) =>
    dispatch(userActions.downloadUserPickUpScans({ userId, pickUpId })),
  getPickUps: params => dispatch(actions.getPickUps({ params })),
  updatePickUp: pickUp => dispatch(actions.updatePickUp({ pickUp })),
  getAvailabilities: (userId, params) =>
    dispatch(actions.getAvailabilities({ userId, params }))
});

/**
 * Connects admin state to the App component
 *
 * @param {object} mapStateToProps admin data object to become props for the App component
 * @param {object} mapDispatchToProps admin adminActions to be dispatched to reducer
 */
export default connect(
  mapStateToProps,
  promisifyActions(mapDispatchToProps)
)(PickUps);
