import Header from '../Header/Header';
import React from 'react';
import AdminHeaderLinks from './AdminHeaderLinks';

export default function AdminHeader(props) {
  return (
    <Header
      color="dark"
      brand="darklab"
      links={<AdminHeaderLinks {...props} dropdownHoverColor="dark" />}
      fixed
      changeColorOnScroll={{
        height: 300,
        color: 'dark'
      }}
      {...props}
    />
  );
}
