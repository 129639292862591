import React from 'react';
import { connect } from 'react-redux';
import {
  availabilitiesSelector,
  loadingPickUpsSelector,
  pickUpSelector,
  pickUpsErrorSelector,
  productsSelector
} from './selectors';
import { actions } from './slice';
import { actions as userActions } from '../Users/slice';
import PickUpPage from '../../views/PickUpPage/PickUpPage';
import promisifyActions from '../../lib/utils/promisify-actions';

const PickUp = props => {
  return <PickUpPage {...props} />;
};

/**
 * The admin state to be provided to the admin component
 *
 * @param {object} state global state
 */
const mapStateToProps = state => ({
  availabilities: availabilitiesSelector(state),
  loading: loadingPickUpsSelector(state),
  error: pickUpsErrorSelector(state),
  pickUp: pickUpSelector(state),
  products: productsSelector(state)
});

/**
 * Actions to be dispatched
 *
 * @param {function} dispatch function to dispatch admin adminActions
 */
const mapDispatchToProps = dispatch => ({
  addPickUp: pickUp => dispatch(actions.addPickUp({ pickUp })),
  addFilmRoll: film => dispatch(actions.addFilmRoll({ film })),
  addFilmRollScan: (film, scanFile, onProgress) =>
    dispatch(actions.addFilmRollScan({ film, scanFile, onProgress })),
  getPickUpProducts: () => dispatch(actions.getPickUpProducts()),
  getAvailabilities: (userId, params) =>
    dispatch(actions.getAvailabilities({ userId, params })),
  getPickUp: (userId, pickUpId) => dispatch(userActions.getUserPickUp({ userId, pickUpId })),
  removeAddress: (userId, addressId) =>
    dispatch(userActions.removeAddress({ userId, addressId })),
  removeFilmRollScan: (film, scanId) =>
    dispatch(actions.removeFilmRollScan({ film, scanId })),
  updatePickUp: pickUp => dispatch(actions.updatePickUp({ pickUp })),
  updateFilmRoll: film => dispatch(actions.updateFilmRoll({ film }))
});

/**
 * Connects admin state to the App component
 *
 * @param {object} mapStateToProps admin data object to become props for the App component
 * @param {object} mapDispatchToProps admin adminActions to be dispatched to reducer
 */
export default connect(
  mapStateToProps,
  promisifyActions(mapDispatchToProps)
)(PickUp);
