import React from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// core components
import Footer from '../../components/Footer/Footer.js';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  block: {
    color: 'inherit',
    padding: '0',
    fontWeight: '500',
    fontSize: '12px',
    textTransform: 'uppercase',
    borderRadius: '3px',
    textDecoration: 'none',
    position: 'relative',
    display: 'block',
    '&:visited': {
      color: theme.palette.primary.main
    },
    '&:hover': {
      color: theme.palette.primary.dark
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '8px'
    }
  },
  downcase: {
    textTransform: 'lowercase'
  },
  footer: {
    padding: '15px 50px',
    [theme.breakpoints.down('xs')]: {
      padding: '15px 20px'
    }
  },
  inlineBlock: {
    display: 'inline-block',
    padding: '0px',
    width: 'auto',
    [theme.breakpoints.down('xs')]: {
      fontSize: '8px'
    }
  },
  left: {
    float: 'left!important',
    display: 'block'
  },
  list: {
    marginBottom: '0',
    padding: '0',
    marginTop: '0',
    '& > li': {
      marginLeft: '10px'
    }
  },
  right: {
    padding: '0',
    margin: '0',
    float: 'right'
  }
}));

export default function AppFooter() {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <div>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a
                href="mailto:josh@darklabsubscription.com"
                className={classes.block}
              >
                contact
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                target="_blank"
                href="https://www.instagram.com/darklabsubscription/"
                className={classes.block}
              >
                instagram
              </a>
            </ListItem>
          </List>
        </div>
        <div className={classes.right}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a href="/privacy-policy" className={classes.block}>
                Privacy Policy
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a href="/" className={classes.block}>
                Copyright 2020 darklab llc
              </a>
            </ListItem>
          </List>
        </div>
      </div>
    </footer>
  );
}
