import cx from 'classnames';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  active: {
    fontWeight: 'bold'
  },
  header: {
    cursor: 'pointer'
  }
}));

export default function TableHeader(props) {
  const classes = useStyles();
  const { sortBy, text, params, setParams } = props;
  return (
    <div
      className={cx(classes.header, {
        [classes.active]: sortBy === params.sortBy
      })}
      onClick={() =>
        setParams(p => ({
          ...p,
          desc: !params.desc,
          sortBy
        }))
      }
    >
      {text}
    </div>
  );
}
