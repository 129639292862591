import React from 'react';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';

export default withGoogleMap(props => (
  <GoogleMap
    defaultZoom={props.zoom || 14}
    defaultCenter={{
      lat: props.center.place.lat,
      lng: props.center.place.lng
    }}
    center={{ lat: props.center.place.lat, lng: props.center.place.lng }}
  >
    {props.route.map(({ pickUp, place }) => (
      <Marker key={pickUp.id} position={place} />
    ))}
  </GoogleMap>
));
