import { get, chain } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import querystring from 'querystring';

import DateSwitcherBar from '../../../components/DateSwitcherBar/DateSwitcherBar';
import MessengerRoute from '../../../components/MessengerRoute/MessengerRoute';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  routeActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '15px 0px'
  },
  routeAction: {
    marginLeft: '15px'
  }
}));

const getMessengerId = location => {
  return chain(location)
    .get('pathname')
    .split('/')
    .at(3)
    .value();
};

const setInitialState = search => {
  const queryParams = querystring.decode(search);
  return {
    from: moment(queryParams.from)
      .startOf('day')
      .toISOString(),
    to: moment(queryParams.to)
      .endOf('day')
      .toISOString()
  };
};

export default function MessengerRoutes(props) {
  const classes = useStyles();
  const [messengerId] = useState(getMessengerId(props.location));
  const [params, setParams] = useState(
    setInitialState(props.location.search.slice(1))
  );

  useEffect(() => {
    props.getMessenger(messengerId);
  }, [messengerId]);

  useEffect(() => {
    const { history, location } = props;
    const { from, to } = params;

    history.push({ ...location, search: querystring.encode(params) });
    props.getRoutes(messengerId, from, to);
  }, [params]);

  function notifyNextStop() {
    props.notifyNextStop(
      messengerId,
      moment()
        .startOf('day')
        .toISOString(),
      moment()
        .endOf('day')
        .toISOString()
    );
  }

  return (
    <div>
      <div className={classes.routeActions}>
        <Button
          className={classes.routeAction}
          color="primary"
          variant="contained"
          onClick={notifyNextStop}
        >
          Send Start Route Message
        </Button>
        <Link
          target="_blank"
          to={`/admin/messengers/${props.messenger.id}/printable-routes?from=${params.from}&to=${params.to}`}
        >
          <Button
            className={classes.routeAction}
            color="primary"
            variant="contained"
          >
            Print Route
          </Button>
        </Link>
      </div>

      <DateSwitcherBar
        day={moment(params.from)}
        onChange={day =>
          setParams(p => ({
            ...p,
            from: day
              .clone()
              .startOf('day')
              .toISOString(),
            to: day
              .clone()
              .endOf('day')
              .toISOString()
          }))
        }
      />

      {props.routes.map(route => (
        <MessengerRoute
          startRoute={props.startRoute}
          route={route}
          key={get(route, '0.pickUp.id')}
        />
      ))}
    </div>
  );
}

MessengerRoutes.propTypes = {
  messenger: PropTypes.shape({}),
  getMessenger: PropTypes.func.isRequired,
  getRoutes: PropTypes.func.isRequired
};
