import cx from 'classnames';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DangerButton from '../../../components/Button/DangerButton';

const useStyles = makeStyles(theme => ({
  card: {
    cursor: 'pointer',
    backgroundColor: theme.palette.white.main,
    border: props =>
      props.selected ? `5px solid ${theme.palette.secondary.main}` : 'none',
    display: 'flex',
    height: '100px',
    justifyContent: 'space-between',
    margin: '0px 10px 10px 0px',
    padding: '10px 5px',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.primary.main,
      cursor: 'pointer'
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '10px',
      height: '90px'
    }
  },
  cardAction: {
    fontSize: '11px',
    '&:first-child': {
      marginBottom: '5px'
    }
  },
  cardActions: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 5px'
  },
  cardDetails: {
    fontSize: '0.9rem',
    lineHeight: '1.15em',
    padding: '0px 5px',
    minHeight: '40px'
  },
  confirmationModalActions: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '50px',
    '& button': {
      margin: '0px 5px 0px 0px'
    }
  },
  confirmationModalContent: {
    display: 'flex',
    flexDirection: 'column',
    margin: '10px 0px'
  },
  primary: {
    color: theme.palette.success.dark,
    fontWeight: 'bold'
  }
}));

export default function PickUpAddress(props) {
  const classes = useStyles(props);
  const { address } = props;
  const { id, address1, address2, city, state, zip, primary, userId } = address;
  const [openConfirmation, setOpenConfirmation] = useState(false);

  return (
    <Card className={classes.card} onClick={() => props.onSelect(id)}>
      <div className={classes.cardDetails}>
        <div>{`${address1}`}</div>

        {address2 && <div>{`${address2}`}</div>}

        <div>{`${city} ${state}, ${zip}`}</div>

        {primary ? (
          <div className={classes.primary}>Primary Address</div>
        ) : (
          <br />
        )}
      </div>
      {/*<div className={classes.cardActions}>*/}
      {/*  <Button*/}
      {/*    size="small"*/}
      {/*    className={classes.cardAction}*/}
      {/*    disabled={props.disabled}*/}
      {/*    variant="contained"*/}
      {/*    color="secondary"*/}
      {/*    disabled={props.selected}*/}
      {/*    onClick={props.onEdit}*/}
      {/*    onClick={() => props.onSelect(id)}*/}
      {/*  >*/}
      {/*    {props.selected ? 'Selected' : 'Select'}*/}
      {/*  </Button>*/}
      {/*  /!*<Button*!/*/}
      {/*  /!*  size="small"*!/*/}
      {/*  /!*  className={classes.cardAction}*!/*/}
      {/*  /!*  disabled={props.disabled}*!/*/}
      {/*  /!*  variant="contained"*!/*/}
      {/*  /!*  color="primary"*!/*/}
      {/*  /!*  onClick={props.onEdit}*!/*/}
      {/*  /!*>*!/*/}
      {/*  /!*  Edit*!/*/}
      {/*  /!*</Button>*!/*/}
      {/*</div>*/}

      <Dialog
        open={openConfirmation}
        onClose={() => setOpenConfirmation(false)}
      >
        <DialogContent>
          <div className={classes.confirmationModalContent}>
            Are you sure you want to remove this address ?
            <div className={classes.confirmationModalActions}>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={() => setOpenConfirmation(false)}
              >
                Cancel
              </Button>
              <DangerButton
                size="small"
                variant="contained"
                onClick={() => props.removeAddress(userId, id)}
              >
                Confirm
              </DangerButton>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </Card>
  );
}

PickUpAddress.propTypes = {
  selected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  removeAddress: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired
};
