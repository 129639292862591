import React from 'react';
import { connect } from 'react-redux';
import UserSettingsPage from '../../views/UserSettingsPage/UserSettingsPage';
import {
  loadingUsersSelector,
  usersErrorSelector,
  userSelector,
  userPickUpsSelector,
  userPickUpsCountSelector,
  productsSelector
} from './selectors';
import { actions } from './slice';
import { availabilitiesSelector } from '../PickUps/selectors';
import promisifyActions from '../../lib/utils/promisify-actions';

const UserSettings = props => {
  return <UserSettingsPage {...props} />;
};

/**
 * The admin state to be provided to the admin component
 *
 * @param {object} state global state
 */
const mapStateToProps = state => ({
  availabilities: availabilitiesSelector(state),
  error: usersErrorSelector(state),
  loading: loadingUsersSelector(state),
  pickUps: userPickUpsSelector(state),
  pickUpsCount: userPickUpsCountSelector(state),
  user: userSelector(state),
  products: productsSelector(state)
});

/**
 * Actions to be dispatched
 *
 * @param {function} dispatch function to dispatch admin adminActions
 */
const mapDispatchToProps = dispatch => ({
  getUser: userId => dispatch(actions.getUser({ userId })),
  updateUser: (userId, update) =>
    dispatch(actions.updateUser({ userId, update })),
  updatePayment: (userId, token) =>
    dispatch(actions.updatePayment({ userId, token })),
  removeAddress: (userId, addressId) =>
    dispatch(actions.removeAddress({ userId, addressId }))
});

/**
 * Connects admin state to the App component
 *
 * @param {object} mapStateToProps admin data object to become props for the App component
 * @param {object} mapDispatchToProps admin adminActions to be dispatched to reducer
 */
export default connect(
  mapStateToProps,
  promisifyActions(mapDispatchToProps)
)(UserSettings);
