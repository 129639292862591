import cx from 'classnames';
import moment from 'moment';
import { chain, get, toLower } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  alignCenter: {
    textAlign: 'center'
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '5px 0px'
  },
  duplicate: {
    cursor: 'pointer',
    fill: theme.palette.primary.light,
    '&:hover': {
      fill: theme.palette.primary.dark
    }
  },
  flex1: {
    flex: '1'
  },
  flex2: {
    flex: '2'
  },
  mobileHide: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  name: {
    cursor: 'text',
    flex: '5'
  },
  optionsBtn: {
    position: 'relative'
  },
  optionsBadge: {
    position: 'absolute',
    top: '-10px',
    right: '-10px',
    fontSize: '12px',
    padding: '2px 5px',
    borderRadius: '50%',
    background: theme.palette.success.dark,
    color: theme.palette.bg.main
  },
  remove: {
    cursor: 'pointer',
    fill: theme.palette.primary.light,
    '&:hover': {
      fill: theme.palette.primary.dark
    }
  }
}));

export default function RollToReceive(props) {
  const classes = useStyles();
  const detailedFilmRoll = props.filmRolls[props.roll.filmRollId];

  function formatName(roll) {
    const type = get(roll, 'native_process');
    const name = get(roll, 'name');
    return toLower(type) === 'e-6' ? `${name} (E6 + $5.99)` : name;
  }

  return (
    <div className={classes.container}>
      <div className={classes.name}>
        <span>{formatName(detailedFilmRoll)}</span>
      </div>

      <div className={cx(classes.flex1, classes.mobileHide)}>
        {get(detailedFilmRoll, 'iso')}
      </div>
      <div className={cx(classes.flex1, classes.mobileHide)}>
        {get(detailedFilmRoll, 'format')}
      </div>
      <div
        className={cx(classes.flex2, classes.alignCenter, classes.mobileHide)}
      >
        <Checkbox
          onChange={e => {
            props.updateRoll('returnToCustomer', !e.target.checked);
          }}
          checked={!props.roll.returnToCustomer}
        />
      </div>
      <div className={classes.flex2}>
        {chain([
          chain(detailedFilmRoll)
            .get('crossProcessOptions')
            .find(opt => opt.id === props.roll.crossProcessId)
            .thru(opt => (opt ? `Cross Process as ${opt.targetFormat}` : null))
            .value(),
          chain(detailedFilmRoll)
            .get('pushPullOptions')
            .find(opt => opt.id === props.roll.pushPullId)
            .thru(opt =>
              opt ? `${opt.factor < 0 ? 'Pull' : 'Push'} ${opt.factor}` : null
            )
            .value()
        ])
          .compact()
          .join(', ')
          .value()}
      </div>
      <div className={classes.flex1}>
        <Checkbox
          onChange={e => {
            props.updateRoll(
              'receivedAt',
              e.target.checked ? moment().toISOString() : null
            );
          }}
          checked={!!props.roll.receivedAt}
        />
      </div>
    </div>
  );
}

RollToReceive.propTypes = {
  filmRolls: PropTypes.shape({}).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  roll: PropTypes.shape({}).isRequired,
  updateRoll: PropTypes.func.isRequired
};
