/* eslint-disable */
import React from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// react components for routing our app without refresh
import { Link } from 'react-router-dom';

// @material-ui/core components
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

// @material-ui/icons
import Face from '@material-ui/icons/Face';
import LocalShipping from '@material-ui/icons/LocalShipping';
import People from '@material-ui/icons/People';

import { makeStyles } from '@material-ui/core/styles';
import styles from 'assets/jss/material-kit-pro-react/components/headerLinksStyle.js';

const useStyles = makeStyles(styles);

export default function AdminHeaderLinks(props) {
  const classes = useStyles();

  return (
    <List className={classes.list + ' ' + classes.mlAuto}>
      <ListItem className={classes.listItem}>
        <Link to="/admin/users-list" className={classes.dropdownLink}>
          <People className={classes.dropdownIcons} /> Users
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/admin/pick-ups" className={classes.dropdownLink}>
          <LocalShipping className={classes.dropdownIcons} />{' '}
          <div>Pick Ups</div>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/admin/messengers" className={classes.dropdownLink}>
          <Face className={classes.dropdownIcons} /> Messengers
        </Link>
      </ListItem>
      <ListItem>
        <Button
          color={window.innerWidth < 960 ? 'info' : 'white'}
          className={classes.navButton}
          onClick={props.logout}
          variant="contained"
          color="secondary"
        >
          Log Out
        </Button>
      </ListItem>
    </List>
  );
}

AdminHeaderLinks.defaultProps = {
  hoverColor: 'primary'
};

AdminHeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    'dark',
    'primary',
    'info',
    'success',
    'warning',
    'danger',
    'rose'
  ])
};
