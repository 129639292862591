import { omit } from 'lodash';
import cx from 'classnames';
import React from 'react';
import Button from '@material-ui/core/Button';

import buttonStyles from './styles/buttonStyles';

export default function DangerButton(props) {
  const classes = buttonStyles();

  return <Button className={cx(classes.danger, props.className)} {...omit(props, 'className')} />;
}
