import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';

import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';

import styles from './styles/routeStopStyles';
import formatPhoneNumber from '../../lib/utils/format-phone-number';

export default function RouteStop(props) {
  const classes = styles(props);
  const {
    address,
    user: { firstName, lastName, phone, profileImageUrl },
    pickedUpAt
  } = props;
  const initials = firstName.charAt(0) + lastName.charAt(0);
  const { address1, address2, city, state, zip } = address;

  return (
    <Card raised className={classes.card} onMouseEnter={props.onMouseEnter}>
      <Avatar src={profileImageUrl}>{initials}</Avatar>
      <div className={classes.details}>
        <div className={classes.name}>{`${firstName} ${lastName}`}</div>
        <div className={classes.phone}>{formatPhoneNumber(phone)}</div>
        <div className={classes.address}>
          {`${address1}`}

          {address2 && <br />}
          {address2 && address2}
          <br />
          {`${city} ${state}, ${zip}`}
        </div>
        {pickedUpAt && (
          <div className={classes.pickedUpAt}>{`Picked Up At: ${moment(
            pickedUpAt
          ).format('LLL')}`}</div>
        )}
      </div>
    </Card>
  );
}

RouteStop.propTypes = {
  user: PropTypes.shape({}),
  address: PropTypes.shape({})
};
