/* global google */
import { assign, debounce, get, map, first, split } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';

import GoogleAPI from '../../lib/Api/Google';

const selectStyles = {
  control: (provided, state) => {
    return {
      ...provided,
      ':hover': {
        borderColor: '#D2D2D2',
        borderBottomWidth: '1px'
      },
      'div ': {
        padding: '0px'
      },
      borderTop: '0px',
      borderLeft: '0px',
      borderRight: '0px',
      borderBottomColor: '#D2D2D2',
      borderColor: state.isFocused ? 'none' : 'none',
      borderRadius: '0px',
      boxShadow: state.isFocused ? '0px' : '0px'
    };
  },
  indicatorSeparator: provided => ({ ...provided, display: 'none' }),
  valueContainer: provided => ({
    ...provided,
    marginLeft: '0px'
  })
};

export default function AddressAutocomplete(props) {
  const service = new google.maps.places.AutocompleteService();
  const [suggestions, setSuggestions] = useState(
    props.place.value ? [props.place] : []
  );
  const [loading, setLoading] = useState(false);
  const [place, setPlace] = useState(props.place.value ? props.place : null);
  const selectRef = useRef(null);

  useEffect(() => {
    selectRef.current.focus();
  }, [selectRef]);

  function displaySuggestions(placesSuggestions) {
    const formattedSuggestions = map(
      placesSuggestions,
      ({ description, place_id }) => {
        return {
          label: description,
          value: place_id
        };
      }
    );

    setSuggestions(formattedSuggestions);
    setLoading(false);
  }

  const onInputChange = debounce(input => {
    if (!input) return;

    setLoading(true);
    service.getQueryPredictions({ input }, displaySuggestions);
  }, 500);

  function onChange(place) {
    if (!place) return;

    const placeId = get(place, 'value');

    GoogleAPI.getPlace(placeId, details => {
      const label = first(split(place.label, ','));

      setPlace(assign(place, { label }));
      props.onSelect(details, placeId);
    });
  }

  return (
    <Select
      ref={selectRef}
      styles={selectStyles}
      options={suggestions}
      value={place}
      filterOption={() => true}
      onInputChange={onInputChange}
      placeholder="Address"
      isLoading={loading}
      onChange={onChange}
    />
  );
}

AddressAutocomplete.propTypes = {
  onSelect: PropTypes.func.isRequired
};
