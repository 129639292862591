import React from 'react';
import { connect } from 'react-redux';
import MessengersPage from '../../views/MessengersPage/MessengersPage';
import {
  loadingMessengersSelector,
  messengersErrorSelector,
  messengersSelector
} from './selectors';
import { actions } from './slice';

const Messengers = props => {
  return <MessengersPage {...props} />;
};

/**
 * The admin state to be provided to the admin component
 *
 * @param {object} state global state
 */
const mapStateToProps = state => ({
  loading: loadingMessengersSelector(state),
  error: messengersErrorSelector(state),
  messengers: messengersSelector(state)
});

/**
 * Actions to be dispatched
 *
 * @param {function} dispatch function to dispatch admin adminActions
 */
const mapDispatchToProps = dispatch => ({
  getMessengers: () => dispatch(actions.getMessengers())
});

/**
 * Connects admin state to the App component
 *
 * @param {object} mapStateToProps admin data object to become props for the App component
 * @param {object} mapDispatchToProps admin adminActions to be dispatched to reducer
 */
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Messengers);
