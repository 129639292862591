import cx from 'classnames';
import { keyBy, sumBy } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import Divider from '@material-ui/core/Divider';

import OrderLine from '../OrderLine/OrderLine';
import OrderTotal from '../OrderTotal/OrderTotal';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  checkCircle: {
    fill: theme.palette.success.dark,
    marginLeft: '10px'
  },
  container: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column'
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between'
  },
  headerText: {
    alignItems: 'center',
    display: 'flex'
  },
  summary: {
    backgroundColor: 'transparent',
    padding: '20px 10px'
  },
  summaryHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 'bold'
  },
  summaryHeader1: {
    flex: '1'
  },
  summaryHeader2Mobile: {
    [theme.breakpoints.down('sm')]: {
      flex: '2'
    }
  },
  summaryHeader5: {
    flex: '5'
  },
  tableTitle: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

export default function OrderSummary(props) {
  const classes = useStyles(props);
  const productsById = keyBy(props.products, 'id');

  const validLines = props.lines.filter(
    line => line.quantity !== 0 && line.productId
  );

  console.log(validLines);

  return (
    <div className={classes.container}>
      <div className={classes.summary}>
        <div className={classes.summaryHeader}>
          <div className={classes.summaryHeader5}>Item</div>
          <div
            className={cx(classes.summaryHeader1, classes.summaryHeader2Mobile)}
          >
            {props.isSmallScreen ? '$' : 'Price'}
          </div>
          <div
            className={cx(classes.summaryHeader1, classes.summaryHeader2Mobile)}
          >
            {props.isSmallScreen ? 'Qty' : 'Quantity'}
          </div>
          <div
            className={cx(classes.summaryHeader1, classes.summaryHeader2Mobile)}
          >
            Total
          </div>
        </div>

        {validLines.map((line, i) => (
          <OrderLine
            key={line.id || i}
            products={productsById}
            line={line}
            isSmallScreen={props.isSmallScreen}
          />
        ))}
      </div>

      <Divider />

      <OrderTotal
        showPickUpFee={props.showPickUpFee}
        total={sumBy(validLines, 'amount')}
        pickUpFee={props.chargePickUp ? 9.99 : 0}
      />
    </div>
  );
}

OrderSummary.propTypes = {
  chargePickUp: PropTypes.bool,
  isSmallScreen: PropTypes.bool.isRequired,
  lines: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showPickUpFee: PropTypes.bool,
  user: PropTypes.shape({}).isRequired
};

OrderSummary.defaultProps = {
  chargePickUp: false,
  showPickUpFee: false
};
