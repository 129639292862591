import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogTitle, Divider } from '@material-ui/core';
import StripeCheckout from '../../../components/StripeCheckout/StripeCheckout';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  content: {
    marginRight: '5px'
  },
  dialog: {},
  dialogPaper: {}
}));

export function UpdatePaymentModal(props) {
  const classes = useStyles();
  return (
    <Dialog
      classes={{ root: classes.dialog, paper: classes.dialogPaper }}
      onClose={props.onClose}
      open={props.open}
    >
      <DialogTitle>HD Unlocks Order Confirmation</DialogTitle>
      <Divider />
      <DialogContent className={classes.content}>
        <StripeCheckout
          cancel
          onSubmit={props.updatePayment}
          onCancel={props.onClose}
          onSuccess={props.onClose}
          {...props}
        />
      </DialogContent>
    </Dialog>
  );
}

UpdatePaymentModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  updatePayment: PropTypes.func.isRequired
};
