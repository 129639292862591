import { get } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, withRouter } from 'react-router';
import styles from './App.module.css';
import ErrorPage from '../../views/ErrorPage/ErrorPage';
import LandingPage from '../../views/LandingPage/LandingPage';
import { actions } from './slice';
import {
  currentUserSelector,
  gettingCurrentUserSelector,
  isLoggedInSelector,
  loggingInSelector,
  loginErrorSelector,
  signingUpSelector,
  signupErrorSelector,
  waitlistUserSelector
} from './selectors';
import { actions as userActions } from '../Users/slice';
import { userSelector } from '../Users/selectors';
import Admin from '../Admin/Admin';
import PrivateRoute from '../../components/PrivateRoute/PrivateRoute';
import Messengers from '../Messengers/Messengers';
import Messenger from '../Messengers/Messenger';
import PickUp from '../PickUps/PickUp';
import PickUps from '../PickUps/PickUps';
import User from '../Users/User';
import Users from '../Users/Users';
import { Redirect } from 'react-router-dom';
import Role from '../../lib/Constants/Role';
import CustomerSideBar from '../../components/CustomerSideBar/CustomerSideBar';
import promisifyActions from '../../lib/utils/promisify-actions';
import UserAPI from '../../lib/Api/User';
import UserSettings from '../Users/UserSettings';
import ReceivePickUp from '../PickUps/ReceivePickUp';
import PrivacyPolicyPage from '../../views/PrivacyPolicyPage/PrivacyPolicyPage';
import PrintableRoutes from '../Messengers/PrintableRoutes';

/**
 * Renders app component
 */
class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: window.innerWidth
    };

    if (props.isLoggedIn) {
      props.getCurrentUser();
    }

    this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange() {
    this.setState({ width: window.innerWidth });
  }
  render() {
    const { currentUser, isLoggedIn } = this.props;
    const isActive = isLoggedIn && get(currentUser, 'active');
    const userRole = isLoggedIn && get(currentUser, 'role');

    return (
      <div className={styles.app}>
        <Switch>
          <Route
            exact
            path="/"
            render={() => {
              if (isActive && get(currentUser, 'role') === Role.ADMIN) {
                return <Redirect to="/admin/pick-ups" />;
              }
              if (isActive && get(currentUser, 'role') === Role.CUSTOMER) {
                return <Redirect to={`/users/${currentUser.id}`} />;
              }
              return <LandingPage {...this.props} />;
            }}
          />

          <Route
            exact
            path="/privacy-policy"
            component={PrivacyPolicyPage}
            {...this.props}
          />

          {!isLoggedIn && <Redirect to="/" />}

          <PrivateRoute
            path="/admin/messengers/:messengerId/printable-routes"
            exact
            {...this.props}
            component={PrintableRoutes}
          />

          <Route
            path="/admin"
            render={() => (
              <Admin {...this.props}>
                <PrivateRoute
                  path="/admin/messengers"
                  exact
                  {...this.props}
                  component={Messengers}
                />
                <PrivateRoute
                  path="/admin/messengers/:messengerId"
                  exact
                  {...this.props}
                  component={Messenger}
                />
                <PrivateRoute
                  path="/admin/pick-ups"
                  exact
                  isAdmin
                  {...this.props}
                  component={PickUps}
                />
                <PrivateRoute
                  path="/admin/users-list"
                  exact
                  {...this.props}
                  component={Users}
                />
                <Route
                  path="/admin/users/"
                  render={() => (
                    <CustomerSideBar
                      {...this.props}
                      uploadProfilePic={UserAPI.uploadProfilePic}
                    >
                      <PrivateRoute
                        path="/admin/users/:userId/pick-ups/new"
                        exact
                        {...this.props}
                        component={PickUp}
                        new
                      />
                      <PrivateRoute
                        path="/admin/users/:userId/pick-ups/:pickUpId"
                        exact
                        {...this.props}
                        component={PickUp}
                      />
                      <PrivateRoute
                        path="/admin/users/:userId/pick-ups/:pickUpId/receive"
                        exact
                        {...this.props}
                        component={ReceivePickUp}
                      />
                      <PrivateRoute
                        path="/admin/users/:userId"
                        exact
                        {...this.props}
                        component={User}
                      />
                    </CustomerSideBar>
                  )}
                ></Route>
              </Admin>
            )}
          />
          <Route
            path="/users"
            render={() => (
              <CustomerSideBar
                {...this.props}
                uploadProfilePic={UserAPI.uploadProfilePic}
              >
                <Switch>
                  <PrivateRoute
                    path="/users/:userId"
                    exact
                    {...this.props}
                    isAdmin={false}
                    component={User}
                  />
                  <PrivateRoute
                    path="/users/:userId/settings"
                    exact
                    {...this.props}
                    isAdmin={false}
                    component={UserSettings}
                  />
                  <PrivateRoute
                    path="/users/:userId/pick-ups/new"
                    exact
                    {...this.props}
                    isAdmin={false}
                    component={PickUp}
                    new
                  />
                  <PrivateRoute
                    path="/users/:userId/pick-ups/:pickUpId"
                    exact
                    {...this.props}
                    isAdmin={false}
                    component={PickUp}
                  />
                </Switch>
              </CustomerSideBar>
            )}
          />

          {isActive && userRole === Role.ADMIN && (
            <Redirect to="/admin/pick-ups" />
          )}
          {isActive && userRole === Role.CUSTOMER && (
            <Redirect to={`/users/${currentUser.id}`} />
          )}
          <Route path="/*" component={ErrorPage} />
        </Switch>
      </div>
    );
  }
}

App.propTypes = {};

App.defaultProps = {};

/**
 * The app state to be provided to the app component
 *
 * @param {object} state global state
 */
const mapStateToProps = state => ({
  gettingCurrentUser: gettingCurrentUserSelector(state),
  getCurrentUserError: gettingCurrentUserSelector(state),
  currentUser: currentUserSelector(state),
  isLoggedIn: isLoggedInSelector(state),
  loggingIn: loggingInSelector(state),
  loginError: loginErrorSelector(state),
  signupError: signupErrorSelector(state),
  signingUp: signingUpSelector(state),
  user: userSelector(state),
  waitlistUser: waitlistUserSelector(state)
});

/**
 * Actions to be dispatched
 *
 * @param {function} dispatch function to dispatch app actions
 */
const mapDispatchToProps = dispatch => ({
  signup: userData => dispatch(actions.signup({ userData })),
  login: userData => dispatch(actions.login({ userData })),
  logout: () => dispatch(actions.logout()),
  getCurrentUser: () => dispatch(actions.getCurrentUser()),
  getUser: userId => dispatch(userActions.getUser({ userId })),
  addWaitlistUser: userData => dispatch(actions.addWaitlistUser({ userData }))
});

/**
 * Connects app state to the App component
 *
 * @param {object} mapStateToProps app data object to become props for the App component
 * @param {object} mapDispatchToProps app actions to be dispatched to reducer
 */
export default withRouter(
  connect(
    mapStateToProps,
    promisifyActions(mapDispatchToProps)
  )(App)
);
