import { get, keyBy, isEmpty, trim, some } from 'lodash';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import AddressAutocomplete from './AddressAutocomplete';
import CustomInput from '../CustomInput/CustomInput';

import style from '../../assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles';
import { makeStyles } from '@material-ui/core/styles';

const styles = theme => {
  const jsStyles = style(theme);
  return {
    ...jsStyles,
    form: {
      ...jsStyles.form,
      marginTop: '10px'
    }
  };
};

const useStyles = makeStyles(styles);
const emptyAddress = isOnboarding => ({
  id: null,
  placeId: null,
  address1: '',
  address2: '',
  city: '',
  zip: '',
  state: '',
  country: 'United States of America',
  primary: isOnboarding
});

export default function AddressForm(props) {
  const classes = useStyles();
  const userId = get(props, 'user.id');
  const [address, setAddress] = useState({
    ...emptyAddress(!!props.onboarding),
    ...props.address,
    userId
  });
  const activeZipCodes = keyBy(props.zipCodes, 'zip');
  const fetchActiveZips = true;

  useEffect(() => {
    props.getActiveZipCodes();
  }, [fetchActiveZips]);

  function handleChange(e) {
    e.persist();

    setAddress(addr => ({ ...addr, [e.target.name]: e.target.value }));

    const isValid =
      !isEmpty(address.address1) &&
      !isEmpty(address.zip) &&
      !!activeZipCodes[address.zip];

    props.onChange(address, isValid);
  }

  function onSelectAddress(place, placeId) {
    if (!place) return;

    const [street, city, stateZip] = place.formatted_address
      .split(',')
      .map(trim);
    const [state, zip] = stateZip.split(' ');

    if (some([street, city, state, zip], isEmpty)) return;

    setAddress(addr => ({
      ...addr,
      address1: street,
      city,
      state,
      zip,
      placeId
    }));
  }

  function submitForm(e) {
    if (e) e.preventDefault();
    const saveAddress = address.id ? props.updateAddress : props.createAddress;

    saveAddress(userId, address).then(({ user }) => props.onUpdateUser(user));
  }

  return (
    <form className={classes.form} onSubmit={submitForm}>
      <AddressAutocomplete
        place={{ value: address.placeId, label: address.address1 }}
        onSelect={onSelectAddress}
      />
      <CustomInput
        formControlProps={{
          fullWidth: true,
          className: classes.customFormControlClasses
        }}
        inputProps={{
          type: 'text',
          onChange: handleChange,
          name: 'address2',
          value: address.address2,
          placeholder: 'Apartment, suite, etc...'
        }}
      />
      <CustomInput
        formControlProps={{
          fullWidth: true,
          className: classes.customFormControlClasses
        }}
        inputProps={{
          type: 'text',
          onChange: handleChange,
          name: 'city',
          value: address.city,
          placeholder: 'City'
        }}
      />
      <CustomInput
        formControlProps={{
          fullWidth: true,
          className: classes.customFormControlClasses
        }}
        inputProps={{
          type: 'text',
          onChange: handleChange,
          name: 'state',
          value: address.state,
          placeholder: 'State'
        }}
      />
      <CustomInput
        formControlProps={{
          fullWidth: true,
          className: classes.customFormControlClasses
        }}
        inputProps={{
          type: 'text',
          onChange: handleChange,
          name: 'zip',
          value: address.zip,
          placeholder: 'Zip'
        }}
      />
      {!props.onboarding && (
        <FormControlLabel
          control={
            <Checkbox
              checked={address.primary}
              onChange={e => {
                e.persist();
                setAddress(a => ({ ...a, primary: !address.primary }));
              }}
              value="true"
            />
          }
          label="Primary Pick Up Address"
        />
      )}

      <div className={classes.textCenter}>
        {address.zip && !activeZipCodes[address.zip] ? (
          <div>
            Unfortunately we don't deliver to this zip code yet, we will let you
            know once we're available near you or you can try another address.
          </div>
        ) : (
          <Button
            disabled={isEmpty(address.address1) || props.savingAddress}
            type="submit"
            color="primary"
            variant="contained"
          >
            Save Address
          </Button>
        )}
      </div>
    </form>
  );
}

AddressForm.propTypes = {
  address: PropTypes.shape({}),
  createAddress: PropTypes.func.isRequired,
  getActiveZipCodes: PropTypes.func.isRequired,
  onboarding: PropTypes.bool,
  onChange: PropTypes.func,
  onUpdateUser: PropTypes.func,
  savingAddress: PropTypes.bool.isRequired,
  updateAddress: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired,
  zipCodes: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

AddressForm.defaultProps = {
  onUpdateUser: () => {},
  onChange: () => {}
};
