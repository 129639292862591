import { chain, map } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { useSwipeable } from 'react-swipeable';

import { makeStyles } from '@material-ui/core/styles';
import RollSummaryThumb from './RollSummaryThumb';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.white.main,
    display: 'flex',
    flex: '1',
    minHeight: '80px',
    overflow: 'hidden',
    position: 'relative',
    width: '100%'
  }
}));

export function RollSummaryThumbs(props) {
  const classes = useStyles();
  const swipeHandlers = useSwipeable({
    onSwipedRight: () => props.setPrevThumbs(),
    onSwipedLeft: () => props.setNextThumbs()
  });

  const thumbWidth = 120;

  function makeX(index, len, width) {
    return index * width - len * width;
  }

  return (
    <div className={classes.root} {...swipeHandlers}>
      {map(props.thumbs, (thumb, i) => {
        const x = makeX(i, props.displayLen, thumbWidth);
        return (
          <RollSummaryThumb
            isNext={x === (props.displayLen - 1) * thumbWidth}
            isPrev={x === 0}
            isSelected={
              !!props.selected[
                chain(thumb.data.id)
                  .split('_')
                  .first()
                  .value()
              ]
            }
            key={thumb.data.id}
            openImage={props.openImage}
            setNextThumbs={props.setNextThumbs}
            setPrevThumbs={props.setPrevThumbs}
            setSelected={props.setSelected}
            thumb={thumb}
            x={x}
            visible={x >= 0 && x < props.displayLen * thumbWidth}
          />
        );
      })}
    </div>
  );
}

RollSummaryThumbs.propTypes = {
  displayLen: PropTypes.number.isRequired,
  setNextThumbs: PropTypes.func.isRequired,
  setPrevThumbs: PropTypes.func.isRequired,
  openImage: PropTypes.func.isRequired,
  roll: PropTypes.shape({}).isRequired,
  setSelected: PropTypes.func.isRequired,
  selected: PropTypes.shape({}).isRequired,
  thumbs: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
