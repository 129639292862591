/**
 * This file specifies the state of the application in response to App actions
 */
import { keyBy } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: null,
  user: null,
  users: [],
  pickUps: [],
  pickUpsCount: 0,
  products: []
};

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    addUser: state => ({ ...state, loading: true }),
    addUserError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error
    }),
    addUserSuccess: (state, action) => ({
      ...state,
      loading: false,
      error: null,
      user: action.payload.user,
      users: state.users.unshift(action.payload.user)
    }),
    getUser: state => ({ ...state, loading: true }),
    getUserError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error
    }),
    getUserSuccess: (state, action) => ({
      ...state,
      loading: false,
      error: null,
      user: action.payload.user
    }),
    getUsers: state => ({ ...state, loading: true }),
    getUsersError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error
    }),
    getUsersSuccess: (state, action) => ({
      ...state,
      loading: false,
      error: null,
      users: action.payload.users
    }),
    updateUser: state => ({ ...state, loading: true }),
    updateUserError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error
    }),
    updateUserSuccess: (state, action) => {
      const { user } = action.payload;
      const users = state.users.map(u => {
        if (u.id === user.id) return { ...u, ...user };
        return u;
      });

      return {
        ...state,
        loading: false,
        error: null,
        user,
        users
      };
    },
    updatePayment: state => ({ ...state }),
    updatePaymentError: (state, action) => ({
      ...state,
      loading: false,
      error: null
    }),
    updatePaymentSuccess: (state, action) => ({
      ...state,
      loading: false,
      error: null,
      user: action.payload.user
    }),
    addUserPickUp: state => ({ ...state, loading: true }),
    addUserPickUpError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error
    }),
    addUserPickUpSuccess: (state, action) => ({
      ...state,
      loading: false,
      error: null,
      pickUp: action.payload.pickUp,
      pickUps: [action.payload.pickUp].concat(state.pickUps)
    }),
    getUserPickUp: state => ({ ...state, loading: true }),
    getUserPickUpError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error
    }),
    getUserPickUpSuccess: (state, action) => ({
      ...state,
      loading: false,
      error: null,
      pickUp: action.payload.pickUp
    }),
    getUserPickUps: state => ({ ...state, loading: true }),
    getUserPickUpsError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error
    }),
    getUserPickUpsSuccess: (state, action) => ({
      ...state,
      loading: false,
      error: null,
      pickUps: action.payload.pickUps,
      pickUpsCount: action.payload.count
    }),
    updateUserPickUp: state => ({ ...state, loading: true }),
    updateUserPickUpError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error
    }),
    updateUserPickUpSuccess: (state, action) => {
      const { pickUp } = action.payload;
      const pickUps = state.pickUps.map(pu =>
        pu.id === pickUp.id ? pickUp : pu
      );

      return {
        ...state,
        loading: false,
        error: null,
        pickUp,
        pickUps
      };
    },
    downloadUserPickUpScans: state => ({ ...state, loading: true }),
    downloadUserPickUpScansError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error
    }),
    downloadUserPickUpScansSuccess: state => ({
      ...state,
      loading: false,
      error: null
    }),
    unlockHDScans: state => ({ ...state, loading: true }),
    unlockHDScansError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error
    }),
    unlockHDScansSuccess: (state, action) => {
      const { pickUps } = action.payload;
      const byId = keyBy(pickUps, 'id');

      return {
        ...state,
        loading: false,
        error: null,
        pickUps: state.pickUps.map(pu => {
          if (byId[pu.id]) return byId[pu.id];
          return pu;
        })
      };
    },
    unlockTiffScans: state => ({ ...state, loading: true }),
    unlockTiffScansError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error
    }),
    unlockTiffScansSuccess: (state, action) => {
      const { pickUps } = action.payload;
      const byId = keyBy(pickUps, 'id');

      return {
        ...state,
        loading: false,
        error: null,
        pickUps: state.pickUps.map(pu => {
          if (byId[pu.id]) return byId[pu.id];
          return pu;
        })
      };
    },
    removeAddress: state => ({ ...state, loading: true }),
    removeAddressError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error
    }),
    removeAddressSuccess: (state, action) => ({
      ...state,
      loading: false,
      error: null,
      user: action.payload.user
    }),
    getDigitalProducts: state => ({ ...state, loading: true }),
    getDigitalProductsError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error
    }),
    getDigitalProductsSuccess: (state, action) => {
      const { products } = action.payload;

      return {
        ...state,
        loading: false,
        error: null,
        products
      };
    }
  }
});

export const { reducer, actions } = slice;

export default reducer;
