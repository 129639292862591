import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 'bold'
  },
  login: {
    textTransform: 'lowercase'
  },
  logo: {
    color: theme.palette.primary.main,
    fontWeight: '200'
  },
  root: {
    alignItems: 'center',
    background: 'transparent',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 50px',
    position: 'fixed',
    top: '0',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      padding: '15px 20px'
    }
  }
}));

export default function LandingHeader(props) {
  const classes = useStyles();

  return (
    <header className={classes.root}>
      <Typography variant="body1" className={classes.logo}>
        dark<span className={classes.bold}>lab</span>
      </Typography>
      <Button
        color="primary"
        variant="text"
        onClick={props.onLogin}
        className={classes.login}
      >
        Login
      </Button>
    </header>
  );
}
