import { some, size, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// @material-ui/core components
import Button from '@material-ui/core/Button';

import validateEmail from '../../lib/utils/Form/validate-email';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  actions: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: '15px'
  },
  form: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  inputRoot: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minHeight: '70px'
  },
  inputs: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column'
  },
  row: {
    alignItems: 'center',
    display: 'flex'
  },
  signup: {
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
      fontWeight: 'bold'
    }
  }
}));

/**
 * Login Component
 */
export default function Login(props) {
  const [form, setForm] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const classes = useStyles();

  useEffect(() => {
    setFormErrors(errors => ({
      ...errors,
      password: !isEmpty(form.password) && size(form.password) < 6,
      email: !isEmpty(form.email) && !validateEmail(form.email)
    }));
  }, [form]);

  function submitForm(e) {
    if (e) e.preventDefault();
    props.login(form);
  }

  function disableForm() {
    const fields = ['email', 'password'];

    if (some(formErrors, isError => isError)) return true;
    if (some(fields.map(f => isEmpty(form[f])))) return true;
  }

  function handleChange(e, field) {
    if (e) e.persist();

    setForm(inputs => ({ ...inputs, [e.target.name]: e.target.value }));

    props.onChange(form, !disableForm());
  }

  return (
    <form className={classes.form} onSubmit={submitForm}>
      <div className={classes.inputs}>
        <div className={classes.row}>
          <TextField
            classes={{ root: classes.inputRoot }}
            name="email"
            label="Email"
            onChange={e => handleChange(e, 'email')}
            onPaste={e => handleChange(e, 'email')}
            fullWidth
            required
            error={formErrors.email}
            helperText={formErrors.email && 'Please fill out a valid email'}
          />
        </div>
        <div className={classes.row}>
          <TextField
            classes={{ root: classes.inputRoot }}
            name="password"
            label="Password"
            type="password"
            onChange={e => handleChange(e, 'password')}
            onPaste={e => handleChange(e, 'password')}
            fullWidth
            required
            error={formErrors.password}
            helperText={
              formErrors.password && 'Please fill out a valid password'
            }
          />
        </div>
      </div>
      <div className={classes.actions}>
        <div>
          Don't have an account?{' '}
          <span className={classes.signup} onClick={props.showSignUp}>
            Sign Up
          </span>
        </div>

        <Button
          disabled={disableForm()}
          type="submit"
          color="primary"
          variant="contained"
        >
          Login
        </Button>
      </div>
    </form>
  );
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
  showSignUp: PropTypes.func.isRequired
};
