import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  address: {
    lineHeight: '1.25',
    margin: '5px 0px'
  },
  card: {
    alignItems: 'center',
    color: theme.palette.primary.light,
    background: props =>
      props.pickedUpAt ? theme.palette.success.main : theme.palette.white.main,
    display: 'flex',
    marginBottom: '3px',
    padding: '5px'
  },
  details: {
    fontSize: '10px',
    padding: '0px 10px'
  },
  name: {
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: '1.5'
  },
  phone: {
    lineHeight: '1.5'
  },
  pickedUpAt: {
    fontWeight: 'bold'
  }
}));
