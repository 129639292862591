import React from 'react';
import { StripeProvider, Elements } from 'react-stripe-elements';
import StripeForm from './StripeForm';

const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;

export default function StripeCheckout(props) {
  return (
    <StripeProvider apiKey={STRIPE_KEY}>
      <Elements>
        <StripeForm {...props} />
      </Elements>
    </StripeProvider>
  );
}
