import cx from 'classnames';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DangerButton from '../../../components/Button/DangerButton';

const useStyles = makeStyles(theme => ({
  card: {
    padding: '10px'
  },
  cardAction: {
    margin: '0px 5px 0px 0px'
  },
  cardActions: {
    padding: '10px'
  },
  cardDetails: {
    padding: '10px',
    minHeight: '120px'
  },
  confirmationModalActions: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '50px',
    '& button': {
      margin: '0px 5px 0px 0px'
    }
  },
  confirmationModalContent: {
    display: 'flex',
    flexDirection: 'column',
    margin: '10px 0px'
  },
  primary: {
    color: theme.palette.success.dark,
    fontWeight: 'bold'
  }
}));

export default function UserAddress(props) {
  const classes = useStyles();
  const { address } = props;
  const { id, address1, address2, city, state, zip, primary, userId } = address;
  const [openConfirmation, setOpenConfirmation] = useState(false);

  return (
    <Card className={classes.card}>
      <div className={classes.cardDetails}>
        <div>{`${address1}`}</div>

        {address2 && <div>{`${address2}`}</div>}

        <div>{`${city} ${state}, ${zip}`}</div>

        {primary ? (
          <div className={classes.primary}>Primary Address</div>
        ) : (
          <br />
        )}
      </div>
      <div className={classes.cardActions}>
        <Button
          size="small"
          className={classes.cardAction}
          disabled={props.disabled}
          variant="contained"
          color="primary"
          onClick={props.onEdit}
        >
          Edit
        </Button>
        <DangerButton
          size="small"
          variant="contained"
          className={cx(classes.cardAction)}
          disabled={props.disabled}
          onClick={() => setOpenConfirmation(true)}
        >
          Remove
        </DangerButton>
      </div>

      <Dialog
        open={openConfirmation}
        onClose={() => setOpenConfirmation(false)}
      >
        <DialogContent>
          <div className={classes.confirmationModalContent}>
            Are you sure you want to remove this address ?
            <div className={classes.confirmationModalActions}>
              <Button
                size="small"
                color="primary"
                variant="contained"
                onClick={() => setOpenConfirmation(false)}
              >
                Cancel
              </Button>
              <DangerButton
                size="small"
                variant="contained"
                onClick={() => props.removeAddress(userId, id)}
              >
                Confirm
              </DangerButton>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </Card>
  );
}

UserAddress.propTypes = {
  disabled: PropTypes.bool.isRequired,
  removeAddress: PropTypes.func.isRequired
};
