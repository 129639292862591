import { createSelector } from 'reselect';

const selector = state => state.pickUps;

export const loadingPickUpsSelector = createSelector(
  selector,
  pickUps => pickUps.loading
);

export const pickUpsErrorSelector = createSelector(
  selector,
  pickUps => pickUps.error
);

export const pickUpsSelector = createSelector(
  selector,
  pickUps => pickUps.pickUps
);

export const pickUpSelector = createSelector(
  selector,
  pickUps => pickUps.pickUp
);

export const availabilitiesSelector = createSelector(
  selector,
  pickUps => pickUps.availabilities
);

export const productsSelector = createSelector(
  selector,
  pickUps => pickUps.products
);
