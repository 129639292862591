import { chain, get } from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import customerSideBarStyles from './styles/customerSideBarStyles';
import AdminContext from '../../contexts/AdminContext';
import { makeUserInitials } from '../../lib/utils/make-user-initials';
import resize from '../../lib/utils/resize-image';
import dataURItoBlob from '../../lib/utils/data-url-to-blob';
import useWindowSize from '../../lib/utils/Hook/use-window-size';
import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(customerSideBarStyles);

const getUserId = (location, isAdmin) => {
  return chain(location)
    .get('pathname')
    .split('/')
    .at(isAdmin ? 3 : 2)
    .value();
};

export default function CustomerSideBar(props) {
  const { user, logout } = props;
  const imgUrl = get(user, 'profileImageUrl');
  const context = useContext(AdminContext);
  const classes = useStyles({ isAdmin: context.isAdmin });
  const [userId] = useState(getUserId(props.location, context.isAdmin));
  const [avatar, setAvatar] = useState(imgUrl);
  const fileInput = useRef(null);
  const screenSize = useWindowSize();

  useEffect(() => {
    props.getUser(userId);
  }, [userId]);
  useEffect(() => {
    if (!user) return;

    setAvatar(user.profileImageUrl);
  }, [imgUrl]);

  if (!user) return null;

  function onUpload(e) {
    if (e) e.preventDefault();

    const reader = new FileReader();
    const file = e.target.files[0];

    if (!file) return;

    reader.onload = ev =>
      resize(ev, dataURI => {
        const params = { fileType: file.type, fileName: file.name };
        const resizedFile = dataURItoBlob(dataURI);
        props
          .uploadProfilePic(user.id, params, resizedFile)
          .then(({ user }) => setAvatar(user.profileImageUrl));
      });

    reader.readAsDataURL(file);
  }

  return (
    <div className={classes.container}>
      <Drawer
        classes={{ root: classes.drawer, paper: classes.drawerPaper }}
        variant="persistent"
        anchor={
          screenSize.isMidScreen || screenSize.isSmallScreen ? 'top' : 'left'
        }
        open
      >
        <div className={classes.profileContainer}>
          <label
            htmlFor="avatar-upload-input"
            onClick={() => fileInput.current.click()}
          >
            <IconButton>
              <Avatar
                alt="Avatar"
                src={avatar}
                style={{
                  margin: '0px',
                  width: '50px',
                  height: '50px'
                }}
              >
                {makeUserInitials(user)}
              </Avatar>
            </IconButton>
            <input
              ref={fileInput}
              accept="image/jpeg, image/png"
              className={classes.avatarInput}
              id="avatar-upload-input"
              type="file"
              onChange={onUpload}
            />
          </label>

          <p className={classes.hideMobile}>{user.firstName}</p>
          <p className={classes.hideMobile}>{user.lastName}</p>
          <Button variant="text" onClick={logout} size="small">
            Logout
          </Button>
        </div>

        <Divider />

        <List className={classes.hideMobile}>
          {[
            ['Home', `/users/${user.id}`],
            ['Settings', `/users/${user.id}/settings`]
          ].map(([nav, to], index) => (
            <ListItem button key={index}>
              <ListItem className={classes.linkItem}>
                <Link to={to} className={classes.link}>
                  {nav}
                </Link>
              </ListItem>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <div className={classes.childContainer}>{props.children}</div>
    </div>
  );
}

CustomerSideBar.propTypes = {
  currentUser: PropTypes.shape({}),
  logout: PropTypes.func.isRequired
};
