/*eslint-disable*/ import React from 'react';

// Sections for this page
import {
  createSubscriptionErrorSelector,
  creatingSubscriptionSelector,
  subscriptionCreatedSelector
} from './selectors';
import { actions } from './slice';
import { connect } from 'react-redux';
import Onboarding from '../../components/Onboarding/Onboarding';
import promisifyActions from '../../lib/utils/promisify-actions';

const OnboardingContainer = props => {
  return <Onboarding {...props} />;
};

/**
 * The app state to be provided to the app component
 *
 * @param {object} state global state
 */
const mapStateToProps = state => ({
  creatingSubscription: creatingSubscriptionSelector(state),
  createSubscriptionError: createSubscriptionErrorSelector(state),
  subscriptionCreated: subscriptionCreatedSelector(state)
});

/**
 * Actions to be dispatched
 *
 * @param {function} dispatch function to dispatch app appActions
 */
const mapDispatchToProps = dispatch => ({
  createSubscription: (userId, token) =>
    dispatch(actions.createSubscription({ userId, token }))
});

/**
 * Connects app state to the App component
 *
 * @param {object} mapStateToProps app data object to become props for the App component
 * @param {object} mapDispatchToProps app appActions to be dispatched to reducer
 */
export default connect(
  mapStateToProps,
  promisifyActions(mapDispatchToProps)
)(OnboardingContainer);
