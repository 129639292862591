import React from 'react';
import { connect } from 'react-redux';
import {
  loadingMessengersSelector,
  messengersErrorSelector,
  messengerSelector,
  messengerRoutesSelector
} from './selectors';
import { actions } from './slice';
import { productsSelector } from '../Products/selectors';
import { actions as productActions } from '../Products/slice';
import promisifyActions from '../../lib/utils/promisify-actions';
import PrintableRoutesPage from '../../views/PrintableRoutesPage/PrintableRoutesPage';

const Messenger = props => {
  return <PrintableRoutesPage {...props} />;
};

/**
 * The admin state to be provided to the admin component
 *
 * @param {object} state global state
 */
const mapStateToProps = state => ({
  loading: loadingMessengersSelector(state),
  error: messengersErrorSelector(state),
  messenger: messengerSelector(state),
  products: productsSelector(state),
  routes: messengerRoutesSelector(state)
});

/**
 * Actions to be dispatched
 *
 * @param {function} dispatch function to dispatch admin adminActions
 */
const mapDispatchToProps = dispatch => ({
  getMessenger: messengerId => dispatch(actions.getMessenger({ messengerId })),
  getPickUpProducts: () => dispatch(productActions.getPickUpProducts()),
  getRoutes: (messengerId, from, to) =>
    dispatch(actions.getRoutes({ messengerId, from, to }))
});

/**
 * Connects admin state to the App component
 *
 * @param {object} mapStateToProps admin data object to become props for the App component
 * @param {object} mapDispatchToProps admin adminActions to be dispatched to reducer
 */
export default connect(
  mapStateToProps,
  promisifyActions(mapDispatchToProps)
)(Messenger);
