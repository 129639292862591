import { chain, filter, findIndex, omit } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import PickUpFilmRolls from './Sections/RollsToReceive';

import splice from '../../lib/utils/immutable-splice';

import styles from '../../assets/jss/material-kit-pro-react/views/componentsSections/contentAreas';
import { makeStyles } from '@material-ui/core/styles';
import AdminContext from '../../contexts/AdminContext';

const useStyles = makeStyles(theme => ({
  ...styles,
  container: {
    ...styles.container,
    display: 'flex',
    flexDirection: 'column',
    height: props => (props.isAdmin ? 'calc(100vh - 100px)' : '100vh'),
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '50px'
    }
  },
  row: {
    display: 'flex',
    margin: '20px 0px',
    minHeight: '250px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginRight: '0px',
      minHeight: '0px'
    }
  },
  scrollable: {
    overflow: 'auto'
  },
  verticalDividerMargin: {
    margin: '0px 5px'
  }
}));

const getPickUpId = location => {
  const locationArr = chain(location)
    .get('pathname')
    .split('/')
    .value();
  const index = findIndex(locationArr, v => v === 'pick-ups');
  return locationArr[index + 1];
};

export default function ReceivePickUpPage(props) {
  const context = useContext(AdminContext);
  const classes = useStyles({ isAdmin: context.isAdmin });
  const [pickUp, setPickUp] = useState(null);
  const [error, setError] = useState(null);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    props.getPickUpProducts();
    props
      .getPickUp(props.user.id, getPickUpId(props.location))
      .then(({ pickUp }) => {
        setPickUp(pickUp);
      })
      .catch(err => setError(err));
  }, [props.new]);

  if (!pickUp) return <div className={classes.container} />;

  const filmRollProducts = filter(props.products, 'filmRoll');

  function updateUserRoll(index, roll) {
    setPickUp(pu => ({ ...pu, rolls: splice(pu.rolls, index, roll) }));
  }

  function formatPickUpForUpdate(pickUp) {
    return {
      newLines: chain(pickUp.order.lines)
        .filter(l => l.productId && !l.id)
        .map(l => ({ ...l, orderId: pickUp.order.id }))
        .value(),
      lines: filter(pickUp.order.lines, 'id'),
      rolls: filter(pickUp.rolls, 'id').map(r => ({ ...r, scans: [] })),
      newRolls: chain(pickUp.rolls)
        .filter(r => r.filmRollId && !r.id)
        .map(r => ({ ...r, pickUpId: pickUp.id }))
        .value(),
      order: omit(pickUp.order, 'lines'),
      pickUp: omit(pickUp, [
        'lines',
        'rolls',
        'order',
        'messenger',
        'customer',
        'address',
        'window'
      ])
    };
  }

  function submit() {
    const formatted = formatPickUpForUpdate(pickUp);

    setSaving(true);

    props
      .updatePickUp(formatted)
      .then(() => props.history.goBack())
      .catch(err => setError(err))
      .finally(() => setSaving(false));
  }

  return (
    <div className={classes.container}>
      <div className={classes.scrollable}>
        <PickUpFilmRolls
          save={submit}
          user={props.user}
          rolls={pickUp.rolls}
          updateRoll={updateUserRoll}
          products={filmRollProducts}
        />
      </div>
    </div>
  );
}

ReceivePickUpPage.propTypes = {
  getPickUp: PropTypes.func.isRequired,
  getPickUpProducts: PropTypes.func.isRequired,
  pickUp: PropTypes.shape({}),
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updatePickUp: PropTypes.func.isRequired,
  user: PropTypes.shape({})
};
