/**
 * AddressForm saga functions
 */
import { call, put, takeLatest } from 'redux-saga/effects';
import { handleError } from '../../lib/utils/saga-utils';
import { actions } from './slice';
import UserAPI from '../../lib/Api/User';
import ZipCodesAPI from '../../lib/Api/ZipCode';

export function* createAddress({
  payload: { userId, address, resolve, reject }
}) {
  try {
    const response = yield call(UserAPI.createAddress, userId, address);
    yield put(actions.createAddressSuccess({ address: response.address }));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.createAddressError, err);
    yield call(reject, err);
  }
}

export function* getAddress({ payload: { userId, addressId } }) {
  try {
    const response = yield call(UserAPI.getAddress, userId, addressId);
    const { address } = response;
    yield put(actions.getAddressSuccess({ address }));
  } catch (err) {
    yield handleError(actions.getAddressError, err);
  }
}

export function* updateAddress({ payload: { userId, address } }) {
  try {
    const response = yield call(UserAPI.updateAddress, userId, address);
    yield put(actions.updateAddressSuccess({ address: response.address }));
  } catch (err) {
    yield handleError(actions.updateAddressError, err);
  }
}

export function* removeAddress({ payload: { userId, addressId } }) {
  try {
    const response = yield call(UserAPI.removeAddress, userId, addressId);
    const { address } = response;
    yield put(actions.removeAddressSuccess({ address }));
  } catch (err) {
    yield handleError(actions.removeAddressError, err);
  }
}

export function* getZipCodes() {
  try {
    const response = yield call(ZipCodesAPI.getAll, true);
    const { zipCodes } = response;
    yield put(actions.getZipCodesSuccess({ zipCodes }));
  } catch (err) {
    yield handleError(actions.getZipCodesError, err);
  }
}

function* addressFormSaga() {
  yield takeLatest(actions.createAddress.type, createAddress);
  yield takeLatest(actions.updateAddress.type, updateAddress);
  yield takeLatest(actions.getZipCodes.type, getZipCodes);
}

export default addressFormSaga;
