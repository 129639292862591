import { omit } from 'lodash';
import cx from 'classnames';
import React from 'react';
import Button from '@material-ui/core/Button';

import buttonStyles from './styles/buttonStyles';

export default function SuccessButton(props) {
  const classes = buttonStyles();

  return (
    <Button
      className={cx(classes.success, props.className)}
      {...omit(props, 'className')}
    />
  );
}
