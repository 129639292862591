import { get, omit, size, range } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

import { makeStyles } from '@material-ui/core/styles';

import Table from '../Table/Table';
import TableHeader from '../TableHeader/TableHeader';
import contentAreasStyles from '../../assets/jss/material-kit-pro-react/views/componentsSections/contentAreas';
import Pagination from '../Pagination/Pagination';

const useStyles = makeStyles(theme => ({
  ...contentAreasStyles,
  active: {
    fontWeight: 'bold'
  },
  header: {
    cursor: 'pointer'
  },
  paper: {
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '475px',
    padding: '20px'
  }
}));

export default function PickUpsTable(props) {
  const classes = useStyles();
  const [params, setParams] = useState(
    props.params || {
      limit: 5,
      offset: 0,
      sortBy: 'date',
      desc: false
    }
  );

  useEffect(() => {
    props.onParamsChange(params, props.userId);
  }, [params]);

  const adminCols = [
    { sortBy: 'id', text: 'Id' },
    { sortBy: 'customerId', text: 'Customer' },
    { sortBy: 'messengerId', text: 'Messenger' },
    { sortBy: 'date', text: 'Pick Up Date' },
    { sortBy: 'pickedUpAt', text: 'Picked Up At' }
  ];
  const customerCols = [
    { sortBy: 'id', text: 'Id' },
    { sortBy: 'date', text: 'Pick Up Date' },
    { sortBy: 'pickedUpAt', text: 'Picked Up At' }
  ];

  const headers = props.isAdmin ? adminCols : customerCols;

  function makeAdminData(p) {
    return [
      <Link to={`/admin/users/${p.customerId}/pick-ups/${p.id}`}>{p.id}</Link>,
      <Link to={`/admin/users/${p.customer.id}`}>
        {`${get(p, 'customer.firstName')} ${get(p, 'customer.lastName')}`}
      </Link>,
      <Link to={`/admin/messengers/${p.messenger.id}`}>
        {`${get(p, 'messenger.firstName')} ${get(p, 'messenger.lastName')}`}
      </Link>
    ];
  }

  function makeCustomerData(p) {
    return [p.id];
  }

  return (
    <Paper className={classes.paper}>
      <Table
        tableHead={headers
          .map(header => (
            <TableHeader
              key={header.sortBy}
              {...header}
              params={params}
              setParams={setParams}
            />
          ))
          .concat(['# Rolls', '', ''])}
        tableData={props.pickUps.map(p => {
          return {
            key: p.id,
            data: (props.isAdmin
              ? makeAdminData(p)
              : makeCustomerData(p)
            ).concat([
              moment(p.date).format('LLLL'),
              p.pickedUpAt
                ? moment(p.pickedUpAt).format('MM/DD/YY hh:mm A')
                : '',
              size(p.rolls),
              <Button
                color="primary"
                variant="contained"
                size="small"
                onClick={() => {
                  props.history.push(
                    `/admin/users/${p.customerId}/pick-ups/${p.id}`
                  );
                }}
              >
                Edit
              </Button>,
              <Button
                color="primary"
                variant="contained"
                size="small"
                onClick={() => {
                  props.history.push(
                    `/admin/users/${p.customerId}/pick-ups/${p.id}/receive`
                  );
                }}
              >
                Receive
              </Button>
            ])
          };
        })}
        customCellClasses={[
          classes.textCenter,
          classes.textRight,
          classes.textRight
        ]}
        customClassesForCells={[0, 4, 5]}
        customHeadCellClasses={[
          classes.textCenter,
          classes.textRight,
          classes.textRight
        ]}
        customHeadClassesForCells={[0, 4, 5]}
        striped
      />

      <Pagination
        pages={range(Math.ceil(props.pickUpsCount / params.limit)).map(n => {
          return {
            active: n === params.offset / params.limit,
            disabled: false,
            text: n + 1,
            onClick: () => setParams(p => ({ ...p, offset: n * params.limit }))
          };
        })}
      />
    </Paper>
  );
}

PickUpsTable.propTypes = {
  params: PropTypes.shape({
    offset: PropTypes.number.isRequired,
    limit: PropTypes.number.isRequired,
    sortBy: PropTypes.string.isRequired,
    desc: PropTypes.bool.isRequired
  }),
  onParamsChange: PropTypes.func.isRequired
};
