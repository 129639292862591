import { keyBy, some, mapValues } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import DownloadIcon from '@material-ui/icons/GetApp';

import { makeStyles } from '@material-ui/core/styles';
import { Checkbox } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  checkbox: {
    padding: '0px'
  },
  rollAction: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    padding: '5px 0px'
  },
  rollActions: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%'
  },
  expand: {
    transform: props => (props.expanded ? 'rotate(180deg)' : 'rotate(0deg)'),
    transition: 'all 1s'
  }
}));

export default function RollSummaryActions(props) {
  const classes = useStyles(props);
  const scansById = keyBy(props.roll.scans, 'id');
  const checked = some(props.selected, (v, scanId) => scansById[scanId] && v);

  return (
    <div className={classes.rollActions}>
      <IconButton
        color="primary"
        className={classes.rollAction}
        onClick={e => {
          e.stopPropagation();
          props.download(scansById);
        }}
        disabled={props.downloading}
      >
        <DownloadIcon />
      </IconButton>
      <Checkbox
        classes={{ root: classes.checkbox }}
        color="secondary"
        checked={checked}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          props.setSelected(state => ({
            ...state,
            ...mapValues(scansById, () => !checked)
          }));
        }}
        className={classes.overlayCheckbox}
      />
    </div>
  );
}

RollSummaryActions.propTypes = {
  download: PropTypes.func.isRequired,
  downloading: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
  isSmallScreen: PropTypes.bool.isRequired,
  roll: PropTypes.shape({}).isRequired,
  setExpand: PropTypes.func.isRequired,
  selected: PropTypes.shape({}).isRequired,
  setSelected: PropTypes.func.isRequired
};
