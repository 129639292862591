import cx from 'classnames';
import Fuse from 'fuse.js';
import { get, isInteger, size } from 'lodash';
import numeral from 'numeral';
import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  alignCenter: {
    textAlign: 'center'
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '5px 0px'
  },
  duplicate: {
    cursor: 'pointer',
    fill: theme.palette.primary.light,
    '&:hover': {
      fill: theme.palette.primary.dark
    }
  },
  editable: {
    borderBottom: `1px dashed ${theme.palette.primary.main}`
  },
  flex1: {
    flex: '1'
  },
  flex2: {
    flex: '2'
  },
  mobileHide: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  name: {
    cursor: 'text',
    flex: '5'
  },
  quantity: {
    marginRight: '10px'
  },
  remove: {
    cursor: 'pointer',
    fill: theme.palette.primary.light,
    '&:hover': {
      fill: theme.palette.primary.dark
    }
  },
  rollRequest: {
    fontSize: '10px'
  }
}));

export default function PickUpRollForPurchase(props) {
  const classes = useStyles();
  const [item, setItem] = useState(props.filmRolls[props.roll.productId]);
  const [quantityFocused, setQuantityFocused] = useState(false);
  const [dropdownFocused, setDropdownFocused] = useState(false);

  function onKeyPress(e) {
    if (!quantityFocused) return;
    const q = get(props.roll, 'quantity', 0);
    if (e.keyCode === 38) {
      props.updateRoll('quantity', +q + 1);
    } else if (e.keyCode === 40) {
      props.updateRoll('quantity', +q - 1 < 1 ? 1 : +q - 1);
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', onKeyPress);
    return () => {
      window.removeEventListener('keydown', onKeyPress);
    };
  }, [quantityFocused, props.roll]);

  function makeRequestLink() {
    const email = 'josh@darklabsubscription.com';
    const subject = `Purchase Film Roll Request`;
    const body = `Hey darklab, 
      %0D%0A
      %0D%0A
      I'm looking for the following roll for purchase:
      %0D%0A
      %0D%0A
      film roll: _______
      %0D%0A
      iso: ______
      %0D%0A
      format: ______ 
      %0D%0A
      %0D%0A
      Would you be able to get it in stock?`;

    return `mailto:${email}?subject=${subject}&body=${body}`;
  }

  return (
    <div className={classes.container}>
      <div className={classes.name}>
        {item ? (
          <span
            onClick={() => {
              setItem(null);
              props.updateRoll('productId', null);
            }}
            className={classes.editable}
          >
            {get(props.filmRolls[props.roll.productId], 'filmRoll.name')}
          </span>
        ) : (
          <div>
            <ReactSelect
              styles={{
                container: base => ({
                  ...base,
                  borderRadius: '2px',
                  flex: 1,
                  fontSize: '12px',
                  marginRight: '10px'
                }),
                control: base => ({
                  ...base,
                  minHeight: '0px',
                  padding: '0px'
                }),
                dropdownIndicator: base => ({
                  ...base,
                  padding: '0px 4px'
                }),
                input: base => ({
                  ...base,
                  padding: '0px'
                }),
                option: base => ({
                  ...base,
                  padding: '0px 4px'
                }),
                menuPortal: base => ({ ...base, zIndex: 9999 }),
                valueContainer: base => ({
                  ...base,
                  padding: '0px 8px'
                })
              }}
              options={props.options}
              onFocus={() => setDropdownFocused(true)}
              filterOption={(opt, search) => {
                const fuse = new Fuse([opt], {
                  keys: ['label'],
                  threshold: 0.4,
                  distance: 100
                });
                return search ? fuse.search(search).length > 0 : true;
              }}
              onChange={r => {
                setItem(props.filmRolls[r.value]);
                props.updateRoll('productId', r.value);
              }}
              placeholder="Select A Film Roll"
            />
            {dropdownFocused && !item && (
              <a href={makeRequestLink()} className={classes.rollRequest}>
                Not seeing your roll? Send us a request
              </a>
            )}
          </div>
        )}
      </div>

      <div className={cx(classes.flex1, classes.mobileHide)}>
        {get(item, 'filmRoll.iso')}
      </div>
      <div className={cx(classes.flex1, classes.mobileHide)}>
        {get(item, 'filmRoll.format')}
      </div>
      <div className={cx(classes.flex1, classes.mobileHide)}>
        {item && numeral(get(item, 'price')).format('$0.00')}
      </div>
      <div className={classes.flex1}>
        {item && (
          <Input
            className={classes.quantity}
            type="text"
            value={get(props.roll, 'quantity')}
            onFocus={() => setQuantityFocused(true)}
            onChange={e => {
              if (e) e.preventDefault();
              if (size(e.target.value) && !isInteger(+e.target.value)) return;

              props.updateRoll('quantity', +e.target.value);
            }}
            onBlur={() => {
              const q = get(props.roll, 'quantity');
              if (!q || !isInteger(q)) {
                props.updateRoll('quantity', 1);
              }
              setQuantityFocused(false);
            }}
          />
        )}
      </div>
      <div className={classes.flex1}>
        <RemoveCircleOutline
          onClick={() => {
            setItem(null);
            props.remove();
          }}
          className={classes.remove}
        />
      </div>
    </div>
  );
}

PickUpRollForPurchase.propTypes = {
  filmRolls: PropTypes.shape({}).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  remove: PropTypes.func.isRequired,
  roll: PropTypes.shape({}).isRequired,
  updateRoll: PropTypes.func.isRequired
};
