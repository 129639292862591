import { get } from 'lodash';
import numeral from 'numeral';
import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';

const useStyles = makeStyles(theme => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '5px 0px'
  },
  desc: {
    fontSize: '11px'
  },
  editable: {
    borderBottom: `1px dashed ${theme.palette.primary.main}`
  },
  flex1: {
    flex: '1'
  },
  flex2Mobile: {
    textAlign: props => (props.isSmallScreen ? 'center' : 'default'),
    [theme.breakpoints.down('sm')]: {
      flex: '2'
    }
  },
  name: {
    cursor: 'text',
    flex: '5'
  },
  quantity: {
    marginRight: '10px'
  },
  remove: {
    cursor: 'pointer',
    fill: theme.palette.primary.light,
    '&:hover': {
      fill: theme.palette.primary.dark
    }
  }
}));

export default function OrderLine(props) {
  const classes = useStyles(props);
  const product = props.products[props.line.productId];
  const { quantity } = props.line;
  const { name, price, description } = product || {};

  return (
    <div className={classes.container}>
      <div className={classes.name}>
        <div>{name}</div>
        {description && <div className={classes.desc}>{description}</div>}
      </div>

      <div className={cx(classes.flex1, classes.flex2Mobile)}>
        {numeral(price).format('$0.00')}
      </div>
      <div className={cx(classes.flex1, classes.flex2Mobile)}>{quantity}</div>
      <div className={cx(classes.flex1, classes.flex2Mobile)}>
        {numeral(quantity * price).format('$0.00')}
      </div>
    </div>
  );
}

OrderLine.propTypes = {
  products: PropTypes.shape({}).isRequired,
  line: PropTypes.shape({}).isRequired
};
