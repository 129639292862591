import PropTypes from 'prop-types';
import React, { useState } from 'react';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

export default function DateSwitcherBar(props) {
  const classes = useStyles();
  const [day, setDay] = useState(props.day || moment());

  function updateRange(days) {
    const newDay = day.clone().add(days, 'days');
    setDay(newDay);
    props.onChange(newDay);
  }

  return (
    <div className={classes.container}>
      <div>
        <IconButton onClick={() => updateRange(-1)} aria-label="One Day Back">
          <KeyboardArrowLeft />
        </IconButton>
      </div>
      <div>{moment(day).format('LL')}</div>
      <div>
        <IconButton onClick={() => updateRange(1)} aria-label="One Day Forward">
          <KeyboardArrowRight />
        </IconButton>
      </div>
    </div>
  );
}

DateSwitcherBar.propTypes = {
  onChange: PropTypes.func.isRequired
};
