/**
 * Onboarding saga functions
 */
import { call, put, takeLatest } from 'redux-saga/effects';
import { handleError } from '../../lib/utils/saga-utils';
import { actions } from './slice';
import UserAPI from '../../lib/Api/User';
import AuthAPI from '../../lib/Api/Auth';

export function* createSubscription({ payload: { userId, token, resolve } }) {
  try {
    const response = yield call(UserAPI.createSubscription, userId, token);
    yield put(actions.createSubscriptionSuccess());
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.createSubscriptionError, err);
  }
}

function* onboardingSaga() {
  yield takeLatest(actions.createSubscription.type, createSubscription);
}

export default onboardingSaga;
