import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { Router } from 'react-router';

import 'assets/scss/material-kit-pro-react.scss?v=1.8.0';
import { ThemeProvider } from '@material-ui/styles';
import theme from './Theme/index';

import saga from './saga';
import reducer from './reducer';
import App from 'containers/App/App';

const hist = createBrowserHistory();
// create the saga middleware
const sagaMiddleware = createSagaMiddleware();
// mount it on the store
const store = createStore(reducer, applyMiddleware(sagaMiddleware));
// run sagas
sagaMiddleware.run(saga);

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <Router history={hist}>
        <App />
      </Router>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root')
);
