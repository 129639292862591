import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import styles from '../../assets/jss/material-kit-pro-react/components/inputErrorMessageStyle';

const useStyles = makeStyles(styles);

export function InputErrorMessage({ message }) {
  const classes = useStyles();

  return <span className={classes.root}>{message}</span>;
}
