import { chain, get, filter, find, findIndex, omit, keyBy } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';

import PickUpAddressSelector from './Sections/PickUpAddressSelector';
import PickUpWindowSelector from './Sections/PickUpWindowSelector';
import PickUpFilmRolls from './Sections/PickUpFilmRolls';
import PickUpRollsForPurchase from './Sections/PickUpRollsForPurchase';
import PickUpActionBar from './Sections/PickUpActionBar';

import splice from '../../lib/utils/immutable-splice';

import styles from '../../assets/jss/material-kit-pro-react/views/componentsSections/contentAreas';
import { makeStyles } from '@material-ui/core/styles';
import useWindowSize from '../../lib/utils/Hook/use-window-size';
import {
  formatNewPickUp,
  formatPickUpForUpdate
} from '../../lib/utils/format-pickup';
import { makeAddOnLines } from '../../lib/utils/order-line-utils';
import AdminContext from '../../contexts/AdminContext';
import AddonSku from '../../lib/Constants/AddonSku';

const useStyles = makeStyles(theme => ({
  ...styles,
  container: {
    ...styles.container,
    display: 'flex',
    flexDirection: 'column',
    height: props => (props.isAdmin ? 'calc(100vh - 100px)' : '100vh'),
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '50px'
    }
  },
  row: {
    display: 'flex',
    margin: '20px 0px',
    minHeight: '250px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginRight: '0px',
      minHeight: '0px'
    }
  },
  scrollable: {
    overflow: 'auto'
  },
  verticalDividerMargin: {
    margin: '0px 5px'
  }
}));

const getPickUpId = location => {
  const locationArr = chain(location)
    .get('pathname')
    .split('/')
    .value();
  const index = findIndex(locationArr, v => v === 'pick-ups');
  return locationArr[index + 1];
};

const makeNewPickUp = (user, emptyRoll, emptyLine) => ({
  date: null,
  messengerId: null,
  messengerHoursId: null,
  pickedUpAt: null,
  orderId: null,
  pickUpAddressId: get(user, 'addresses[0].id'),
  customerId: user.id,
  rolls: [emptyRoll],
  order: {
    customerId: user.id,
    notes: '',
    total: 0,
    stripeId: null,
    lines: [emptyLine]
  }
});

export default function PickUpPage(props) {
  const context = useContext(AdminContext);
  const classes = useStyles({ isAdmin: context.isAdmin });
  const emptyRoll = {
    customerId: props.user.id,
    pickUpId: null,
    filmRollId: null,
    returnToCustomer: false,
    pushPullId: null,
    crossProcessId: null
  };
  const emptyLine = {
    tempKey: Math.random() * 100000,
    customerId: props.user.id,
    orderId: null,
    productId: null,
    amount: 0,
    notes: '',
    stripeId: null,
    quantity: 1
  };
  const [pickUp, setPickUp] = useState(null);
  const [error, setError] = useState(null);
  const [saving, setSaving] = useState(false);

  const screenSize = useWindowSize();

  useEffect(() => {
    props.getPickUpProducts();

    if (props.new) {
      setPickUp(makeNewPickUp(props.user, emptyRoll, emptyLine));
    } else {
      props
        .getPickUp(props.user.id, getPickUpId(props.location))
        .then(({ pickUp }) => {
          setPickUp(pickUp);
        })
        .catch(err => setError(err));
    }
  }, [props.new]);

  if (!pickUp) return <div className={classes.container} />;

  // Address Selector
  const selectedAddress = find(
    props.user.addresses,
    address => address.id === pickUp.pickUpAddressId
  );
  const filmRollProducts = filter(props.products, 'filmRoll');
  const filmRollProductsById = keyBy(filmRollProducts, 'id');
  const productsBySku = keyBy(props.products, 'sku');
  const filmRollsById = chain(props.products)
    .filter('filmRoll')
    .keyBy('filmRoll.id')
    .value();

  const chargePickUp =
    chain(pickUp)
      .get('credit')
      .isEmpty()
      .value() &&
    chain(props.user)
      .get('pickUpCredits')
      .isEmpty()
      .value();

  function formatPickUpStateUpdate(pickUp, rolls, lines) {
    return {
      ...pickUp,
      rolls,
      order: {
        ...pickUp.order,
        lines
      }
    };
  }

  function updateUserRoll(index, roll) {
    setPickUp(pu => {
      const updatedRolls = splice(pu.rolls, index, roll);

      const lines = makeAddOnLines(
        pu.order.lines,
        updatedRolls,
        productsBySku,
        filmRollsById,
        props.user
      );

      return formatPickUpStateUpdate(pu, updatedRolls, lines);
    });
  }

  function removeUserRoll(index) {
    setPickUp(pu => {
      const updatedRolls = splice(pu.rolls, index);

      if (updatedRolls.length === 0) updatedRolls.push(emptyRoll);

      const lines = makeAddOnLines(
        pu.order.lines,
        updatedRolls,
        productsBySku,
        filmRollsById,
        props.user
      );

      return formatPickUpStateUpdate(pu, updatedRolls, lines);
    });
  }

  function duplicateRoll(roll) {
    setPickUp(pu => {
      const updatedRolls = [...pu.rolls, omit({ ...roll }, 'id')];
      const lines = makeAddOnLines(
        pu.order.lines,
        updatedRolls,
        productsBySku,
        filmRollsById,
        props.user
      );

      return formatPickUpStateUpdate(pu, updatedRolls, lines);
    });
  }

  function updatePurchaseRoll(index, line) {
    setPickUp(pu => {
      const lines = splice(pu.order.lines, index, line);

      return {
        ...pu,
        order: { ...pu.order, lines }
      };
    });
  }

  function removePurchaseRoll(index) {
    setPickUp(pu => {
      const lines = splice(pu.order.lines, index);
      if (lines.length === 0) lines.push(emptyLine);

      return { ...pu, order: { ...pu.order, lines } };
    });
  }

  function submit() {
    const formatter = pickUp.id ? formatPickUpForUpdate : formatNewPickUp;
    const formatted = formatter(pickUp, chargePickUp, productsBySku);
    const save = pickUp.id ? props.updatePickUp : props.addPickUp;

    setSaving(true);

    save(formatted)
      .then(() => {
        props.history.push(
          `${context.isAdmin ? '/admin/users' : '/users'}/${pickUp.customerId}`
        );
      })
      .catch(err => setError(err))
      .finally(() => setSaving(false));
  }

  return (
    <div className={classes.container}>
      <div className={classes.scrollable}>
        <div className={classes.row}>
          <PickUpAddressSelector
            address={selectedAddress}
            onSelect={id =>
              setPickUp(pu => ({
                ...pu,
                pickUpAddressId: id,
                messengerHoursId: null,
                messengerId: null,
                window: null
              }))
            }
            {...props}
          />
          <Divider
            className={classes.verticalDividerMargin}
            flexItem
            orientation="vertical"
          />
          <PickUpWindowSelector
            {...props}
            address={selectedAddress}
            onSelect={updates => setPickUp(pu => ({ ...pu, ...updates }))}
            pickUp={pickUp}
          />
        </div>

        <Divider />

        <PickUpFilmRolls
          addRoll={() => {
            setPickUp(pu => ({
              ...pu,
              rolls: [emptyRoll, ...pu.rolls]
            }));
          }}
          user={props.user}
          rolls={pickUp.rolls}
          updateRoll={updateUserRoll}
          duplicate={duplicateRoll}
          remove={removeUserRoll}
          products={filmRollProducts}
        />

        <Divider />

        <PickUpRollsForPurchase
          addRoll={() => {
            setPickUp(pu => ({
              ...pu,
              order: { ...pu.order, lines: [emptyLine, ...pu.order.lines] }
            }));
          }}
          products={filmRollProducts}
          remove={removePurchaseRoll}
          rolls={filter(
            pickUp.order.lines,
            l => l.productId === null || filmRollProductsById[l.productId]
          )}
          updateRoll={updatePurchaseRoll}
          user={props.user}
          isSmallScreen={screenSize.isSmallScreen}
        />
      </div>

      <PickUpActionBar
        user={props.user}
        products={props.products}
        rollCredits={chain(props.user)
          .get('rollCredits')
          .value()}
        chargePickUp={chargePickUp}
        pickUpFeeProduct={get(productsBySku, AddonSku.PICK_UP, {})}
        pickUp={pickUp}
        submit={submit}
        disabled={saving}
        isSmallScreen={screenSize.isSmallScreen}
      />
    </div>
  );
}

PickUpPage.propTypes = {
  addPickUp: PropTypes.func.isRequired,
  getPickUp: PropTypes.func.isRequired,
  getPickUpProducts: PropTypes.func.isRequired,
  pickUp: PropTypes.shape({}),
  updatePickUp: PropTypes.func.isRequired,
  user: PropTypes.shape({})
};
