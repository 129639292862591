import {
  filter,
  get,
  map,
  compact,
  orderBy,
  chain,
  omitBy,
  isEmpty
} from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  IconButton,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Paper
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import ExpandIcon from '@material-ui/icons/ExpandMore';
import ExpandRollsIcon from '@material-ui/icons/DoubleArrow';
import DownloadIcon from '@material-ui/icons/GetApp';
import PropTypes from 'prop-types';

import PickUpsListRoll from './PickUpsListRoll';

import contentAreasStyles from '../../assets/jss/material-kit-pro-react/views/componentsSections/contentAreas';
import { makeStyles } from '@material-ui/core/styles';
import downloadPickUpScans from '../../lib/utils/download-pickup-scans';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(theme => ({
  ...contentAreasStyles,
  action: {
    padding: '5px 0px'
  },
  actions: {
    flex: '1',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  active: {
    fontWeight: 'bold'
  },
  details: {
    padding: '0px'
  },
  divider: {
    height: '3px'
  },
  expandRolls: {
    transform: props =>
      props.allExpanded ? 'rotate(270deg)' : 'rotate(90deg)',
    transition: 'all 1s'
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  centerAlignText: {
    textAlign: 'center'
  },
  panelExpanded: {
    margin: '0px !important'
  },
  panelRoot: {
    marginBottom: '20px',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '0px'
    }
  },
  paper: {
    backgroundColor: 'transparent',
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: '5px'
  },
  pickUpSummary: {
    alignItems: 'center',
    display: 'flex',
    flex: '1',
    justifyContent: 'space-between',
    '& > h6': {
      flex: '1'
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      '& > h6': {
        width: '100%'
      }
    }
  },
  summaryRoot: {
    padding: '0px 10px',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    background: props =>
      props.upcoming ? theme.palette.secondary.main : theme.palette.white.main,
    color: props =>
      props.upcoming ? theme.palette.primary.main : theme.palette.primary.main
  }
}));

export default function PickUpsListPickUp(props) {
  const rollsWithScans = filter(props.pickUp.rolls, 'scans.length');
  const pickUpEmpty = rollsWithScans.length === 0;
  const [expand, setExpand] = useState(pickUpEmpty ? false : true);
  const [allExpanded, setAllExpanded] = useState(false);
  const [rollsExpanded, setRollsExpanded] = useState({});
  const [downloading, setDownloading] = useState(false);
  const futurePickUp = moment()
    .add(24, 'hours')
    .isBefore(moment(props.pickUp.date));
  const pickUpEditable = pickUpEmpty && futurePickUp;

  const classes = useStyles({
    upcoming: pickUpEditable,
    allExpanded: allExpanded,
    expanded: expand
  });

  useEffect(() => {
    setAllExpanded(!isEmpty(omitBy(rollsExpanded, v => !v)));
  }, [rollsExpanded]);

  function expandAll(rolls) {
    return chain(rolls)
      .keyBy('id')
      .mapValues(v => !!v.scans.length)
      .value();
  }

  function download(pickUp) {
    setDownloading(true);
    downloadPickUpScans(pickUp).finally(() => setDownloading(false));
  }

  function formatAddress(address = {}) {
    const { address1, address2, city } = address;
    return `${compact([address1, address2]).join(' ')}, ${city}`;
  }

  return (
    <ExpansionPanel
      expanded={expand}
      classes={{ root: classes.panelRoot, expanded: classes.panelExpanded }}
    >
      <ExpansionPanelSummary
        onClick={() => {
          if (!expand && !allExpanded) {
            setAllExpanded(true);
            setRollsExpanded(expandAll(props.pickUp.rolls));
          }
          setExpand(!expand);
        }}
        classes={{ root: classes.summaryRoot }}
      >
        <div className={classes.pickUpSummary}>
          {pickUpEditable ? (
            <Typography variant="subtitle2">
              Upcoming Pick Up At: {formatAddress(get(props, 'pickUp.address'))}
            </Typography>
          ) : (
            <Typography variant="subtitle1">
              Order ID: {get(props, 'pickUp.id')}
            </Typography>
          )}

          <Typography
            variant={pickUpEditable ? 'subtitle2' : 'subtitle1'}
            className={classes.centerAlignText}
          >
            {moment(props.pickUp.date).format('LL')}
          </Typography>
          <div className={classes.actions}>
            {pickUpEditable ? (
              <Link
                className={classes.flexEnd}
                to={`/users/${props.user.id}/pick-ups/${props.pickUp.id}`}
              >
                <IconButton
                  color="primary"
                  className={classes.action}
                  onClick={e => e.stopPropagation()}
                >
                  <EditIcon />
                </IconButton>
              </Link>
            ) : (
              <div className={classes.flexEnd}>
                <IconButton
                  color="primary"
                  className={classes.action}
                  disabled={downloading}
                  onClick={e => {
                    e.stopPropagation();
                    download(props.pickUp);
                  }}
                >
                  <DownloadIcon />
                </IconButton>
                <IconButton
                  color="primary"
                  className={classes.action}
                  onClick={e => {
                    e.stopPropagation();
                    if (allExpanded) {
                      setRollsExpanded({});
                    } else {
                      setExpand(true);
                      setRollsExpanded(expandAll(props.pickUp.rolls));
                    }
                    setAllExpanded(!allExpanded);
                  }}
                >
                  <ExpandRollsIcon className={classes.expandRolls} />
                </IconButton>
              </div>
            )}
          </div>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails classes={{ root: classes.details }}>
        <Paper className={classes.paper}>
          {map(
            orderBy(
              props.pickUp.rolls,
              ['scans.length', 'id'],
              ['desc', 'asc']
            ),
            roll => (
              <div key={roll.id}>
                <PickUpsListRoll
                  expanded={!!rollsExpanded[roll.id]}
                  setExpanded={expand =>
                    setRollsExpanded(state => ({ ...state, [roll.id]: expand }))
                  }
                  roll={roll}
                  {...props}
                />
                <Divider className={classes.divider} />
              </div>
            )
          )}
        </Paper>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

PickUpsListPickUp.propTypes = {
  isSmallScreen: PropTypes.bool.isRequired,
  pickUp: PropTypes.shape({}).isRequired
};
