class LinkedListNode {
  constructor(data) {
    this.data = data;
    this.next = null;
    this.prev = null;
  }
}

class LinkedList {
  constructor() {
    this.list = [];
  }

  add(data) {
    const newNode = new LinkedListNode(data);

    if (this.list.length === 0) {
      this.list[0] = newNode;
      newNode.next = newNode;
      newNode.prev = newNode;
    } else {
      const tail = this.list[this.list.length - 1];

      tail.next = newNode;
      newNode.prev = tail;
      newNode.next = this.list[0];
      this.list[0].prev = newNode;
      this.list.push(newNode);
    }
  }

  values() {
    return this.list;
  }

  length() {
    return this.list.length
  }
}

export default LinkedList;
