import { get } from 'lodash';
import numeral from 'numeral';
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: '10px 0px'
  },
  flex6: {
    flex: '6'
  },
  line: {
    display: 'flex',
    flexDirection: 'row-reverse'
  },
  lineLabel: {
    marginRight: '10px',
    flex: '1.5',
    [theme.breakpoints.down('sm')]: {
      flex: '3'
    }
  },
  lineAmount: {
    flex: '1.5',
    [theme.breakpoints.down('sm')]: {
      flex: '3'
    }
  }
}));

export default function OrderTotal(props) {
  const classes = useStyles();
  const pickUpFee = get(props, 'pickUpFee', 0);

  return (
    <div className={classes.container}>
      {props.showPickUpFee && (
        <div className={classes.line}>
          <div className={classes.lineAmount}>
            {numeral(get(props, 'pickUpFee', 0)).format('$0.00')}
          </div>
          <div className={classes.lineLabel}>Pick Up Fee</div>
          <div className={classes.flex6}></div>
        </div>
      )}
      <div className={classes.line}>
        <div className={classes.lineAmount}>
          {numeral(get(props, 'total', 0) + pickUpFee).format('$0.00')}
        </div>
        <div className={classes.lineLabel}>Total</div>
        <div className={classes.flex6}></div>
      </div>
    </div>
  );
}

OrderTotal.propTypes = {
  total: PropTypes.number,
  showPickUpFee: PropTypes.bool,
  pickUpFee: PropTypes.number
};

OrderTotal.defaultProps = {
  showPickUpFee: false
};
