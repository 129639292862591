import cx from 'classnames';
import { size } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

// @material-ui/core components
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
// @material-ui/icons

import GridItem from '../Grid/GridItem.js';

import SignUp from './SignUp';
import Login from './Login';
import AddressForm from '../../containers/AddressForm/AddressForm';
import StripeCheckout from '../StripeCheckout/StripeCheckout';
import Role from '../../lib/Constants/Role';

import { makeStyles } from '@material-ui/core/styles';
import CheckCircle from '@material-ui/icons/CheckCircle';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const styles = theme => ({
  content: {
    display: 'flex',
    flex: '1',
    minHeight: '362px',
    width: '414px',
    [theme.breakpoints.down('xs')]: {
      width: '360px',
      padding: '5px 18px'
    }
  },
  checkCircle: {
    fill: props =>
      props.valid ? theme.palette.success.dark : theme.palette.primary.main
  },
  paper: {
    [theme.breakpoints.down('xs')]: {
      margin: '10px'
    }
  },
  titleContent: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between'
  },
  titleRoot: {
    padding: '14px 24px'
  }
});

const useStyles = makeStyles(styles);

export default function Onboarding(props) {
  const [user, setUser] = useState(null);
  const [showLogin, setShowLogin] = useState(props.showLogin);
  const [currentStepValid, setCurrentStepValid] = useState(false);
  const classes = useStyles({ valid: currentStepValid });

  useEffect(() => {
    setUser(props.currentUser);
  }, [props.currentUser]);

  useEffect(() => {
    setShowLogin(props.showLogin);
  }, [props.showLogin]);

  function getStep() {
    if (!user && showLogin) return 1;
    if (!user) return 1;
    if (!size(user.addresses)) return 2;
    return 3;
  }

  function renderStepText(step) {
    if (showLogin && step === 1) return 'Login';
    if (step === 1) return 'Sign Up';
    if (step === 2) return 'Add A Pick Up Address';
    return 'Activate Your Subscription';
  }

  function renderStep(step = 1) {
    if (showLogin && step === 1)
      return (
        <Login
          login={props.login}
          showSignUp={() => setShowLogin(false)}
          onChange={(_, valid) => setCurrentStepValid(valid)}
        />
      );

    if (step === 1)
      return (
        <SignUp
          signup={props.signup}
          showLogin={() => setShowLogin(true)}
          onChange={(_, valid) => setCurrentStepValid(valid)}
        />
      );

    if (step === 2)
      return (
        <GridItem className={cx(classes.mrAuto, classes.gridItem)}>
          <AddressForm
            {...props}
            onboarding
            user={user}
            onChange={(_, valid) => setCurrentStepValid(valid)}
            onUpdateUser={u => setUser(u)}
          />
        </GridItem>
      );

    return (
      <StripeCheckout
        user={user}
        onSuccess={u => setUser(u)}
        onChange={(_, valid) => setCurrentStepValid(valid)}
        onSubmit={props.createSubscription}
      />
    );
  }

  if (user && user.active && user.role === Role.CUSTOMER) {
    return <Redirect to={`/users/${user.id}`} />;
  }

  const step = getStep();

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => props.onClose(false)}
    >
      <DialogTitle classes={{ root: classes.titleRoot }}>
        <div className={classes.titleContent}>
          <div>{renderStepText(step)}</div>
          <CheckCircle className={classes.checkCircle} />
        </div>
      </DialogTitle>
      <Divider />
      <DialogContent classes={{ root: classes.content }}>
        {renderStep(step)}
      </DialogContent>
    </Dialog>
  );
}

Onboarding.propTypes = {
  createSubscription: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({}),
  login: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  showLogin: PropTypes.bool.isRequired,
  signup: PropTypes.func.isRequired
};
