import { size } from 'lodash';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

export default function LoadingText(props) {
  const { text, indicator, indicatorLength } = props;
  const [indicators, setIndicators] = useState(indicator);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIndicators(
        size(indicators) > indicatorLength - 1
          ? indicator
          : `${indicators}${indicator}`
      );
    }, 500);

    return function cleanup() {
      clearTimeout(timeout);
    };
  });

  return (
    <span>
      {text}
      {indicators}
    </span>
  );
}

LoadingText.propTypes = {
  indicator: PropTypes.string,
  indicatorLength: PropTypes.number,
  text: PropTypes.string.isRequired
};

LoadingText.defaultProps = {
  indicator: '.',
  indicatorLength: 3
};
