import Api from '../api';

const Auth = '/auth';

const AuthAPI = {
  getCurrentUser() {
    return Api.get(`${Auth}/current-user`);
  },

  login(data) {
    return Api.post(`${Auth}/login`, { data });
  },

  signup(data) {
    return Api.post(`${Auth}/signup`, { data });
  }
};

export default AuthAPI;
