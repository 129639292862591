import { chain, get, pick, capitalize, size } from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CustomInput from '../../../components/CustomInput/CustomInput';

import Divider from '@material-ui/core/Divider';

import { makeStyles } from '@material-ui/core/styles';
import UserDetailInput from './UserDetailInput';
import validateEmail from '../../../lib/utils/Form/validate-email';
import validatePhone from '../../../lib/utils/Form/validate-phone';
import { UpdatePaymentModal } from './UpdatePaymentModal';

const useStyles = makeStyles(theme => ({
  creditCard: {
    alignItems: 'flex-end',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px 0px'
  },
  creditCardNumberLabel: {
    color: theme.palette.info.main,
    fontSize: '11px',
    marginBottom: '10px'
  },
  inputsContainer: {},
  root: {
    marginBottom: '50px'
  },
  submit: {
    display: 'flex',
    flexDirection: 'row-reverse',
    margin: '10px 0px'
  }
}));

export default function UserDetails(props) {
  const classes = useStyles();
  const [user, setUser] = useState({ ...props.user });
  const [updatePayment, setUpdatePayment] = useState(false);
  const fields = ['email', 'password', 'firstName', 'lastName', 'phone'];

  function onChange(name, value) {
    setUser(u => ({ ...u, [name]: value }));
  }

  function updateUser() {
    const update = pick(user, fields);
    return props.updateUser(props.user.id, update);
  }

  const defaultSource = get(props.user, 'stripeCustomer.default_source');
  const creditCard = chain(props.user)
    .get('stripeCustomer.sources.data')
    .find(source => source.id === defaultSource)
    .value();
  const { brand, last4 } = creditCard || {};

  return (
    <div className={classes.root}>
      <h4>{capitalize(props.user.role)} Details:</h4>
      <Divider />
      <div className={classes.inputsContainer}>
        <UserDetailInput
          name="firstName"
          labelText="First Name"
          onChange={onChange}
          save={updateUser}
          validate={firstName => !!size(firstName)}
          value={user.firstName}
        />
        <UserDetailInput
          name="lastName"
          labelText="Last Name"
          onChange={onChange}
          save={updateUser}
          validate={lastName => !!size(lastName)}
          value={user.lastName}
        />
        <UserDetailInput
          name={'email'}
          labelText="Email"
          onChange={onChange}
          save={updateUser}
          validate={email => validateEmail(email)}
          value={user.email}
        />
        <UserDetailInput
          name={'phone'}
          labelText="Phone"
          onChange={onChange}
          save={updateUser}
          validate={phone => validatePhone(phone)}
          value={user.phone}
        />
      </div>
      <div className={classes.creditCard}>
        <div className={classes.creditCardNumber}>
          <div className={classes.creditCardNumberLabel}>Payment Method</div>
          <div>
            {brand} ending in {last4}
          </div>
        </div>
        <div className={classes.creditCardActions}>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => setUpdatePayment(true)}
          >
            Edit
          </Button>
        </div>
      </div>

      <UpdatePaymentModal
        open={updatePayment}
        onClose={() => setUpdatePayment(false)}
        {...props}
      />
    </div>
  );
}

UserDetails.propTypes = {
  user: PropTypes.shape({}).isRequired,
  updateUser: PropTypes.func.isRequired
};
