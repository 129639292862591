import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Table from '../../components/Table/Table';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import styles from '../../assets/jss/material-kit-pro-react/views/componentsSections/contentAreas';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(styles);

export default function MessengersPage(props) {
  const classes = useStyles();
  const [page] = useState(0);

  useEffect(() => {
    props.getMessengers();
  }, [page]);

  return (
    <Table
      tableHead={['#', 'Name', 'Week Days', 'Since', 'Actions']}
      tableData={props.messengers.map(m => {
        return {
          key: m.id,
          data: [
            <Link to={`/admin/messengers/${m.id}`}>{m.id}</Link>,
            <Link to={`/admin/messengers/${m.id}`}>
              {`${m.firstName} ${m.lastName}`}
            </Link>,
            '',
            moment(m.createdAt).format('LLLL'),
            ''
          ]
        };
      })}
      customCellClasses={[
        classes.textCenter,
        classes.textRight,
        classes.textRight
      ]}
      customClassesForCells={[0, 4, 5]}
      customHeadCellClasses={[
        classes.textCenter,
        classes.textRight,
        classes.textRight
      ]}
      customHeadClassesForCells={[0, 4, 5]}
    />
  );
}

MessengersPage.propTypes = {
  messengers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getMessengers: PropTypes.func.isRequired
};
