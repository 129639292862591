import { parallelLimit } from 'async';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { chain, map, flatMap } from 'lodash';
import moment from 'moment';
import querystring from 'querystring';
import Api from '../api';
import JsZip from 'jszip';

const User = '/users';

const UserAPI = {
  get(userId) {
    return Api.get(`${User}/${userId}`);
  },

  getAll(params) {
    return Api.get(`${User}?${querystring.encode(params)}`);
  },

  update(userId, update) {
    return Api.put(`${User}/${userId}`, { data: update });
  },

  createAddress(userId, address) {
    return Api.post(`${User}/${userId}/address`, { data: address });
  },

  getAddress(userId, addressId) {
    return Api.get(`${User}/${userId}/address/${addressId}`);
  },

  updateAddress(userId, address) {
    return Api.put(`${User}/${userId}/address/${address.id}`, {
      data: address
    });
  },

  removeAddress(userId, addressId) {
    return Api.delete(`${User}/${userId}/address/${addressId}`);
  },

  getAvailabilities(userId, params) {
    return Api.get(
      `${User}/${userId}/availabilities?${querystring.encode(params)}`
    );
  },

  createSubscription(userId, token) {
    return Api.post(`${User}/${userId}/subscriptions`, { data: { token } });
  },

  updatePayment(userId, token) {
    return Api.put(`${User}/${userId}/payment-methods`, { data: { token } });
  },

  addPickUp(userId, pickUp) {
    return Api.post(`${User}/${userId}/pick-ups`, { data: pickUp });
  },

  getPickUps(userId, params) {
    return Api.get(`${User}/${userId}/pick-ups?${querystring.encode(params)}`);
  },

  getPickUp(userId, pickUpId) {
    return Api.get(`${User}/${userId}/pick-ups/${pickUpId}`);
  },

  updatePickUp(userId, pickUpId, pickUp) {
    return Api.put(`${User}/${userId}/pick-ups/${pickUpId}`, {
      data: pickUp
    });
  },

  addFilmRoll(film) {
    const url = `${User}/${film.customerId}/pick-ups/${film.pickUpId}/film-rolls`;
    return Api.post(url, { data: film });
  },

  getFilmRoll(customerId, pickUpId, rollId) {
    const url = `${User}/${customerId}/pick-ups/${pickUpId}/film-rolls/${rollId}`;
    return Api.get(url);
  },

  updateFilmRoll(film) {
    const url = `${User}/${film.customerId}/pick-ups/${film.pickUpId}/film-rolls/${film.id}`;
    return Api.put(url, { data: film });
  },

  async addFilmRollScan(film, scanFile, onUploadProgress) {
    const params = { fileType: scanFile.type };
    const url = `${User}/${film.customerId}/pick-ups/${
      film.pickUpId
    }/film-rolls/${film.id}/scans?${querystring.encode(params)}`;
    const newScan = {
      filmRollId: film.id,
      name: scanFile.name,
      description: ''
    };
    const createdScan = await Api.post(url, { data: newScan });

    await axios({
      method: 'PUT',
      data: scanFile,
      url: createdScan.write,
      onUploadProgress,
      headers: {
        'Content-Type': scanFile.type
      }
    });

    return UserAPI.getFilmRoll(film.customerId, film.pickUpId, film.id);
  },

  removeFilmRollScan(film, scanId) {
    const url = `${User}/${film.customerId}/pick-ups/${film.pickUpId}/film-rolls/${film.id}/scans/${scanId}`;
    return Api.delete(url);
  },

  unlockHDScans(userId, params) {
    const url = `${User}/${userId}/unlock-hd-scans`;
    return Api.post(url, { data: params });
  },

  unlockTiffScans(userId, params) {
    const url = `${User}/${userId}/unlock-tiff-scans`;
    return Api.post(url, { data: params });
  },

  async downloadPickUpScans(userId, pickUpId) {
    const zip = new JsZip();
    const now = moment().format('LLLL');
    const folderName = `darklab Scans For Pick Up - ${pickUpId} ${now}`;
    const { pickUp } = await UserAPI.getPickUp(userId, pickUpId);
    const scans = flatMap(pickUp.rolls, 'scans');
    const rollFoldersById = chain(pickUp)
      .get('rolls')
      .keyBy('id')
      .mapValues(({ filmRoll: roll }) => {
        return zip.folder([roll.model, roll.format, roll.id, now].join('_'));
      })
      .value();

    const callbacks = map(scans, scan => {
      return cb => {
        const { name, filmRollId } = scan;
        const read = scan.hiRes ? scan.hiRes.read : scan.midRes.read;
        const config = { responseType: 'arraybuffer' };

        axios
          .get(read, config)
          .then(({ data }) => {
            rollFoldersById[filmRollId].file(
              name,
              Buffer.from(data, 'binary').toString('base64'),
              {
                base64: true
              }
            );
            cb();
          })
          .catch(cb);
      };
    });

    await parallelLimit(callbacks, 5);
    zip.generateAsync({ type: 'blob' }).then(content => {
      saveAs(content, folderName);
    });

    return Promise.resolve();
  },

  async uploadProfilePic(userId, params, file) {
    const { url } = await Api.get(
      `${User}/${userId}/profile-pic?${querystring.encode(params)}`
    );

    await axios({
      method: 'PUT',
      data: file,
      url,
      headers: {
        'Content-Type': params.fileType
      }
    });

    return UserAPI.get(userId);
  }
};

export default UserAPI;
