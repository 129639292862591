import cx from 'classnames';
import { first, get, isEmpty, last, range, size } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import LinkedList from '../../lib/utils/linked-list';
import { makeStyles } from '@material-ui/core/styles';
import { RollSummaryThumbs } from './RollSummaryThumbs';
import RollSummaryActions from './RollSummaryActions';
import Typography from '@material-ui/core/Typography';
import RollSummaryNav from './RollSummaryNav';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(theme => ({
  details: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > h6': {
      display: 'flex',
      flex: '1',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'space-between'
      }
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  detailsCenter: {
    justifyContent: 'center'
  },
  detailsEnd: {
    justifyContent: 'flex-end'
  },
  detailsRollId: {
    marginRight: '5px'
  },
  detailsStatus: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100px'
  },
  hideDesktop: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  rollThumbs: {
    maxHeight: props => (props.expanded ? '0px' : '145px'),
    overflow: 'hidden',
    transition: 'max-height 1s ease-in-out',
    [theme.breakpoints.down('sm')]: {
      flex: '1',
      maxHeight: props => (props.expanded ? '0px' : '260px'),
      transition: 'max-height 1s ease-in-out',
      width: '100%'
    }
  },
  success: {
    color: theme.palette.success.dark,
    fontWeight: 'bold'
  }
}));

export default function RollSummary(props) {
  const classes = useStyles(props);
  const scansEmpty = isEmpty(props.roll.scans);
  const linkedThumbList = new LinkedList();
  const displayLen = getDisplayLen(props.screenSize);
  const thumbsLen =
    displayLen * 3 > props.thumbs.length ? displayLen * 3 : props.thumbs.length;
  props.thumbs.forEach(thumb => linkedThumbList.add(thumb));
  if (displayLen * 3 > props.thumbs.length) {
    range(displayLen * 3 - props.thumbs.length).forEach(i => {
      const val = linkedThumbList.values()[i];
      if (!val) return;
      const next = val.data;
      linkedThumbList.add({ ...next, id: `${next.id}_${100 * Math.random()}` });
    });
  }

  const [thumbs, setThumbs] = useState(
    getThumbsFromImage(first(linkedThumbList.values()), thumbsLen)
  );

  function getDisplayLen(screenSize) {
    if (screenSize.isSmallScreen) return 5;
    if (screenSize.isMidScreen) return 4;
    if (screenSize.isLargeScreen) return 5;
    if (screenSize.isXLScreen) return 6;
    if (screenSize.isXXLScreen) return 8;
    if (screenSize.isXXXLScreen) return 10;
    return 6;
  }

  const setNextThumbs = useCallback(() => {
    const index = displayLen - 1;
    setThumbs(getThumbsFromImage(thumbs[index].prev, thumbsLen));
  }, [displayLen, thumbs, thumbsLen]);

  const setPrevThumbs = useCallback(() => {
    const thumb = range(displayLen - 1).reduce(t => t.prev, first(thumbs));
    setThumbs(getThumbsFromImage(thumb.next, thumbsLen));
  }, [displayLen, thumbs, thumbsLen]);

  function getThumbsFromImage(thumb, len) {
    if (!thumb) return [];

    return range(len - 1).reduce(thumbs => [...thumbs, last(thumbs).next], [
      thumb
    ]);
  }

  useEffect(() => {
    setThumbs(getThumbsFromImage(first(thumbs), thumbsLen));
  }, [thumbsLen]);

  return (
    <div className={classes.root}>
      <div className={classes.details}>
        <Typography variant="subtitle2">
          <span className={classes.detailsRollId}>Roll Id:</span>
          <span>
            {get(props.user, 'firstName.[0]')}
            {get(props.user, 'lastName.[0]')}P{get(props.roll, 'pickUpId')}R
            {get(props.roll, 'id')}
          </span>
        </Typography>
        <Typography variant="subtitle2" className={classes.detailsCenter}>
          <span className={classes.hideDesktop}>Roll Model: </span>
          <span>{get(props.roll, 'filmRoll.name')}</span>
        </Typography>
        <Typography variant="subtitle2" className={classes.detailsEnd}>
          Status:{' '}
          <span
            className={cx(classes.detailsStatus, {
              [classes.success]: size(thumbs) > 0
            })}
          >
            {size(thumbs) === 0 ? 'Pending' : 'Ready'}
          </span>
        </Typography>
      </div>
      {!scansEmpty && <RollSummaryActions {...props} />}
      {size(thumbs) > 0 && (
        <div className={classes.rollThumbs}>
          <RollSummaryThumbs
            displayLen={displayLen}
            setNextThumbs={setNextThumbs}
            setPrevThumbs={setPrevThumbs}
            thumbs={thumbs}
            roll={props.roll}
            openImage={props.openImage}
            setSelected={props.setSelected}
            selected={props.selected}
          />
        </div>
      )}
      {!scansEmpty && (
        <RollSummaryNav
          setNextThumbs={setNextThumbs}
          setPrevThumbs={setPrevThumbs}
          {...props}
        />
      )}
    </div>
  );
}

RollSummary.propTypes = {
  roll: PropTypes.shape({}).isRequired,
  thumbs: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
