import cx from 'classnames';
import { get, keyBy, toLower } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import CheckCircle from '@material-ui/icons/CheckCircle';
import PickUpRollForPurchase from './PickUpRollForPurchase';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  checkCircle: {
    fill: theme.palette.success.dark,
    marginLeft: '10px'
  },
  container: {
    minHeight: '300px'
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between'
  },
  headerText: {
    alignItems: 'center',
    display: 'flex'
  },
  mobileHide: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  rolls: {
    backgroundColor: 'transparent',
    padding: '20px 10px'
  },
  rollsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 'bold'
  },
  rollsHeader1: {
    flex: '1'
  },
  rollsHeader2: {
    flex: '2'
  },
  rollsHeader5: {
    flex: '5'
  },
  tableTitle: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

export default function PickUpRollsForPurchase(props) {
  const classes = useStyles();

  function formatFilmRoll(roll) {
    const { iso, name, format, native_process } = roll;
    const size = `${format === '135' ? '35' : format}mm`;
    const label = name.includes(iso)
      ? `${name} ${size}`
      : `${name} ${iso} ${size}`;
    return toLower(native_process) === 'e-6' ? `${label} (E6 + $5.99)` : label;
  }

  const options = props.products.map(({ id, filmRoll }) => ({
    label: formatFilmRoll(filmRoll),
    value: id
  }));
  const filmRollProducts = keyBy(props.products, 'id');

  return (
    <div className={classes.container}>
      <h4 className={classes.header}>
        <span className={classes.headerText}>
          4. Buy New Rolls Delivered At Your Pick Up{' '}
          <CheckCircle className={classes.checkCircle} />
        </span>

        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={() => props.addRoll()}
        >
          Add Roll
        </Button>
      </h4>

      <Divider />

      <div className={classes.rolls}>
        <div className={classes.rollsHeader}>
          <div className={classes.rollsHeader5}>Roll</div>
          <div className={cx(classes.rollsHeader1, classes.mobileHide)}>
            ISO
          </div>
          <div className={cx(classes.rollsHeader1, classes.mobileHide)}>
            Format
          </div>
          <div className={cx(classes.rollsHeader1, classes.mobileHide)}>
            Price
          </div>
          <div className={classes.rollsHeader1}>
            {props.isSmallScreen ? 'Qty' : 'Quantity'}
          </div>
          <div className={classes.rollsHeader1}>Remove</div>
        </div>
        <Divider />
        {props.rolls.map((roll, i) => (
          <PickUpRollForPurchase
            key={roll.id || roll.tempKey}
            updateRoll={(k, v) => {
              const update = { [k]: v };

              if (k === 'quantity' || k === 'productId') {
                const price = get(
                  filmRollProducts,
                  `${roll.productId || v}.price`
                );
                const quantity = k === 'quantity' ? v : roll.quantity;
                Object.assign(update, { amount: price * quantity });
              }

              props.updateRoll(i, { ...roll, ...update });
            }}
            remove={() => props.remove(i)}
            roll={roll}
            options={options}
            filmRolls={filmRollProducts}
          />
        ))}
      </div>
    </div>
  );
}

PickUpRollsForPurchase.propTypes = {
  addRoll: PropTypes.func.isRequired,
  isSmallScreen: PropTypes.bool.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  remove: PropTypes.func.isRequired,
  rolls: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updateRoll: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired
};
