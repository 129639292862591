import { chain, get } from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, Divider } from '@material-ui/core';
import CheckCircle from '@material-ui/icons/CheckCircle';

import Dropdown from '../Dropdown/Dropdown';

import { makeStyles } from '@material-ui/core/styles';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  checkCircle: {
    fill: theme.palette.success.dark,
    marginLeft: '10px'
  },
  content: {
    minHeight: '185px',
    padding: '20px',
    width: '360px'
  },
  contentActions: {
    display: 'flex',
    justifyContent: 'space-around',
    padding: '15px 0px 0px 0px'
  },
  dropdown: {
    margin: '5px 0px'
  },
  editable: {
    borderBottom: `1px dashed ${theme.palette.primary.main}`
  },
  option: {
    margin: '10px'
  },
  optionTitle: {
    fontWeight: 'bold'
  },
  remove: {
    cursor: 'pointer',
    fill: theme.palette.primary.light,
    '&:hover': {
      fill: theme.palette.primary.dark
    }
  },
  selectedOption: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '5px 0px'
  },
  selectedOptionActions: {
    alignItems: 'center',
    display: 'flex'
  }
}));

export default function FilmRollOptions(props) {
  const classes = useStyles();
  const pushPullOptions = chain(props.filmRoll)
    .get('pushPullOptions')
    .map(opt => ({
      label: opt.factor < 0 ? `Pull ${opt.factor}` : `Push ${opt.factor}`,
      value: opt.id
    }))
    .value();
  const crossProcessOptions = chain(props.filmRoll)
    .get('crossProcessOptions')
    .map(opt => ({
      label: `Cross Process as ${opt.targetFormat}`,
      value: opt.id
    }))
    .value();

  const pushPullId = get(props.roll, 'pushPullId');
  const [pushPullOption, setPushPullOption] = useState({
    label: chain(pushPullOptions)
      .keyBy('value')
      .get(`${pushPullId}.label`)
      .value(),
    value: pushPullId
  });
  const crossProcessId = get(props.roll, 'crossProcessId');
  const [crossProcessOption, setCrossProcessOption] = useState({
    label: chain(crossProcessOptions)
      .keyBy('value')
      .get(`${crossProcessId}.label`)
      .value(),
    value: crossProcessId
  });

  return (
    <Dialog onClose={props.onClose} open={props.open}>
      <DialogTitle>Film Roll Options</DialogTitle>
      <Divider />
      <DialogContent className={classes.content}>
        <div className={classes.option}>
          <div className={classes.optionTitle}>Add Push or Pull (+$1.99)</div>
          {pushPullId ? (
            <div className={classes.selectedOption}>
              <span
                onClick={() => {
                  setPushPullOption(null);
                  props.updateRoll('pushPullId', null);
                }}
                className={classes.editable}
              >
                {pushPullOption.label}
              </span>
              <div className={classes.selectedOptionActions}>
                <CheckCircle className={classes.checkCircle} />
                <RemoveCircleOutline
                  onClick={() => {
                    setPushPullOption(null);
                    props.updateRoll('pushPullId', null);
                  }}
                  className={classes.remove}
                />
              </div>
            </div>
          ) : (
            <Dropdown
              className={classes.dropdown}
              onChange={opt => {
                setPushPullOption(opt);
                props.updateRoll('pushPullId', opt.value);
              }}
              value={pushPullOption}
              options={pushPullOptions}
            />
          )}
        </div>
        <Divider />
        {crossProcessOptions.length > 0 && (
          <div className={classes.option}>
            <div className={classes.optionTitle}>Add Cross Processing</div>
            {crossProcessId ? (
              <div className={classes.selectedOption}>
                <span
                  onClick={() => {
                    setCrossProcessOption(null);
                    props.updateRoll('crossProcessId', null);
                  }}
                  className={classes.editable}
                >
                  {crossProcessOption.label}
                </span>
                <div className={classes.selectedOptionActions}>
                  <CheckCircle className={classes.checkCircle} />
                  <RemoveCircleOutline
                    onClick={() => {
                      setCrossProcessOption(null);
                      props.updateRoll('crossProcessId', null);
                    }}
                    className={classes.remove}
                  />
                </div>
              </div>
            ) : (
              <Dropdown
                className={classes.dropdown}
                onChange={opt => {
                  setCrossProcessOption(opt);
                  props.updateRoll('crossProcessId', opt.value);
                }}
                value={crossProcessOption}
                options={crossProcessOptions}
              />
            )}
          </div>
        )}
        <div className={classes.contentActions}>
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={props.onClose}
          >
            Save and Close
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

FilmRollOptions.propTypes = {
  filmRoll: PropTypes.shape({}),
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  roll: PropTypes.shape({}),
  updateRoll: PropTypes.func.isRequired
};
