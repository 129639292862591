import { combineReducers } from '@reduxjs/toolkit';
import appReducer from './containers/App/slice';
import addressFormReducer from './containers/AddressForm/slice';
import onboardingReducer from './containers/Onboarding/slice';
import messengersReducer from './containers/Messengers/slice';
import pickUpsReducer from './containers/PickUps/slice';
import productsReducer from './containers/Products/slice';
import usersReducer from './containers/Users/slice';

export default combineReducers({
  app: appReducer,
  addressForm: addressFormReducer,
  onboarding: onboardingReducer,
  messengers: messengersReducer,
  pickUps: pickUpsReducer,
  products: productsReducer,
  users: usersReducer
});
