import { chain, filter, get, keyBy, omit, without } from 'lodash';
import AddonSku from '../Constants/AddonSku';

function resolvePickUpFeeLine(pickUp, lines, chargePickUpFee, products) {
  const pickUpProduct = get(products, AddonSku.PICK_UP);
  const linesByProductId = keyBy(lines, 'productId');
  const lineExists = !!linesByProductId[pickUpProduct.id];

  if (!chargePickUpFee && lineExists) {
    return without(lines, l => l.productId === pickUpProduct.id);
  } else if (chargePickUpFee && !lineExists) {
    return lines.concat({
      customerId: get(pickUp, 'customerId'),
      orderId: get(pickUp.order, 'id'),
      productId: get(pickUpProduct, 'id'),
      amount: get(pickUpProduct, 'price'),
      notes: '',
      stripeId: null,
      quantity: 1
    });
  } else {
    return lines;
  }
}

export function formatNewPickUp(pickUp, chargePickUpFee, products) {
  const lines = filter([...pickUp.order.lines], 'productId').map(l =>
    omit(l, 'tempKey')
  );

  return {
    ...pickUp,
    date: pickUp.date.toDate().toISOString(),
    order: {
      ...pickUp.order,
      lines: resolvePickUpFeeLine(pickUp, lines, chargePickUpFee, products)
    }
  };
}

export function formatPickUpForUpdate(pickUp, chargePickUpFee, products) {
  const lines = resolvePickUpFeeLine(
    pickUp,
    pickUp.order.lines,
    chargePickUpFee,
    products
  );

  return {
    newLines: chain(lines)
      .filter(l => l.productId && !l.id)
      .map(l => ({ ...l, orderId: pickUp.order.id }))
      .value(),
    lines: filter(lines, 'id'),
    rolls: filter(pickUp.rolls, 'id').map(r => ({ ...r, scans: [] })),
    newRolls: chain(pickUp.rolls)
      .filter(r => r.filmRollId && !r.id)
      .map(r => ({ ...r, pickUpId: pickUp.id }))
      .value(),
    order: omit(pickUp.order, 'lines'),
    pickUp: omit(pickUp, [
      'lines',
      'rolls',
      'order',
      'messenger',
      'customer',
      'address',
      'window',
      'credit'
    ])
  };
}
