import { size, toLower, omit, some, isEmpty } from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
// @material-ui/core components
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
// core components
import GridItem from '../Grid/GridItem.js';

import validateEmail from '../../lib/utils/Form/validate-email';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  actions: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: '15px'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  inputRoot: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minHeight: '70px'
  },
  login: {
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
      fontWeight: 'bold'
    }
  },
  row: {
    alignItems: 'center',
    display: 'flex'
  }
}));

/**
 * SignUp Component
 */
export default function SignUp(props) {
  const [form, setForm] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const classes = useStyles();

  function handleChange(e) {
    e.persist();
    setForm(inputs => ({ ...inputs, [e.target.name]: e.target.value }));
    props.onChange(form, !disableForm());
  }

  function submitForm(e) {
    if (e) e.preventDefault();
    props.signup(omit(form, 'confirmPassword'));
  }

  function disableForm() {
    const fields = [
      'firstName',
      'lastName',
      'email',
      'password',
      'confirmPassword',
      'phone'
    ];

    if (some(formErrors, isError => isError)) return true;
    if (some(fields.map(f => isEmpty(form[f])))) return true;
  }

  return (
    <form onSubmit={submitForm} className={classes.form}>
      <div className={classes.row}>
        <TextField
          classes={{ root: classes.inputRoot }}
          name="firstName"
          label="First Name"
          onChange={handleChange}
          required
          onBlur={() =>
            setFormErrors(errors => ({
              ...errors,
              firstName: !size(form.firstName)
            }))
          }
          error={formErrors.firstName}
          helperText={
            formErrors.firstName && 'Please fill out a valid first name'
          }
        />
        <TextField
          classes={{ root: classes.inputRoot }}
          name="lastName"
          label="Last Name"
          onChange={handleChange}
          required
          onBlur={() =>
            setFormErrors(errors => ({
              ...errors,
              lastName: !size(form.lastName)
            }))
          }
          error={formErrors.lastName}
          helperText={
            formErrors.lastName && 'Please fill out a valid last name'
          }
        />
      </div>
      <div className={classes.row}>
        <TextField
          classes={{ root: classes.inputRoot }}
          name="phone"
          label="Phone"
          onChange={handleChange}
          fullWidth
          required
          onBlur={() =>
            setFormErrors(errors => ({
              ...errors,
              phone: !size(form.phone)
            }))
          }
          error={formErrors.phone}
          helperText={
            formErrors.phone && 'Please fill out a valid phone number'
          }
        />
      </div>
      <div className={classes.row}>
        <TextField
          classes={{ root: classes.inputRoot }}
          name="email"
          label="Email"
          onChange={handleChange}
          fullWidth
          required
          onBlur={() =>
            setFormErrors(errors => ({
              ...errors,
              email: !validateEmail(form.email)
            }))
          }
          error={formErrors.email}
          helperText={formErrors.email && 'Please fill out a valid email'}
        />
      </div>
      <div className={classes.row}>
        <TextField
          classes={{ root: classes.inputRoot }}
          name="password"
          label="Password"
          type="password"
          onChange={handleChange}
          required
          onBlur={() =>
            setFormErrors(errors => ({
              ...errors,
              password: size(form.password) < 6
            }))
          }
          error={formErrors.password}
          helperText={formErrors.password && 'Please fill out a valid password'}
        />
        <TextField
          classes={{ root: classes.inputRoot }}
          name="confirmPassword"
          label="Confirm Password"
          type="password"
          onChange={handleChange}
          required
          onBlur={() =>
            setFormErrors(errors => ({
              ...errors,
              confirmPassword:
                size(form.confirmPassword) < 6 ||
                toLower(form.password) !== toLower(form.confirmPassword)
            }))
          }
          error={formErrors.confirmPassword}
          helperText={formErrors.confirmPassword && "Passwords don't match!"}
        />
      </div>
      <div className={classes.actions}>
        <div>
          Already have an account?{' '}
          <span className={classes.login} onClick={props.showLogin}>
            Login
          </span>
        </div>
        <Button
          className={classes.submit}
          disabled={disableForm()}
          type="submit"
          color="primary"
          variant="contained"
        >
          Get started
        </Button>
      </div>
    </form>
  );
}

SignUp.propTypes = {
  onChange: PropTypes.func.isRequired,
  showLogin: PropTypes.func.isRequired,
  signup: PropTypes.func.isRequired
};
