import tinycolor from 'tinycolor2';
import { hexToRgb } from '../assets/jss/material-kit-pro-react';

// Arsenic
const primary = '#444444';
const white = '#ffffff';
const black = '#000000';
// Very Pale Orange
const secondary = '#FFE1BE';
// Sea Shell
const bg = '#FDF6F1';
const destructive = '#d63031';
// Terra Cotta
const warning = '#e17055';
// Bone
const success = '#D3DAC8';
const info = '#AAAAAA';
const text = '#4A4A4A';
const disableGray = '#cccccc';
const portra = '#c9a386';

const lightenRate = 15;
const darkenRate = 15;

export const defaultStyles = {
  palette: {
    primary: {
      main: primary,
      light: tinycolor(primary)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(primary)
        .darken(darkenRate)
        .toHexString()
    },
    secondary: {
      main: secondary,
      light: tinycolor(secondary)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(secondary)
        .darken(darkenRate)
        .toHexString(),
      contrastText: bg
    },
    warning: {
      main: warning,
      light: tinycolor(warning)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(warning)
        .darken(darkenRate)
        .toHexString()
    },
    success: {
      main: success,
      light: tinycolor(success)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(success)
        .darken(darkenRate)
        .toHexString(),
      darker: tinycolor(success)
        .darken(darkenRate * 2)
        .toHexString()
    },
    bg: {
      main: bg,
      light: tinycolor(bg)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(bg)
        .darken(darkenRate)
        .toHexString()
    },
    destructive: {
      main: destructive,
      light: tinycolor(destructive)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(destructive)
        .darken(darkenRate)
        .toHexString()
    },
    white: {
      main: white,
      light: tinycolor(white)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(white)
        .darken(darkenRate)
        .toHexString()
    },
    disableGray: {
      main: disableGray,
      light: tinycolor(disableGray)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(disableGray)
        .darken(darkenRate)
        .toHexString()
    },
    info: {
      main: info,
      light: tinycolor(info)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(info)
        .darken(darkenRate)
        .toHexString()
    },
    text: {
      primary: text,
      secondary: '#6E6E6E',
      hint: '#B9B9B9',
      light: tinycolor(text)
        .darken(lightenRate)
        .toHexString(),
      dark: tinycolor(text)
        .darken(darkenRate)
        .toHexString()
    },
    background: {
      default: white,
      light: white
    },
    black: {
      default: black
    },
    portra: {
      default: portra
    }
  },
  customShadows: {
    widget:
      '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    widgetDark:
      '0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    widgetWide:
      '0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A'
  },
  typography: {
    h1: {
      fontSize: '3rem'
    },
    h2: {
      fontSize: '2rem'
    },
    h3: {
      fontSize: '1.64rem'
    },
    h4: {
      fontSize: '1.5rem'
    },
    h5: {
      fontSize: '1.285rem'
    },
    h6: {
      fontSize: '1.142rem'
    }
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: '#4A4A4A1A'
      }
    },
    MuiButton: {
      root: {
        minHeight: 'auto',
        minWidth: 'auto',
        fontWeight: '400',
        willChange: 'box-shadow, transform',
        transition:
          'box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
        lineHeight: '1.5',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        verticalAlign: 'middle',
        touchAction: 'manipulation',
        cursor: 'pointer',
        '&:hover,&:focus': {
          boxShadow:
            '0 14px 26px -12px rgba(' +
            hexToRgb(info) +
            ', 0.42), 0 4px 23px 0px rgba(' +
            hexToRgb(primary) +
            ', 0.12), 0 8px 10px -5px rgba(' +
            hexToRgb(info) +
            ', 0.2)'
        }
      },
      containedSecondary: {
        color: primary,
        fontSize: 'inherit'
      }
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': ['proxima-nova']
      }
    },
    MuiMenu: {
      paper: {
        boxShadow:
          '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A'
      }
    },
    MuiSelect: {
      icon: {
        color: '#B9B9B9'
      }
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: 'white'
      }
    },
    MuiTableRow: {
      root: {
        height: 56
      }
    },
    MuiTableCell: {
      root: {
        borderBottom: '1px solid rgba(224, 224, 224, .5)'
      },
      head: {
        fontSize: '0.95rem'
      },
      body: {
        fontSize: '0.95rem'
      }
    }
  }
};

export const overrides = {
  shadows: Array(25).fill('none'),
  shape: {
    borderRadius: 4
  }
};
