import cx from 'classnames';
import { find, get } from 'lodash';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import LinkedList from '../../lib/utils/linked-list';

import { makeStyles } from '@material-ui/core/styles';
import ButtonGroup from '@material-ui/core/ButtonGroup';

const useStyles = makeStyles(theme => ({
  action: {
    alignItems: 'center',
    flex: '1',
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0px',
      marginTop: '1px',
      width: '100%'
    }
  },
  actions: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    height: '100px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      height: '300px'
    }
  },
  nav: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    width: '100px',
    '&:hover': {
      background: theme.palette.primary.light
    },
    '& > svg': {
      height: '50px',
      width: '50px'
    }
  },
  container: {
    background: 'rgba(0,0,0,0.9)',
    display: 'flex',
    height: '100%',
    left: 0,
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: '9999'
  },
  image: {
    alignSelf: 'flex-start',
    maxHeight: '100%',
    maxWidth: '100%'
  },
  imageContainer: {
    display: 'flex',
    flex: '1',
    height: '0px',
    justifyContent: 'space-around',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  main: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column'
  },
  mobileHide: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  mobileNav: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flex: '1',
      width: '100%'
    }
  }
}));

export default function Gallery(props) {
  const classes = useStyles();
  const linkedImageList = new LinkedList();
  props.images.forEach(img => linkedImageList.add(img));
  const [image, setImage] = useState(
    find(linkedImageList.values(), link => link.data.id === props.image.id)
  );

  function download(resolution = 'midRes') {
    const link = document.createElement('a');
    link.href = image.data[resolution].read;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function onKeyDown(e) {
    if (e.keyCode === 37) {
      const prevImg = image.prev;
      setImage(prevImg);
      props.onChangeImage(prevImg.data);
    } else if (e.keyCode === 39) {
      const nextImg = image.next;
      setImage(nextImg);
      props.onChangeImage(nextImg.data);
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [get(image, 'data.id')]);

  return (
    <div className={classes.container} onClick={props.onClose}>
      <div
        className={cx(classes.nav, classes.mobileHide)}
        onClick={e => {
          e.stopPropagation();
          const prevImg = image.prev;
          setImage(prevImg);
          props.onChangeImage(prevImg.data);
        }}
      >
        <ArrowBackIosIcon />
      </div>
      <div className={classes.main}>
        <div className={classes.imageContainer}>
          <img src={image.data.midRes.read} className={classes.image} />
        </div>
        <div className={classes.actions}>
          <ButtonGroup
            color="primary"
            variant="contained"
            className={classes.mobileNav}
          >
            <Button
              onClick={e => {
                e.stopPropagation();
                const prevImg = image.prev;
                setImage(prevImg);
                props.onChangeImage(prevImg.data);
              }}
              className={classes.action}
            >
              Prev
            </Button>
            <Button
              className={classes.action}
              onClick={e => {
                e.stopPropagation();
                const nextImg = image.next;
                setImage(nextImg);
                props.onChangeImage(nextImg.data);
              }}
            >
              Next
            </Button>
          </ButtonGroup>
          <Button
            color="primary"
            variant="contained"
            className={classes.action}
            onClick={e => {
              e.stopPropagation();
              download();
            }}
          >
            Download
          </Button>
          <Button
            color="primary"
            variant="contained"
            className={cx(classes.action)}
            onClick={e => {
              e.stopPropagation();
              image.data.hiRes
                ? download('hiRes')
                : props.onUnlock4k(image.data);
            }}
          >
            {image.data.hiRes ? 'Download 4k' : 'Unlock 4k'}
          </Button>
          <Button
            color="primary"
            variant="contained"
            className={cx(classes.action)}
            onClick={e => {
              e.stopPropagation();
              image.data.tiff
                ? download('tiff')
                : props.onUnlockTiff(image.data);
            }}
          >
            {image.data.tiff ? 'Download TIFF' : 'Unlock TIFF'}
          </Button>
          <Button
            color="primary"
            variant="contained"
            className={classes.action}
            onClick={e => {
              e.stopPropagation();
              props.onClose();
            }}
          >
            Close
          </Button>
        </div>
      </div>
      <div
        className={cx(classes.nav, classes.mobileHide)}
        onClick={e => {
          e.stopPropagation();
          const nextImg = image.next;
          setImage(nextImg);
          props.onChangeImage(nextImg.data);
        }}
      >
        <ArrowForwardIosIcon />
      </div>
    </div>
  );
}

Gallery.propTypes = {
  image: PropTypes.shape({}).isRequired,
  images: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onClose: PropTypes.func.isRequired,
  onChangeImage: PropTypes.func,
  onUnlock4k: PropTypes.func.isRequired,
  onUnlockTiff: PropTypes.func.isRequired
};
