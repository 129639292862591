import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Checkbox } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  checkboxRoot: {
    padding: '0px',
    '& svg': {
      height: '.7em',
      width: '.7em'
    }
  },
  container: {
    backgroundColor: theme.palette.black.default,
    border: `1px solid ${theme.palette.black.default}`
  },
  data: {
    margin: '0px 4px',
    textTransform: 'uppercase'
  },
  dataBorder: {
    alignItems: 'center',
    backgroundColor: theme.palette.black.default,
    color: theme.palette.portra.default,
    display: 'flex',
    fontSize: '9px',
    justifyContent: 'space-between',
    lineHeight: '1.7em',
    padding: '0px 2px',
    whiteSpace: 'nowrap',
    width: '100%'
  },
  img: {
    maxHeight: '100%',
    maxWidth: '100%'
  },
  scan: {
    alignItems: 'center',
    background: theme.palette.black.default,
    border: props =>
      `3px solid ${
        props.isSelected
          ? theme.palette.destructive.main
          : theme.palette.black.default
      }`,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-around',
    height: '99px',
    position: 'relative',
    width: '148px',
    [theme.breakpoints.down('sm')]: {
      height: '230px',
      width: '345px'
    }
  }
}));

function RollThumbnail(props) {
  const {
    filmRoll: { model, brand }
  } = props.roll;

  const [checked, setChecked] = useState(props.isSelected);
  const classes = useStyles({ isSelected: checked });

  useEffect(() => {
    if (checked !== !!props.isSelected) setChecked(props.isSelected);
  }, [props.isSelected]);

  return (
    <div className={classes.container}>
      <div className={classes.dataBorder}>
        <span className={classes.data}>
          {props.index % 2 === 1 ? `${brand} ${model}` : '\u25BA'}
        </span>
        <Checkbox
          classes={{ root: classes.checkboxRoot }}
          color="secondary"
          checked={checked}
          onClick={e => {
            e.stopPropagation();
            e.preventDefault();
            setChecked(!checked);
            props.onSelect(state => ({
              ...state,
              [props.scan.id]: !state[props.scan.id]
            }));
          }}
        />
      </div>
      <div
        className={classes.scan}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          props.openImage(props.scan);
        }}
      >
        <img src={get(props.scan, 'thumbnail.read')} className={classes.img} />
      </div>
      <div className={classes.dataBorder}>
        <span className={classes.data}>{props.index}</span>
        <span className={classes.data}>
          {props.scan.tiff ? 'TIF' : props.scan.hiRes ? '4K' : 'HD'}
        </span>
      </div>
    </div>
  );
}

RollThumbnail.propTypes = {
  index: PropTypes.number.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  openImage: PropTypes.func.isRequired,
  roll: PropTypes.shape({}).isRequired,
  scan: PropTypes.shape({}).isRequired
};

export default React.memo(RollThumbnail);
