import React from 'react';
import { connect } from 'react-redux';
import UsersPage from '../../views/UsersPage/UsersPage';
import {
  loadingUsersSelector,
  usersErrorSelector,
  usersSelector
} from './selectors';
import { actions } from './slice';
import { actions as pickUpActions } from '../PickUps/slice';
import {
  availabilitiesSelector,
  loadingPickUpsSelector,
  pickUpSelector,
  pickUpsErrorSelector
} from '../PickUps/selectors';
import promisifyActions from '../../lib/utils/promisify-actions';

const Users = props => {
  return <UsersPage {...props} />;
};

/**
 * The admin state to be provided to the admin component
 *
 * @param {object} state global state
 */
const mapStateToProps = state => ({
  loading: loadingUsersSelector(state),
  error: usersErrorSelector(state),
  users: usersSelector(state),
  loadingPickUps: loadingPickUpsSelector(state),
  pickUp: pickUpSelector(state),
  pickUpsError: pickUpsErrorSelector(state),
  availabilities: availabilitiesSelector(state)
});

/**
 * Actions to be dispatched
 *
 * @param {function} dispatch function to dispatch admin adminActions
 */
const mapDispatchToProps = dispatch => ({
  addPickUp: pickUp => dispatch(pickUpActions.addPickUp({ pickUp })),
  updatePickUp: pickUp => dispatch(pickUpActions.updatePickUp({ pickUp })),
  getUsers: params => dispatch(actions.getUsers({ params })),
  getAvailabilities: (userId, params) =>
    dispatch(pickUpActions.getAvailabilities({ userId, params }))
});

/**
 * Connects admin state to the App component
 *
 * @param {object} mapStateToProps admin data object to become props for the App component
 * @param {object} mapDispatchToProps admin adminActions to be dispatched to reducer
 */
export default connect(
  mapStateToProps,
  promisifyActions(mapDispatchToProps)
)(Users);
