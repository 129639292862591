import axios from 'axios';
import { chain, size, isEmpty, some } from 'lodash';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import querystring from 'querystring';

import { makeStyles } from '@material-ui/core/styles';
// core components
import GridContainer from '../../components/Grid/GridContainer.js';
import GridItem from '../../components/Grid/GridItem.js';
import Button from '../../components/CustomButtons/Button.js';

// Sections for this page
import Onboarding from '../../containers/Onboarding/Onboarding';
import LandingHeader from '../../components/LandingHeader/LandingHeader';
import AppFooter from '../../components/AppFooter/AppFooter';
import Typography from '@material-ui/core/Typography';
import LandingBG from '../../assets/img/landing_bg.jpg';
import TextField from '@material-ui/core/TextField';
import validateEmail from '../../lib/utils/Form/validate-email';

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: 'bold'
  },
  form: {
    height: '210px',
    width: '100%'
  },
  hero: {
    alignItems: 'center',
    display: 'flex',
    flex: '1',
    justifyContent: 'space-around',
    margin: 'auto',
    maxWidth: '540px',
    minHeight: '400px',
    padding: '0px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse'
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '720px'
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '910px'
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '1215px'
    }
  },
  heroBody: {
    marginTop: '15px'
  },
  heroLeft: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      flex: '1'
    }
  },
  heroRight: {
    [theme.breakpoints.down('xs')]: {
      flex: '1',
      maxHeight: '150px',
      maxWidth: '50%',
      justifyContent: 'space-around',
      display: 'flex'
    }
  },
  img: {
    maxWidth: '100%',
    transform: 'rotate(12deg) translate(10px, -3px)',
    [theme.breakpoints.down('xs')]: {
      height: '100%'
    }
  },
  inputRoot: {
    marginRight: theme.spacing(1),
    minHeight: '70px'
  },
  instagramHandle: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    '&:visited': {
      color: theme.palette.primary.main,
      fontWeight: 'bold'
    }
  },
  join: {
    margin: '0px'
  },
  joined: {
    height: '210px'
  },
  list: {
    fontSize: '14px'
  },
  root: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    paddingTop: '50px'
  }
}));

export default function LandingPage(props) {
  const [login, setLogin] = useState(false);
  const [form, setForm] = useState(props.waitlistUser);
  const [formErrors, setFormErrors] = useState({});
  const [openModel, setOpenModal] = useState(false);
  const [addingUser, setAddingUser] = useState(false);
  const classes = useStyles();

  function handleChange(e) {
    e.persist();
    setForm(inputs => ({ ...inputs, [e.target.name]: e.target.value }));
  }

  function getIPAddress() {
    return axios
      .get('https://www.cloudflare.com/cdn-cgi/trace')
      .then(({ data }) =>
        chain(data)
          .split('\n')
          .reduce((total, kevVal) => {
            const [key, val] = kevVal.split('=');
            if (key === 'ip') total['ipAddress'] = val;
            if (key === 'loc') total['country'] = val;
            return total;
          }, {})
          .value()
      )
      .catch(() => ({}));
  }

  async function addWaitlistUser() {
    setAddingUser(true);

    const locationDetails = await getIPAddress();

    props
      .addWaitlistUser({ ...form, ...locationDetails })
      .then(({ user }) => {
        if (window.gtag && process.env.REACT_APP_ENVIRONMENT === 'production') {
          window.gtag('config', 'GA_MEASUREMENT_ID', {
            user_id: user.email
          });
        }

        setForm(f => ({ ...f, ...user }));
      })
      .catch(console.log)
      .finally(() => setAddingUser(false));
  }

  const register = chain(props.location)
    .get('search', '')
    .slice(1)
    .invoke(querystring.decode)
    .get('register', false)
    .value();

  const disableJoin = some([
    addingUser,
    !!form.id,
    !chain(formErrors)
      .values()
      .filter()
      .isEmpty()
      .value(),
    !form.zip,
    !form.email
  ]);

  return (
    <div className={classes.root}>
      <LandingHeader
        onLogin={() => {
          setLogin(true);
          setOpenModal(true);
        }}
      />
      <GridContainer className={classes.hero}>
        <GridItem xs={12} sm={6} md={6} className={classes.heroLeft}>
          <Typography variant="h1">
            Subscription <br /> Film Photography
          </Typography>
          <Typography variant="body1" className={classes.heroBody}>
            darklab streamlines your film photography with a simple subscription
            that <span className={classes.bold}>includes</span>:
          </Typography>
          <ul className={classes.list}>
            <li>Film roll pickups directly from your door</li>
            <li>Color, B/W, and E6 film development (35mm & medium format)</li>
            <li>Professional HD scans</li>
          </ul>

          {form.id && (
            <Typography variant="body1" className={classes.joined}>
              Thank you for joining! You’re #{300 + form.id} on the waitlist, we
              will reach out as soon as we can service you! In the meantime -
              make sure to stay up to date by following us instagram
              {` `}
              <a
                className={classes.instagramHandle}
                href="https://www.instagram.com/darklabsubscription"
                target="_blank"
              >
                @darklabsubscription
              </a>
              .
            </Typography>
          )}
          {!form.id && (
            <form className={classes.form}>
              <TextField
                fullWidth
                classes={{ root: classes.inputRoot }}
                name="email"
                label="Email"
                onChange={handleChange}
                required
                onBlur={() =>
                  setFormErrors(errors => ({
                    ...errors,
                    email: !validateEmail(form.email)
                  }))
                }
                error={formErrors.email}
                helperText={formErrors.email && 'Please fill out a valid email'}
              />
              <TextField
                fullWidth
                classes={{ root: classes.inputRoot }}
                name="zip"
                label="Zip or Postal Code"
                onChange={handleChange}
                required
                onBlur={() =>
                  setFormErrors(errors => ({
                    ...errors,
                    zip: !size(form.zip)
                  }))
                }
                error={formErrors.zip}
                helperText={
                  formErrors.zip && 'Please fill out a valid zip/postal code'
                }
              />
              <TextField
                fullWidth
                classes={{ root: classes.inputRoot }}
                name="instagram"
                label="Instagram (e.g. @darklabsubscription)"
                onChange={handleChange}
              />
            </form>
          )}
          <Button
            onClick={() =>
              register ? setOpenModal(true) : addWaitlistUser(form)
            }
            color="primary"
            size="lg"
            className={classes.join}
            disabled={disableJoin}
          >
            Join
          </Button>
        </GridItem>
        <GridItem xs={12} sm={6} md={6} className={classes.heroRight}>
          <img src={LandingBG} className={classes.img} />
        </GridItem>
      </GridContainer>

      <Onboarding
        {...props}
        showLogin={login}
        open={openModel}
        onClose={() => {
          setLogin(false);
          setOpenModal(false);
        }}
      />

      <AppFooter />
    </div>
  );
}

LandingPage.propTypes = {
  addWaitlistUser: PropTypes.func.isRequired,
  location: PropTypes.shape({}).isRequired,
  waitlistUser: PropTypes.shape({}).isRequired
};
