import React from 'react';
import { connect } from 'react-redux';
import {
  pickUpSelector,
  pickUpsErrorSelector,
  productsSelector
} from './selectors';
import { actions } from './slice';
import { actions as userActions } from '../Users/slice';
import ReceivePickUpPage from '../../views/ReceivePickUpPage/ReceivePickUpPage';
import promisifyActions from '../../lib/utils/promisify-actions';

const ReceivePickUp = props => {
  return <ReceivePickUpPage {...props} />;
};

/**
 * The admin state to be provided to the admin component
 *
 * @param {object} state global state
 */
const mapStateToProps = state => ({
  error: pickUpsErrorSelector(state),
  pickUp: pickUpSelector(state),
  products: productsSelector(state)
});

/**
 * Actions to be dispatched
 *
 * @param {function} dispatch function to dispatch admin adminActions
 */
const mapDispatchToProps = dispatch => ({
  getPickUpProducts: () => dispatch(actions.getPickUpProducts()),
  getPickUp: (userId, pickUpId) =>
    dispatch(userActions.getUserPickUp({ userId, pickUpId })),
  updatePickUp: pickUp => dispatch(actions.updatePickUp({ pickUp }))
});

/**
 * Connects admin state to the App component
 *
 * @param {object} mapStateToProps admin data object to become props for the App component
 * @param {object} mapDispatchToProps admin adminActions to be dispatched to reducer
 */
export default connect(
  mapStateToProps,
  promisifyActions(mapDispatchToProps)
)(ReceivePickUp);
