import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import AdminContext from '../../contexts/AdminContext';
import AdminHeader from '../../components/AdminHeader/AdminHeader';

import styles from '../../assets/jss/material-kit-pro-react/views/adminPageStyle';

const useStyles = makeStyles(theme => ({
  ...styles,
  root: {
    width: '100%'
  }
}));

export default function AdminPage(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AdminHeader {...props} />
      <AdminContext.Provider value={{ isAdmin: true }}>
        <div className={classes.container}>{props.children}</div>
      </AdminContext.Provider>
    </div>
  );
}

AdminPage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};
