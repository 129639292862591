/**
 * App saga functions
 */
import { call, put, takeLatest } from 'redux-saga/effects';
import { handleError } from '../../lib/utils/saga-utils';
import { actions } from './slice';
import AuthAPI from '../../lib/Api/Auth';
import WaitlistAPI from "../../lib/Api/Waitlist";

/**
 * Fetches the current user and dispatches success or error.
 */
export function* getCurrentUser() {
  try {
    const response = yield call(AuthAPI.getCurrentUser);
    yield put(actions.getCurrentUserSuccess(response));
  } catch (err) {
    yield handleError(actions.getCurrentUserError, err);
  }
}

export function* signup({ payload: { userData, resolve } }) {
  try {
    const response = yield call(AuthAPI.signup, userData);
    yield put(actions.signupSuccess(response));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.signupError, err);
  }
}

export function* login({ payload: { userData, resolve } }) {
  try {
    const response = yield call(AuthAPI.login, userData);
    yield put(actions.loginSuccess(response));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.loginError, err);
  }
}

export function* addWaitlistUser({ payload: { userData, resolve, reject } }) {
  try {
    const response = yield call(WaitlistAPI.add, userData);
    yield put(actions.addWaitlistUserSuccess(response));
    yield call(resolve, response);
  } catch (err) {
    yield handleError(actions.addWaitlistUserError, err, reject);
  }
}

/**
 * Gets the response object from the latest request fired
 * to get the current user.
 */
function* appSaga() {
  yield takeLatest(actions.addWaitlistUser.type, addWaitlistUser);
  yield takeLatest(actions.getCurrentUser.type, getCurrentUser);
  yield takeLatest(actions.signup.type, signup);
  yield takeLatest(actions.login.type, login);
}

export default appSaga;
