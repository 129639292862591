/**
 * This file specifies the state of the application in response to App actions
 */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  creatingSubscription: false,
  createSubscriptionError: null,
  subscriptionCreated: false
};

const slice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    createSubscription: state => ({ ...state, creatingSubscription: true }),
    createSubscriptionError: (state, action) => ({
      ...state,
      creatingSubscription: false,
      createSubscriptionError: action.payload.error
    }),
    createSubscriptionSuccess: state => ({
      ...state,
      creatingSubscription: false,
      createSubscriptionError: null,
      subscriptionCreated: true
    }),
    getCurrentUser: state => ({ ...state, gettingCurrentUser: true }),
    getCurrentUserError: (state, action) => ({
      ...state,
      gettingCurrentUser: false,
      getCurrentUserError: action.payload.error
    }),
    getCurrentUserSuccess: (state, action) => ({
      ...state,
      gettingCurrentUser: false,
      currentUser: action.payload.user
    })
  }
});

export const { reducer, actions } = slice;

export default reducer;
