import querystring from 'querystring';

import Api from '../api';

const Product = '/products';

const ProductAPI = {
  getDigitalProducts() {
    return Api.get(`${Product}/digital`);
  },
  
  getPickUpProducts() {
    return Api.get(`${Product}/pick-up`);
  }
};

export default ProductAPI;
