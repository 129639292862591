import { chain } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  overlay: {
    alignItems: 'center',
    background: 'rgba(0,0,0,0.55)',
    display: 'flex',
    cursor: 'pointer',
    height: '100%',
    justifyContent: 'space-between',
    padding: '10px',
    position: 'absolute',
    visibility: 'hidden',
    width: '100%'
  },
  overlayAction: {
    fill: theme.palette.bg.main,
    '&:hover': {
      fill: theme.palette.bg.dark
    }
  },
  overlayCheckbox: {
    padding: '0px'
  },
  overlayActionsRight: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  },
  overlayActionsLeft: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  },
  thumb: {
    alignItems: 'center',
    border: `2px solid ${theme.palette.white.main}`,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-around',
    height: '80px',
    position: 'relative',
    width: '120px',
    '& > div': {
      visibility: props => (props.isSelected ? 'visible' : 'hidden')
    },
    '&:hover': {
      border: `2px solid ${theme.palette.bg.main}`,
      '& > div': {
        visibility: 'visible'
      }
    },
    [theme.breakpoints.down('xs')]: {
      '& > div': {
        visibility: 'visible'
      }
    }
  },
  thumbContainer: {
    alignItems: 'center',
    background: theme.palette.white.main,
    display: 'flex',
    flexDirection: 'column',
    left: '-65px',
    position: 'absolute',
    transform: props => `translateX(${props.x}px)`,
    transition: 'all 1s ease-in-out',
    visibility: props => (props.visible ? 'visible' : 'hidden'),
    [theme.breakpoints.down('xs')]: {
      left: '-6px'
    }
  },
  thumbData: {
    margin: '0px 4px',
    textTransform: 'uppercase'
  },
  thumbImage: {
    alignSelf: 'center',
    maxHeight: '100%',
    maxWidth: '100%'
  },
  thumbNav: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    '&:hover': {
      '& > svg': {
        fill: theme.palette.primary.dark
      }
    },
    [theme.breakpoints.down('sm')]: {
      flex: '1',
      justifyContent: 'space-around'
    }
  }
}));

function RollSummaryThumb(props) {
  const classes = useStyles(props);
  const thumbId = chain(props.thumb.data)
    .get('id')
    .split('_')
    .first()
    .toInteger()
    .value();

  return (
    <div
      className={classes.thumbContainer}
      onClick={e => {
        e.stopPropagation();
        if (props.isPrev) return props.setPrevThumbs();
        if (props.isNext) return props.setNextThumbs();
        props.openImage({
          ...props.thumb.data,
          id: thumbId
        });
      }}
    >
      <div className={classes.thumb}>
        <div className={classes.overlay}>
          <div className={classes.overlayActionsLeft}></div>
          <div className={classes.overlayActionsRight}>
            <Checkbox
              color="secondary"
              checked={props.isSelected}
              onChange={e => {
                e.stopPropagation();
                e.preventDefault();
              }}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                props.setSelected(state => ({
                  ...state,
                  [thumbId]: !state[thumbId],
                  [props.thumb.data.id]: !state[props.thumb.data.id]
                }));
              }}
              className={classes.overlayCheckbox}
            />
          </div>
        </div>
        <img
          className={classes.thumbImage}
          src={props.thumb.data.thumbnail.read}
        />
      </div>
    </div>
  );
}

RollSummaryThumb.propTypes = {
  setNextThumbs: PropTypes.func.isRequired,
  setPrevThumbs: PropTypes.func.isRequired,
  openImage: PropTypes.func.isRequired,
  setSelected: PropTypes.func.isRequired,
  thumb: PropTypes.shape({
    data: PropTypes.shape({
      thumbnail: PropTypes.shape({
        read: PropTypes.string
      }),
      hiRes: PropTypes.shape({
        read: PropTypes.string
      }),
      tiff: PropTypes.shape({
        read: PropTypes.string
      })
    })
  }).isRequired,
  visible: PropTypes.bool.isRequired
};

export default React.memo(RollSummaryThumb);
