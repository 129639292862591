import React from 'react';
import UserAddresses from './Sections/UserAddresses';
import UserDetails from './Sections/UserDetails';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      marginTop: '100px'
    }
  }
}));

export default function UserSettingsPage(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {props.user && <UserDetails {...props} />}
      {props.user && <UserAddresses {...props} />}
    </div>
  );
}
