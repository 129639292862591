import { get, filter, size, chain } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import DateRangePicker from '../../../components/DateRangePicker/DateRangePicker';

import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { IconButton, Paper } from '@material-ui/core';
import LoadingText from '../../../components/LoadingText/LoadingText';
import PickUpWindowCard from '../../../components/PickUpWindowCard/PickUpWindowCard';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';

const styles = theme => ({
  checkCircle: {
    fill: theme.palette.success.dark,
    marginLeft: '10px'
  },
  container: {
    flex: '1',
    margin: '5px'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  headerText: {
    alignItems: 'center',
    display: 'flex'
  },
  paper: {
    backgroundColor: 'transparent'
  },
  selectedWindow: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between'
  },
  selectedWindowDetails: {
    fontWeight: 'bold'
  },
  windows: {
    height: '180px',
    overflow: 'auto'
  }
});
const useStyles = makeStyles(styles);

export default function PickUpWindowSelector(props) {
  const classes = useStyles();
  const { address, user } = props;
  const [from, setFrom] = useState(
    props.pickUp.window
      ? moment(props.pickUp.date).startOf('isoWeek')
      : moment().add(1, 'days')
  );
  const [to, setTo] = useState(from.clone().endOf('isoWeek'));
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const zip = address.zip;
    setLoading(true);
    props
      .getAvailabilities(user.id, {
        from: from.toDate().toISOString(),
        to: to.toDate().toISOString(),
        zip
      })
      .finally(() => setLoading(false));
  }, [from, to, get(address, 'id')]);

  function onDateChange(from, to) {
    setFrom(from);
    setTo(to);
  }

  function getDate(day) {
    return from
      .clone()
      .startOf('isoWeek')
      .add(day, 'days');
  }

  const availabilities = filter(
    props.availabilities,
    a => !getDate(a.weekday).isBefore(from)
  );

  const windows = chain(availabilities)
    .sortBy(window => {
      return getDate(window.weekday)
        .add(window.start, 'minutes')
        .toDate();
    })
    .map(window => (
      <PickUpWindowCard
        key={window.selected ? size(availabilities) + 1000 : window.id}
        window={window}
        date={
          window.selected
            ? moment(props.pickUp.date).startOf('day')
            : getDate(window.weekday)
        }
        selected={
          props.pickUp.messengerHoursId === window.id &&
          getDate(window.weekday)
            .add(window.start, 'minutes')
            .isSame(moment(props.pickUp.date))
        }
        onSelect={w => {
          const date = getDate(w.weekday).add(w.start, 'minutes');

          props.onSelect({
            date,
            messengerId: w.messengerId,
            messengerHoursId: w.id,
            window
          });
        }}
      />
    ))
    .value();

  return (
    <div className={classes.container}>
      <h4 className={classes.header}>
        <span className={classes.headerText}>
          2. Select a Pick Up Window{' '}
          {props.pickUp.messengerHoursId && (
            <CheckCircle className={classes.checkCircle} />
          )}
        </span>
      </h4>
      <Divider />
      {props.pickUp.window ? (
        <Paper className={classes.paper}>
          <div className={classes.selectedWindow}>
            <div className={classes.selectedWindowDetails}>
              {moment(props.pickUp.date)
                .startOf('day')
                .add(props.pickUp.window.start, 'minutes')
                .format('LLLL')}
            </div>
            <IconButton
              color="primary"
              className={classes.action}
              onClick={() =>
                props.onSelect({
                  date: null,
                  messengerId: null,
                  messengerHoursId: null,
                  window: null
                })
              }
            >
              <EditIcon />
            </IconButton>
          </div>
        </Paper>
      ) : (
        <Paper className={classes.paper}>
          <DateRangePicker from={from} to={to} onChange={onDateChange} />

          {loading && <LoadingText text="Loading Availabilities" />}

          {!loading && size(windows) > 0 && (
            <div className={classes.windows}>{windows}</div>
          )}

          {!loading && size(windows) === 0 && (
            <div>
              Looks like there aren't any availabilities this week, please
              select another week
            </div>
          )}
        </Paper>
      )}
    </div>
  );
}

PickUpWindowSelector.propTypes = {
  availabilities: PropTypes.arrayOf(PropTypes.shape({})),
  getAvailabilities: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  pickUp: PropTypes.shape({}).isRequired,
  user: PropTypes.shape({}).isRequired
};
