import { createSelector } from 'reselect';

const selector = state => state.users;

export const loadingUsersSelector = createSelector(
  selector,
  users => users.loading
);

export const usersErrorSelector = createSelector(
  selector,
  users => users.error
);

export const userSelector = createSelector(
  selector,
  users => users.user
);

export const usersSelector = createSelector(
  selector,
  users => users.users
);

export const userPickUpsSelector = createSelector(
  selector,
  users => users.pickUps
);

export const userPickUpsCountSelector = createSelector(
  selector,
  users => users.pickUpsCount
);

export const productsSelector = createSelector(
  selector,
  users => users.products
);
