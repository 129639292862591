import cx from 'classnames';
import { get, keyBy, chain, flatten, toLower, map } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import querystring from 'querystring';

import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
  bold: {
    fontWeight: 'bold'
  },
  col1: {
    flex: '1'
  },
  col2: {
    flex: '2'
  },
  col3: {
    flex: '3'
  },
  container: {
    padding: '15px',
    width: '100%'
  },
  rightAlign: {
    textAlign: 'right'
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 'bold',
    height: '50px'
  },
  rows: {
    margin: '15px 0px'
  },
  section: {
    margin: '30px 0px',
    width: '100%'
  },
  sectionHeader: {
    alignItems: 'baseline',
    display: 'flex',
    justifyContent: 'space-between'
  },
  signature: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    display: 'flex',
    '& > div': {
      marginLeft: '15px'
    }
  }
}));

const getMessengerId = location => {
  return chain(location)
    .get('pathname')
    .split('/')
    .at(3)
    .value();
};

const setInitialState = search => {
  const queryParams = querystring.decode(search);
  return {
    from: moment(queryParams.from)
      .startOf('day')
      .toISOString(),
    to: moment(queryParams.to)
      .endOf('day')
      .toISOString()
  };
};

export default function PrintableRoutesPage(props) {
  const classes = useStyles();
  const [messengerId] = useState(getMessengerId(props.location));
  const [params] = useState(setInitialState(props.location.search.slice(1)));

  useEffect(() => {
    props.getMessenger(messengerId);
    props.getPickUpProducts();
  }, [messengerId]);

  useEffect(() => {
    const { history, location } = props;
    const { from, to } = params;

    history.push({ ...location, search: querystring.encode(params) });
    props.getRoutes(messengerId, from, to);
  }, [params]);

  function formatName(roll) {
    const type = get(roll, 'native_process');
    const name = get(roll, 'name');
    return toLower(type) === 'e-6' ? `${name} (E6 + $5.99)` : name;
  }

  function makeOptions(filmRoll = {}, userRoll) {
    const cpOptionsById = keyBy(filmRoll.crossProcessOptions, 'id');
    const ppOptionsById = keyBy(filmRoll.pushPullOptions, 'id');

    return chain([
      chain(cpOptionsById)
        .get(userRoll.crossProccessId)
        .thru(v => (v ? `Cross Process as ${v.target_format}` : null))
        .value(),
      chain(ppOptionsById)
        .get(userRoll.pushPullId)
        .thru(v => (v ? `${v.factor < 0 ? 'Pull' : 'Push'} ${v.factor}` : null))
        .value()
    ])
      .compact()
      .join(', ')
      .value();
  }

  const productsByRollId = keyBy(map(props.products, 'filmRoll'), 'id');

  return (
    <div className={classes.container}>
      {flatten(props.routes).map(pickUp => (
        <div key={get(pickUp, 'pickUp.id')} className={classes.section}>
          <div className={classes.sectionHeader}>
            <Typography variant="h5">
              Messenger: {get(props.messenger, 'firstName')}
              {` `}
              {get(props.messenger, 'lastName')}
              <br />
              Order ID: {get(pickUp, 'pickUp.id')}
              <br />
              Customer ID: {get(pickUp, 'customer.id')}
            </Typography>
            <Typography variant="h5">
              {moment(get(pickUp, 'pickUp.date')).format('L')}
            </Typography>
          </div>
          <div className={classes.rows}>
            <div className={classes.row}>
              <div className={classes.col1}>Dark Lab ID</div>
              <div className={classes.col3}>Roll</div>
              <div className={classes.col1}>ISO</div>
              <div className={classes.col1}>Format</div>
              <div className={classes.col2}>Options</div>
              <div className={cx(classes.col1, classes.rightAlign)}>
                Lab Roll ID
              </div>
            </div>
            {get(pickUp, 'rolls').map(roll => (
              <div key={roll.id} className={classes.row}>
                <div className={classes.col1}>{roll.id}</div>
                <div className={classes.col3}>
                  {formatName(get(productsByRollId, roll.filmRollId))}
                </div>
                <div className={classes.col1}>
                  {get(productsByRollId, `${roll.filmRollId}.iso`)}
                </div>
                <div className={classes.col1}>
                  {get(productsByRollId, `${roll.filmRollId}.format`)}
                </div>
                <div className={classes.col2}>
                  {makeOptions(
                    get(productsByRollId, `${roll.filmRollId}`),
                    roll
                  )}
                </div>
                <div className={cx(classes.col1, classes.rightAlign)}>
                  ___________
                </div>
              </div>
            ))}
          </div>
          <div className={classes.signature}>
            <div>
              <span className={classes.bold}>Signature:</span>
              _______________________________________________________________________
            </div>
            <div>
              <span className={classes.bold}>Date</span>: __________
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

PrintableRoutesPage.propTypes = {
  getMessenger: PropTypes.func.isRequired,
  getPickUpProducts: PropTypes.func.isRequired,
  getRoutes: PropTypes.func.isRequired,
  messenger: PropTypes.shape({}),
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};
