/**
 * This file specifies the state of the application in response to App actions
 */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  error: null,
  pickUp: null,
  pickUps: [],
  products: [],
  count: 0,
  availabilities: []
};

const slice = createSlice({
  name: 'pickUps',
  initialState,
  reducers: {
    getAvailabilities: state => ({ ...state, loading: true }),
    getAvailabilitiesError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error
    }),
    getAvailabilitiesSuccess: (state, action) => ({
      ...state,
      loading: false,
      error: null,
      availabilities: action.payload.availabilities
    }),
    addPickUp: state => ({ ...state, loading: true }),
    addPickUpError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error
    }),
    addPickUpSuccess: (state, action) => ({
      ...state,
      loading: false,
      error: null,
      pickUp: action.payload.pickUp,
      pickUps: [action.payload.pickUp].concat(state.pickUps)
    }),
    getPickUp: state => ({ ...state, loading: true }),
    getPickUpError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error
    }),
    getPickUpSuccess: (state, action) => ({
      ...state,
      loading: false,
      error: null,
      pickUp: action.payload.pickUp
    }),
    getPickUps: state => ({ ...state, loading: true }),
    getPickUpsError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error
    }),
    getPickUpsSuccess: (state, action) => ({
      ...state,
      loading: false,
      error: null,
      pickUps: action.payload.pickUps,
      count: action.payload.count
    }),
    updatePickUp: state => ({ ...state, loading: true }),
    updatePickUpError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error
    }),
    updatePickUpSuccess: (state, action) => {
      const { pickUp } = action.payload;
      const pickUps = state.pickUps.map(p => (p.id === pickUp.id ? pickUp : p));

      return {
        ...state,
        loading: false,
        error: null,
        pickUp,
        pickUps
      };
    },
    addFilmRoll: state => ({ ...state, loading: true }),
    addFilmRollError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error
    }),
    addFilmRollSuccess: (state, action) => {
      const { pickUp } = action.payload;
      const pickUps = state.pickUps.map(p => (p.id === pickUp.id ? pickUp : p));

      return {
        ...state,
        loading: false,
        error: null,
        pickUp,
        pickUps
      };
    },
    updateFilmRoll: state => ({ ...state, loading: true }),
    updateFilmRollError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error
    }),
    updateFilmRollSuccess: (state, action) => {
      const { pickUp } = action.payload;
      const pickUps = state.pickUps.map(p => (p.id === pickUp.id ? pickUp : p));

      return {
        ...state,
        loading: false,
        error: null,
        pickUp,
        pickUps
      };
    },
    addFilmRollScan: state => ({ ...state, loading: true }),
    addFilmRollScanError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error
    }),
    addFilmRollScanSuccess: (state, action) => {
      const { roll } = action.payload;
      const rolls = state.pickUp.rolls.map(r => (r.id === roll.id ? roll : r));

      return {
        ...state,
        loading: false,
        error: null,
        pickUp: {
          ...state.pickUp,
          rolls
        }
      };
    },
    removeFilmRollScan: state => ({ ...state, loading: true }),
    removeFilmRollScanError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error
    }),
    removeFilmRollScanSuccess: (state, action) => {
      const { pickUp } = action.payload;

      return {
        ...state,
        loading: false,
        error: null,
        pickUp
      };
    },
    getPickUpProducts: state => ({ ...state, loading: true }),
    getPickUpProductsError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload.error
    }),
    getPickUpProductsSuccess: (state, action) => {
      const { products } = action.payload;

      return {
        ...state,
        loading: false,
        error: null,
        products
      };
    }
  }
});

export const { reducer, actions } = slice;

export default reducer;
