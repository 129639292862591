import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Table from '../../components/Table/Table';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import styles from '../../assets/jss/material-kit-pro-react/views/componentsSections/contentAreas';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(styles);

export default function UsersPage(props) {
  const classes = useStyles();
  const [params, setParams] = useState({
    limit: 10,
    offset: 0,
    desc: true,
    sortBy: 'id',
    search: null
  });

  useEffect(() => {
    props.getUsers(params);
  }, [params]);

  function makeUserLink(user) {
    return user.role === 'MESSENGER'
      ? `/admin/messengers/${user.id}`
      : `/admin/users/${user.id}`;
  }

  return (
    <div>
      <Table
        tableHead={['#', 'Name', 'Role', 'Since', 'Actions']}
        tableData={props.users.map(u => {
          return {
            key: u.id,
            data: [
              <Link to={makeUserLink(u)}>{u.id}</Link>,
              `${u.firstName} ${u.lastName}`,
              u.role,
              moment(u.createdAt).format('LLLL'),
              <div>
                {u.role === 'CUSTOMER' && u.active && (
                  <Link to={`/admin/users/${u.id}/pick-ups/new`}>
                    <Button color="primary" variant="contained">
                      Schedule Pick Up
                    </Button>
                  </Link>
                )}
              </div>
            ]
          };
        })}
        customCellClasses={[
          classes.textCenter,
          classes.textRight,
          classes.textRight
        ]}
        customClassesForCells={[0, 4, 5]}
        customHeadCellClasses={[
          classes.textCenter,
          classes.textRight,
          classes.textRight
        ]}
        customHeadClassesForCells={[0, 4, 5]}
      />
    </div>
  );
}

UsersPage.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getUsers: PropTypes.func.isRequired,
  addPickUp: PropTypes.func.isRequired,
  updatePickUp: PropTypes.func.isRequired,
  pickUp: PropTypes.shape({}),
  availabilities: PropTypes.arrayOf(PropTypes.shape({})),
  getAvailabilities: PropTypes.func.isRequired
};
