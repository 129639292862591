import React, { useState } from 'react';
import PropTypes from 'prop-types';

import CustomInput from '../../../components/CustomInput/CustomInput';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  inputActions: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row-reverse',
    right: '0',
    zIndex: '2'
  },
  save: {
    marginRight: '5px'
  }
}));

export default function UserDetailInput(props) {
  const classes = useStyles();
  const [value, setValue] = useState(props.value);
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = useState(false);

  function save() {
    setSaving(true);

    props.save().then(() => {
      setSaving(false);
      setEditing(false);
    });
  }

  return (
    <CustomInput
      formControlProps={{
        fullWidth: true
      }}
      labelText={props.labelText}
      fullWidth
      inputProps={{
        value: value,
        disabled: !editing,
        onChange: e => {
          const { value } = e.target;
          setValue(value);
          props.onChange(props.name, value);
        }
      }}
      children={
        <div className={classes.inputActions}>
          {editing && (
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => setEditing(false)}
            >
              Cancel
            </Button>
          )}
          {editing && (
            <Button
              className={classes.save}
              disabled={saving || !props.validate(value)}
              size="small"
              variant="contained"
              color="primary"
              onClick={save}
            >
              Save
            </Button>
          )}
          {!editing && (
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => setEditing(true)}
            >
              Edit
            </Button>
          )}
        </div>
      }
    />
  );
}

UserDetailInput.propTypes = {
  labelText: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};
