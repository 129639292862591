import {
  container,
  grayColor
} from '../../../assets/jss/material-kit-pro-react';

export default function customerSideBarStyles(theme) {
  return {
    avatarInput: {
      display: 'none'
    },
    childContainer: {
      display: 'flex',
      flex: '1',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '5px',
        paddingRight: '5px'
      }
    },
    container: {
      ...container,
      color: grayColor[4],
      display: 'flex',
      paddingLeft: '115px',
      paddingRight: '0px',
      zIndex: '2',
      [theme.breakpoints.down('md')]: {
        padding: '0px 0px 0px 115px'
      },
      [theme.breakpoints.down('xs')]: {
        marginLeft: '0px',
        padding: '0px'
      }
    },
    drawer: {
      top: '70px',
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
        top: '0px'
      }
    },
    drawerPaper: {
      top: props => (props.isAdmin ? '70px' : '0px'),
      [theme.breakpoints.down('sm')]: {
        top: '0px'
      }
    },
    hideMobile: {
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    link: {
      '&:visited': {
        color: theme.palette.primary.main,
        fill: theme.palette.primary.main
      }
    },
    linkItem: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column'
    },
    profileContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      padding: '15px 0px',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: '0px'
      }
    }
  };
}
