import { createSelector } from 'reselect';

const appSelector = state => state.app;

export const currentUserSelector = createSelector(
  appSelector,
  app => app.currentUser
);

export const gettingCurrentUserSelector = createSelector(
  appSelector,
  app => app.gettingCurrentUser
);

export const getCurrentUserError = createSelector(
  appSelector,
  app => app.getCurrentUserError
);

export const isLoggedInSelector = createSelector(
  appSelector,
  app => app.isLoggedIn
);

export const loggingInSelector = createSelector(
  appSelector,
  app => app.loggingIn
);

export const loginErrorSelector = createSelector(
  appSelector,
  app => app.loginError
);

export const signingUpSelector = createSelector(
  appSelector,
  app => app.signingUp
);

export const signupErrorSelector = createSelector(
  appSelector,
  app => app.signupError
);

export const waitlistUserSelector = createSelector(
  appSelector,
  app => app.waitlistUser
);
