import Api from '../api';

const Waitlist = '/waitlist';

const WaitlistAPI = {
  add(user) {
    return Api.post(`${Waitlist}`, { data: user });
  }
};

export default WaitlistAPI;
