import { omit } from 'lodash';
import querystring from 'querystring';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PickUpsTable from '../../components/PickUpsTable/PickUpsTable';
import DateSwitcherBar from '../../components/DateSwitcherBar/DateSwitcherBar';
import moment from 'moment';

const setInitialState = search => {
  const queryParams = querystring.decode(search);
  return {
    limit: queryParams.limit ? +queryParams.limit : 5,
    offset: queryParams.offset ? +queryParams.offset : 0,
    sortBy: queryParams.sortBy || 'date',
    desc: queryParams.desc === 'true' ? true : false,
    from: moment(queryParams.from)
      .startOf('day')
      .toISOString(),
    to: moment(queryParams.to)
      .endOf('day')
      .toISOString()
  };
};

export default function PickUpsPage(props) {
  const [params, setParams] = useState(
    setInitialState(props.location.search.slice(1))
  );

  useEffect(() => {
    const { history, location } = props;

    history.push({ ...location, search: querystring.encode(params) });
    props.getPickUps(params);
  }, [params]);

  return (
    <div>
      <DateSwitcherBar
        day={moment(params.from)}
        onChange={day => {
          setParams(p => ({
            ...p,
            from: day
              .clone()
              .startOf('day')
              .toISOString(),
            to: day
              .clone()
              .endOf('day')
              .toISOString()
          }));
        }}
      />
      <PickUpsTable
        params={omit(params, ['from', 'to'])}
        onParamsChange={p => {
          setParams({ ...params, ...p });
        }}
        {...props}
      />
    </div>
  );
}

PickUpsPage.propTypes = {
  pickUps: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getPickUps: PropTypes.func.isRequired,
  updatePickUp: PropTypes.func.isRequired
};
