import { createSelector } from 'reselect';

const selector = state => state.messengers;

export const loadingMessengersSelector = createSelector(
  selector,
  messengers => messengers.loading
);

export const messengersErrorSelector = createSelector(
  selector,
  messengers => messengers.error
);

export const messengerSelector = createSelector(
  selector,
  messengers => messengers.messenger
);

export const messengersSelector = createSelector(
  selector,
  messengers => messengers.messengers
);

export const messengerRoutesSelector = createSelector(
  selector,
  messengers => messengers.routes
);
