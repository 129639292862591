import JsZip from 'jszip';
import moment from 'moment';
import { chain, flatMap, map } from 'lodash';
import axios from 'axios';
import { parallelLimit } from 'async';
import { saveAs } from 'file-saver';

export default async function downloadPickUpScans(pickUp) {
  const zip = new JsZip();
  const now = moment().format('LLLL');
  const folderName = `darklab Scans For Pick Up - ${pickUp.id} ${now}`;
  const scans = flatMap(pickUp.rolls, 'scans');
  const rollFoldersById = chain(pickUp)
    .get('rolls')
    .keyBy('id')
    .mapValues(({ filmRoll, id }) =>
      zip.folder([filmRoll.model, filmRoll.format, id, now].join('_'))
    )
    .value();

  const callbacks = map(scans, scan => {
    return cb => {
      const { userFilmRollId } = scan;
      const read = scan.hiRes ? scan.hiRes.read : scan.midRes.read;
      const config = { responseType: 'arraybuffer' };

      axios
        .get(read, config)
        .then(({ data }) => {
          rollFoldersById[userFilmRollId].file(
            `${scan.name}.${scan.tiff ? 'tif' : 'jpg'}`,
            Buffer.from(data, 'binary').toString('base64'),
            { base64: true }
          );
          cb();
        })
        .catch(cb);
    };
  });

  await parallelLimit(callbacks, 5);
  zip.generateAsync({ type: 'blob' }).then(content => {
    saveAs(content, folderName);
  });
}
