import { useEffect, useState } from 'react';

export default function useWindowSize() {
  const isClient = typeof window === 'object';

  function getSize() {
    const innerWidth = window.innerWidth;
    return {
      width: isClient ? innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
      isSmallScreen: innerWidth && innerWidth < 576,
      isMidScreen: innerWidth && innerWidth < 768 && innerWidth >= 576,
      isLargeScreen: innerWidth && innerWidth < 992 && innerWidth >= 768,
      isXLScreen: innerWidth && innerWidth < 1200 && innerWidth >= 992,
      isXXLScreen: innerWidth && innerWidth < 1400 && innerWidth >= 1200,
      isXXXLScreen: innerWidth && innerWidth > 1400
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    const handleResize = () => setWindowSize(getSize());

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}
