import cx from 'classnames';
import { chain, isEmpty, keyBy, size } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Button, ButtonGroup } from '@material-ui/core';
import NextIcon from '@material-ui/icons/NavigateNext';
import PrevIcon from '@material-ui/icons/NavigateBefore';

import { makeStyles } from '@material-ui/core/styles';
import ExpandIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  rollActionNav: {
    alignItems: 'center',
    display: props => (props.expanded ? 'none' : 'flex'),
    justifyContent: 'center',
    padding: '5px 0px',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  rollActions: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: props => (props.expanded ? 'center' : 'space-between'),
    width: '100%'
  },
  expand: {
    transform: props => (props.expanded ? 'rotate(180deg)' : 'rotate(0deg)'),
    transition: 'all 1s'
  }
}));

export default function RollSummaryNav(props) {
  const classes = useStyles(props);

  return (
    <div className={classes.rollActions}>
      <IconButton
        color="primary"
        className={classes.rollActionNav}
        onClick={e => {
          e.stopPropagation();
          props.setPrevThumbs();
        }}
      >
        <PrevIcon />
      </IconButton>
      <IconButton
        color="primary"
        className={classes.rollAction}
        onClick={props.setExpand}
      >
        <ExpandIcon className={classes.expand} />
      </IconButton>
      <IconButton
        color="primary"
        className={classes.rollActionNav}
        onClick={e => {
          e.stopPropagation();
          props.setNextThumbs();
        }}
      >
        <NextIcon />
      </IconButton>
    </div>
  );
}

RollSummaryNav.propTypes = {
  expanded: PropTypes.bool.isRequired,
  setNextThumbs: PropTypes.func.isRequired,
  setPrevThumbs: PropTypes.func.isRequired
};
