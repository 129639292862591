/**
 * Onboarding saga functions
 */
import { call, put, takeLatest } from 'redux-saga/effects';
import { handleError } from '../../lib/utils/saga-utils';
import { actions } from './slice';
import ProductAPI from '../../lib/Api/Product';

export function* getPickUpProducts() {
  try {
    const response = yield call(ProductAPI.getPickUpProducts);
    yield put(actions.getPickUpProductsSuccess(response));
  } catch (err) {
    yield handleError(actions.getPickUpProductsError, err);
  }
}

function* pickUpsSaga() {
  yield takeLatest(actions.getPickUpProducts.type, getPickUpProducts);
}

export default pickUpsSaga;
