import React from 'react';
import { connect } from 'react-redux';
import MessengerPage from '../../views/MessengerPage/MessengerPage';
import {
  loadingMessengersSelector,
  messengersErrorSelector,
  messengerSelector,
  messengerRoutesSelector
} from './selectors';
import { actions } from './slice';
import promisifyActions from '../../lib/utils/promisify-actions';
import UserAPI from '../../lib/Api/User';
import MessengerAPI from "../../lib/Api/Messenger";

const Messenger = props => {
  return (
    <MessengerPage {...props} uploadProfilePic={UserAPI.uploadProfilePic} notifyNextStop={MessengerAPI.notifyNextStop} />
  );
};

/**
 * The admin state to be provided to the admin component
 *
 * @param {object} state global state
 */
const mapStateToProps = state => ({
  loading: loadingMessengersSelector(state),
  error: messengersErrorSelector(state),
  messenger: messengerSelector(state),
  routes: messengerRoutesSelector(state)
});

/**
 * Actions to be dispatched
 *
 * @param {function} dispatch function to dispatch admin adminActions
 */
const mapDispatchToProps = dispatch => ({
  addHours: (messengerId, hours) =>
    dispatch(actions.addHours({ messengerId, hours })),
  updateHours: (messengerId, hours) =>
    dispatch(actions.updateHours({ messengerId, hours })),
  deleteHours: (messengerId, hoursId) =>
    dispatch(actions.deleteHours({ messengerId, hoursId })),
  getMessenger: messengerId => dispatch(actions.getMessenger({ messengerId })),
  getRoutes: (messengerId, from, to) =>
    dispatch(actions.getRoutes({ messengerId, from, to }))
});

/**
 * Connects admin state to the App component
 *
 * @param {object} mapStateToProps admin data object to become props for the App component
 * @param {object} mapDispatchToProps admin adminActions to be dispatched to reducer
 */
export default connect(
  mapStateToProps,
  promisifyActions(mapDispatchToProps)
)(Messenger);
