import cx from 'classnames';
import { chain, find, isEmpty, toLower } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircle from '@material-ui/icons/CheckCircle';
import RollToReceive from './RollToReceive';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  checkCircle: {
    fill: theme.palette.success.dark,
    marginLeft: '10px'
  },
  container: {
    minHeight: '300px'
  },
  header: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between'
  },
  headerText: {
    alignItems: 'center',
    display: 'flex'
  },
  mobileHide: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  rolls: {
    backgroundColor: 'transparent',
    padding: '20px 10px'
  },
  rollsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 'bold'
  },
  rollsHeader1: {
    flex: '1'
  },
  rollsHeader2: {
    flex: '2'
  },
  rollsHeader5: {
    flex: '5'
  },
  tableTitle: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

export default function RollsToReceive(props) {
  const classes = useStyles();

  function formatFilmRoll(roll) {
    const { iso, name, format, native_process } = roll;
    const size = `${format === '135' ? '35' : format}mm`;
    const label = name.includes(iso)
      ? `${name} ${size}`
      : `${name} ${iso} ${size}`;
    return toLower(native_process) === 'e-6' ? `${label} (E6 + $5.99)` : label;
  }

  const options = props.products.map(({ filmRoll }) => ({
    label: formatFilmRoll(filmRoll),
    value: filmRoll.id
  }));

  if (isEmpty(props.products)) return null;

  return (
    <div className={classes.container}>
      <h4 className={classes.header}>
        <span className={classes.headerText}>Confirm Received Rolls</span>

        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={props.save}
        >
          Save
        </Button>
      </h4>
      <Divider />

      <div className={classes.rolls}>
        <div className={classes.rollsHeader}>
          <div className={classes.rollsHeader5}>Roll</div>
          <div className={cx(classes.rollsHeader1, classes.mobileHide)}>
            ISO
          </div>
          <div className={cx(classes.rollsHeader1, classes.mobileHide)}>
            Format
          </div>
          <div className={cx(classes.rollsHeader2, classes.mobileHide)}>
            Store Negatives
          </div>
          <div className={cx(classes.rollsHeader2, classes.mobileHide)}>
            Options
          </div>
          <div className={classes.rollsHeader1}>Received</div>
        </div>
        <Divider />
        {props.rolls.map((roll, i) => (
          <RollToReceive
            key={roll.id || Math.random() * 1000}
            updateRoll={(k, v) => {
              props.updateRoll(i, { ...roll, [k]: v });
            }}
            roll={roll}
            options={options}
            filmRolls={chain(props.products)
              .map('filmRoll')
              .keyBy('id')
              .value()}
          />
        ))}
      </div>
    </div>
  );
}

RollsToReceive.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  rolls: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  updateRoll: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired
};
