import querystring from 'querystring';
import Api from '../api';

const PickUp = '/pick-ups';

const PickUpAPI = {
  add(pickUp) {
    return Api.post(`${PickUp}`, { data: pickUp });
  },

  get(pickUpId) {
    return Api.get(`${PickUp}/${pickUpId}`);
  },

  getAll(params) {
    return Api.get(`${PickUp}?${querystring.encode(params)}`);
  },

  update(pickUp) {
    return Api.put(`${PickUp}/${pickUp.id}`, { data: pickUp });
  }
};

export default PickUpAPI;
