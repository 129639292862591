import { isEmpty, map, sortBy } from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary
} from '@material-ui/core';

import Gallery from './Gallery';
import RollThumbnail from './RollThumbnail';
import RollSummary from './RollSummary';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  detailsRoot: {
    padding: '0px 0px 15px 0px',
    [theme.breakpoints.down('xs')]: {
      padding: '8px 0px'
    }
  },
  panelRoot: {
    background: 'transparent',
    margin: '15px 0px 0px 0px',
    padding: '0px 95px',
    [theme.breakpoints.down('lg')]: {
      padding: '0px 89px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px 15px'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '0px 2px'
    }
  },
  rollActionText: {
    marginLeft: '5px'
  },
  rollAction: {
    alignItems: 'center',
    display: 'flex'
  },
  rollActions: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-around',
    margin: '10px 0px'
  },
  rollSummary: {
    alignItems: 'center',
    display: 'flex',
    flex: '1',
    flexDirection: 'column'
  },
  scans: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center'
    }
  },
  summaryContent: {
    height: props =>
      isEmpty(props.scans) && !props.isSmallScreen ? '38px' : 'auto',
    margin: '5px 0px'
  },
  summaryExpanded: {
    marginBottom: '0px !important'
  },
  summaryRoot: {
    padding: '0px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 2px'
    }
  }
}));

export default function PickUpsListRoll(props) {
  const classes = useStyles({ scans: props.roll.scans, ...props });
  const sortedScans = sortBy(props.roll.scans, 'id');

  return (
    <div>
      <ExpansionPanel
        classes={{ root: classes.panelRoot }}
        expanded={props.expanded}
      >
        <ExpansionPanelSummary
          disabled={isEmpty(props.roll.scans)}
          onClick={() => props.setExpanded(!props.expanded)}
          classes={{
            content: classes.summaryContent,
            expanded: classes.summaryExpanded,
            root: classes.summaryRoot
          }}
        >
          <div className={classes.rollSummary}>
            <RollSummary
              roll={props.roll}
              expanded={props.expanded}
              setExpand={() => props.setExpanded(!props.expanded)}
              download={scans => props.download(scans, props.roll)}
              downloading={props.downloading}
              setSelected={props.setSelected}
              selected={props.selected}
              openImage={props.setGalleryImage}
              thumbs={sortedScans}
              {...props}
            />
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails classes={{ root: classes.detailsRoot }}>
          <div>
            {props.expanded && (
              <div className={classes.scans}>
                {map(sortedScans, (scan, i) => (
                  <RollThumbnail
                    scan={scan}
                    key={scan.id}
                    isSelected={!!props.selected[scan.id]}
                    index={i}
                    openImage={props.setGalleryImage}
                    onSelect={props.setSelected}
                    roll={props.roll}
                  />
                ))}
              </div>
            )}
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}

PickUpsListRoll.propTypes = {
  download: PropTypes.func.isRequired,
  downloading: PropTypes.bool.isRequired,
  expanded: PropTypes.bool.isRequired,
  setExpanded: PropTypes.func.isRequired,
  roll: PropTypes.shape({}).isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selected: PropTypes.shape({}).isRequired,
  setGalleryImage: PropTypes.func.isRequired,
  setSelected: PropTypes.func.isRequired,
  onUnlock4k: PropTypes.func.isRequired,
  onUnlockTiff: PropTypes.func.isRequired
};
