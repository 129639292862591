/**
 * Object of addon skus
 */

export default {
  E_6: 'e6proscanadd',
  ADDITIONAL_ROLL: 'additionalroll',
  PICK_UP: 'pickupcharge',
  CROSS_PROCESS: 'crossprocessroll',
  PUSH_PULL_ROLL: 'pushpullroll'
};
