import { get, chain } from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { makeStyles } from '@material-ui/core/styles';

import resize from '../../lib/utils/resize-image';
import dataURItoBlob from '../../lib/utils/data-url-to-blob';
import { makeUserInitials } from '../../lib/utils/make-user-initials';

import MessengerRoutes from './Sections/MessengerRoutes';

import MessengerHours from './Sections/MessengerHours';

import styles from '../../assets/jss/material-kit-pro-react/views/componentsSections/contentAreas';

const useStyles = makeStyles(theme => ({
  ...styles,
  avatarInput: {
    display: 'none'
  }
}));

const getMessengerId = location => {
  return chain(location)
    .get('pathname')
    .split('/')
    .at(3)
    .value();
};

export default function MessengerPage(props) {
  const classes = useStyles();

  const [messengerId] = useState(getMessengerId(props.location));
  const [avatar, setAvatar] = useState(null);
  const [tab, setTab] = useState(0);
  const fileInput = useRef(null);

  useEffect(() => {
    props
      .getMessenger(messengerId)
      .then(({ messenger }) => setAvatar(messenger.profileImageUrl));
  }, [messengerId]);

  function onUpload(e) {
    if (e) e.preventDefault();

    const reader = new FileReader();
    const file = e.target.files[0];

    if (!file) return;

    reader.onload = ev =>
      resize(ev, dataURI => {
        const params = { fileType: file.type, fileName: file.name };
        const resizedFile = dataURItoBlob(dataURI);
        props
          .uploadProfilePic(messengerId, params, resizedFile)
          .then(({ user }) => setAvatar(user.profileImageUrl));
      });

    reader.readAsDataURL(file);
  }

  if (!props.messenger) return null;

  return (
    <div className={classes.container}>
      <div>
        <label
          htmlFor="avatar-upload-input"
          onClick={() => fileInput.current.click()}
        >
          <IconButton>
            <Avatar
              alt="Avatar"
              src={avatar}
              style={{
                margin: '10px',
                width: '60px',
                height: '60px'
              }}
            >
              {makeUserInitials(props.messenger)}
            </Avatar>
          </IconButton>
          <input
            ref={fileInput}
            accept="image/jpeg, image/png"
            className={classes.avatarInput}
            id="avatar-upload-input"
            type="file"
            onChange={onUpload}
          />
        </label>

        <span>
          {`${get(props, 'messenger.firstName')} ${get(
            props,
            'messenger.lastName'
          )}`}{' '}
          <small>
            · Joined on
            {` ${moment(get(props, 'messenger.createdAt')).format('LL')}`}
          </small>
        </span>
      </div>

      <AppBar position="static" className={classes.nav}>
        <Tabs
          value={tab}
          onChange={(_, tab) => setTab(tab)}
          aria-label="Messenger Tabs"
        >
          <Tab label="Routes" />
          <Tab label="Hours" />
        </Tabs>
      </AppBar>

      {tab === 0 && <MessengerRoutes {...props} />}

      {tab === 1 && props.messenger && (
        <MessengerHours
          addHours={props.addHours}
          updateHours={props.updateHours}
          messenger={props.messenger}
          hours={get(props, 'messenger.hours', [])}
        />
      )}
    </div>
  );
}

MessengerPage.propTypes = {
  addHours: PropTypes.func.isRequired,
  updateHours: PropTypes.func.isRequired,
  deleteHours: PropTypes.func.isRequired,
  uploadProfilePic: PropTypes.func.isRequired,
  messenger: PropTypes.shape({}),
  getMessenger: PropTypes.func.isRequired
};
