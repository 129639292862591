import { grayColor } from 'assets/jss/material-kit-pro-react.js';

const inputErrorMessageStyle = {
  root: {
    color: grayColor[0],
    fontSize: '10px',
    position: 'absolute',
    right: '0px',
    textAlign: 'right',
    top: '0px'
  }
};

export default inputErrorMessageStyle;
