import { get } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import { CardElement, injectStripe } from 'react-stripe-elements';
import PaymentRequestForm from './PaymentRequestForm';

const styles = theme => ({
  actions: {
    display: 'flex',
    flexDirection: 'row-reverse',
    margin: `15px ${theme.spacing(1)}px`
  },
  form: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: '0px'
  },
  root: {
    display: 'flex',
    flex: '1'
  },
  submit: {
    marginRight: props => (props.cancel ? '5px' : '0px')
  }
});

const useStyles = makeStyles(styles);

function StripeForm(props) {
  const classes = useStyles(props);
  const userId = get(props, 'user.id');

  function onSubmit(e) {
    if (e) e.preventDefault();

    props.stripe.createToken().then(result => {
      if (!result.token) return;

      const token = result.token.id;

      props.onSubmit(userId, token).then(({ user }) => {
        props.onSuccess(user);
      });
    });
  }

  function onChange(details) {
    props.onChange(details, details.complete);
  }

  return (
    <div className={classes.root}>
      <form onSubmit={onSubmit} className={classes.form}>
        <label>
          Enter Card details
          <CardElement
            style={{ base: { fontSize: '16px' } }}
            onChange={onChange}
          />
        </label>

        <div className={classes.actions}>
          {props.cancel && (
            <Button
              type="submit"
              color="primary"
              variant="contained"
              size="small"
              onClick={props.onCancel}
            >
              Cancel
            </Button>
          )}

          <Button
            className={classes.submit}
            type="submit"
            color="primary"
            variant="contained"
          >
            {props.user.active ? 'Save' : 'Subscribe'}
          </Button>
        </div>
        <PaymentRequestForm />
      </form>
    </div>
  );
}

StripeForm.propTypes = {
  cancel: PropTypes.bool,
  createSubscription: PropTypes.func,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onUpdateUser: PropTypes.func
};

StripeForm.defaultProps = {
  cancel: false,
  onUpdateUser: () => {},
  onChange: () => {}
};

export default injectStripe(StripeForm);
