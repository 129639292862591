import cx from 'classnames';
import { get, toLower, chain } from 'lodash';
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@material-ui/core';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';
import Dropdown from '../../../components/Dropdown/Dropdown';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FilmRollOptions from '../../../components/FilmRollOptions/FilmRollOptions';
import AdminContext from '../../../contexts/AdminContext';

const useStyles = makeStyles(theme => ({
  alignCenter: {
    textAlign: 'center'
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '5px 0px'
  },
  duplicate: {
    cursor: 'pointer',
    fill: theme.palette.primary.light,
    '&:hover': {
      fill: theme.palette.primary.dark
    }
  },
  editable: {
    borderBottom: `1px dashed ${theme.palette.primary.main}`
  },
  flex1: {
    flex: '1'
  },
  flex2: {
    flex: '2'
  },
  mobileHide: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  name: {
    cursor: 'text',
    flex: '5'
  },
  optionsBtn: {
    position: 'relative'
  },
  optionsBadge: {
    position: 'absolute',
    top: '-10px',
    right: '-10px',
    fontSize: '12px',
    padding: '2px 5px',
    borderRadius: '50%',
    background: theme.palette.success.dark,
    color: theme.palette.bg.main
  },
  remove: {
    cursor: 'pointer',
    fill: theme.palette.primary.light,
    '&:hover': {
      fill: theme.palette.primary.dark
    }
  },
  rollRequest: {
    fontSize: '10px'
  }
}));

export default function PickUpFilmRoll(props) {
  const classes = useStyles();
  const [detailedFilmRoll, setDetailedFilmRoll] = useState(
    props.filmRolls[props.roll.filmRollId]
  );
  const [openOptions, setOpenOptions] = useState(false);
  const [dropdownFocused, setDropdownFocused] = useState(false);

  const context = useContext(AdminContext);

  function formatName(roll) {
    const type = get(roll, 'native_process');
    const name = get(roll, 'name');
    return toLower(type) === 'e-6' ? `${name} (E6 + $5.99)` : name;
  }

  const addedOptions = chain([props.roll.pushPullId, props.roll.crossProcessId])
    .compact()
    .size()
    .value();

  function makeRequestLink() {
    const email = 'josh@darklabsubscription.com';
    const subject = `Film Roll Request for Process and Scan`;
    const body = `Hey darklab, 
      %0D%0A
      %0D%0A
      I'm looking to get the following roll developed:  
      %0D%0A
      %0D%0A
      film roll: _______
      %0D%0A
      iso: ______
      %0D%0A
      format: ______
      %0D%0A
      expiration date: ______
      %0D%0A 
      %0D%0A
      Would you be able to develop and scan it?`;

    return `mailto:${email}?subject=${subject}&body=${body}`;
  }

  return (
    <div className={classes.container}>
      <div className={classes.name}>
        {detailedFilmRoll ? (
          <span
            onClick={() => {
              setDetailedFilmRoll(null);
              props.updateRoll('filmRollId', null);
            }}
            className={classes.editable}
          >
            {formatName(detailedFilmRoll)}
          </span>
        ) : (
          <div>
            <Dropdown
              options={props.options}
              onFocus={() => {
                setDropdownFocused(true);
              }}
              onChange={r => {
                setDetailedFilmRoll(props.filmRolls[r.value]);
                props.updateRoll('filmRollId', r.value);
              }}
              placeholder="Select A Film Roll"
            />
            {dropdownFocused && !detailedFilmRoll && (
              <a href={makeRequestLink()} className={classes.rollRequest}>
                Not seeing your roll? Send us a request
              </a>
            )}
          </div>
        )}
      </div>

      <div className={cx(classes.flex1, classes.mobileHide)}>
        {get(detailedFilmRoll, 'iso')}
      </div>
      <div className={cx(classes.flex1, classes.mobileHide)}>
        {get(detailedFilmRoll, 'format')}
      </div>
      <div
        className={cx(classes.flex2, classes.alignCenter, classes.mobileHide)}
      >
        <Checkbox
          onChange={e => {
            props.updateRoll('returnToCustomer', !e.target.checked);
          }}
          checked={!props.roll.returnToCustomer}
        />
      </div>
      <div className={cx(classes.flex2, classes.mobileHide)}>
        <Button
          size="small"
          color="primary"
          variant="contained"
          disabled={!detailedFilmRoll}
          onClick={() => setOpenOptions(true)}
          className={classes.optionsBtn}
        >
          Options
          {addedOptions > 0 && (
            <span className={classes.optionsBadge}>{addedOptions}</span>
          )}
        </Button>
      </div>
      <div className={classes.flex1}>
        <AddCircleOutline
          onClick={props.duplicate}
          className={classes.duplicate}
        />
      </div>
      <div className={classes.flex1}>
        <RemoveCircleOutline
          onClick={() => {
            setDetailedFilmRoll(null);
            props.remove();
          }}
          className={classes.remove}
        />
      </div>

      {context.isAdmin && (
        <div className={classes.flex1}>
          <Checkbox
            onChange={e => {
              props.updateRoll(
                'receivedAt',
                e.target.checked ? new Date() : null
              );
            }}
            checked={!!props.roll.receivedAt}
          />
        </div>
      )}

      <FilmRollOptions
        roll={props.roll}
        filmRoll={detailedFilmRoll}
        open={openOptions}
        onClose={() => setOpenOptions(false)}
        updateRoll={(k, v) => props.updateRoll(k, v)}
      />
    </div>
  );
}

PickUpFilmRoll.propTypes = {
  duplicate: PropTypes.func.isRequired,
  filmRolls: PropTypes.shape({}).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  remove: PropTypes.func.isRequired,
  roll: PropTypes.shape({}).isRequired,
  updateRoll: PropTypes.func.isRequired
};
