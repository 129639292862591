import querystring from 'querystring';

import Api from '../api';

const Messenger = '/messengers';

const MessengerAPI = {
  add(messenger) {
    return Api.post(`${Messenger}`, { data: messenger });
  },

  get(messengerId) {
    return Api.get(`${Messenger}/${messengerId}`);
  },

  getAll() {
    return Api.get(`${Messenger}`);
  },

  update(messenger) {
    return Api.put(`${Messenger}/${messenger.id}`, { data: messenger });
  },

  addHours(messengerId, hours) {
    return Api.post(`${Messenger}/${messengerId}/hours`, { data: hours });
  },

  updateHours(messengerId, hours) {
    return Api.put(`${Messenger}/${messengerId}/hours/${hours.id}`, {
      data: hours
    });
  },

  deleteHours(messengerId, hoursId) {
    return Api.delete(`${Messenger}/${messengerId}/hours/${hoursId}`);
  },

  getRoutes(messengerId, from, to) {
    return Api.get(
      `${Messenger}/${messengerId}/routes?${querystring.encode({ from, to })}`
    );
  },

  notifyNextStop(messengerId, from, to) {
    return Api.get(
      `${Messenger}/${messengerId}/routes/notify-next-stop?${querystring.encode(
        { from, to }
      )}`
    );
  }
};

export default MessengerAPI;
